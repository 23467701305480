import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  createRef,
  useMemo,
} from "react";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import web3 from "../../connect-web3/web3";
import Web3 from "web3";
import ItemThumbnail from "../item/ItemThumbnail";
import ItemInfoPanel from "../item/ItemInfoPanel";
import ItemChart from "../item/ItemChart";
import ItemAuthor from "../item/ItemAuthor";
import { formatPrice } from "../../helpers/utils";
import { settings } from "../../helpers/settings";
import Web3Context from "../../providers/web3-context";
import CollectionContext from "../../providers/collection-context";
import MarketplaceContext from "../../providers/marketplace-context";
import UserContext from "../../providers/user-context";
import AuctionContext from "../../providers/auction-context";
import Loader from "../general/Loader";
import FullScreenLoader from "../general/FullScreenLoader";
import NftHistory from "../general/NftHistory";
import MetaMaskLoader from "../general/MetaMaskLoader";
import NftItem from "../general/NftItem";
import PricesLog from "../general/PricesLog";
import Modal from "../general/Modal";
import { categoryIcons_white } from "../../helpers/constants";

function ItemSingle() {
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const web3Ctx = useContext(Web3Context);
  const userCtx = useContext(UserContext);
  const auctionCtx = useContext(AuctionContext);
  const [saleType, setSaleType] = useState("");
  const [endDate, setEndDate] = useState(new Date().getTime());
  const [nftData, setNftData] = useState(null);
  const [assetHistory, setAssetHistory] = useState(["0x9"]);
  const [marketplaceAddress, setMarketplaceAddress] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [ownerName, setOwnerName] = useState("Loading...");
  const [historyType, setHistoryType] = useState("transactions");
  const [ownerAvatar, setOwnerAvatar] = useState(null);
  const { addToast } = useToasts();
  const [currentAsset, setCurrentAsset] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCurrentAsset, setIsCurrentAsset] = useState(null);
  const [networkId, setNetworkId] = useState(0);
  const { id } = useParams();
  const navigate = useHistory();
  /*** =============================================== */
  //      GET ACTIVE NETWORK ID
  /*** =============================================== */
  useEffect(() => {
    async function getNetworkId() {
      if (window.ethereum) {
        const networkId = await web3Ctx.loadNetworkId(
          new Web3(window.ethereum)
        );
        setNetworkId(networkId);
      }
    }
    getNetworkId();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*** =============================================== */
  //      CHECK IF THE ASSET EXISTS
  /*** =============================================== */
  useEffect(() => {
    if (collectionCtx.contract) {
      if (
        collectionCtx.collection.map((nft) => nft.id).includes(parseInt(id))
      ) {
        setIsCurrentAsset(true);
      } else {
        setIsCurrentAsset(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftData, id, collectionCtx.collection]);

  /*** =============================================== */
  //      DECLARE PRICE REFERENCE
  /*** =============================================== */
  const priceRefs = useRef([]);
  if (priceRefs.current.length !== collectionCtx.collection.length) {
    priceRefs.current = Array(collectionCtx.collection.length)
      .fill()
      .map((_, i) => priceRefs.current[i] || createRef());
  }

  /*** =============================================== */
  //      MERGE NFT COLLECTIONS WITH OFFERS
  /*** =============================================== */
  useEffect(() => {
    if (
      marketplaceCtx.contract &&
      collectionCtx.contract &&
      collectionCtx.collection.length > 0
    ) {
      let offersMap = marketplaceCtx.offers.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      let combined = collectionCtx.collection.map((d) =>
        Object.assign(d, offersMap[d.id])
      );
      let final = combined.map((el) => {
        if (el.price) {
          return { ...el, owner: el.user };
        } else if (!el.price) {
          return { ...el, price: 0 };
        }
        return { ...el };
      });
      setNftData(final.filter((nft) => nft.id === parseInt(id)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    marketplaceCtx.offers,
    collectionCtx.collection,
    collectionCtx.contract,
    marketplaceCtx.contract,
    collectionCtx.collection,
    collectionCtx.nftHistory,
    isCurrentAsset,
    id,
  ]);

  /*** =============================================== */
  //      GET NFT DETAILS
  /*** =============================================== */
  const similarItems = useMemo(() => {
    if (nftData && isCurrentAsset === true) {
      return collectionCtx.collection
        .filter(
          (nft) =>
            !auctionCtx.auctions
              .filter((auc) => auc.isActive === true)
              .some((auc) => nft.id === auc.tokenId)
        )
        .filter((item) => item.category === nftData[0].category)
        .filter((item) => item.id !== parseInt(id));
    }
  }, [
    nftData,
    id,
    collectionCtx.collection,
    isCurrentAsset,
    auctionCtx.auctions,
  ]);

  /*** =============================================== */
  //      GET MFT HISTORY
  /*** =============================================== */
  useEffect(() => {
    if (collectionCtx.contract && isCurrentAsset === true) {
      collectionCtx.getNftHistory(collectionCtx.contract, parseInt(id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionCtx.contract, id, isCurrentAsset]);

  /*** =============================================== */
  //      SET MFT HISTORY
  /*** =============================================== */
  useEffect(() => {
    if (collectionCtx.assetHistory && collectionCtx.contract) {
      setAssetHistory(collectionCtx.assetHistory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionCtx.assetHistory, id, isCurrentAsset]);

  /*** =============================================== */
  //      GET MARKETPLACE CONTRACT
  /*** =============================================== */
  useEffect(() => {
    if (marketplaceCtx.contract) {
      async function getMarketplaceAddress() {
        const mktAddress = await marketplaceCtx.contract._address;
        setMarketplaceAddress(mktAddress);
      }
      getMarketplaceAddress();
    }
  }, [marketplaceCtx.contract]);

  /*** =============================================== */
  //      CHANGE PAGE TITLE
  /*** =============================================== */
  useEffect(() => {
    document.title = `${
      currentAsset.length > 0 ? currentAsset[0].title : "NFT Item"
    } | Elysium NFT`;
  }, [currentAsset, id]);

  /*** =============================================== */
  //      GET NFT DETAILS
  /*** =============================================== */
  useEffect(() => {
    setCurrentAsset(
      collectionCtx.collection.filter((asset) => asset.id === parseInt(id))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionCtx.collection, id]);

  /*** =============================================== */
  //      GET OWNER NAME & AVATAR
  /*** =============================================== */
  useEffect(() => {
    if (
      userCtx.usersList &&
      userCtx.usersList.length > 0 &&
      currentAsset.length > 0 &&
      nftData
    ) {
      setOwnerName(
        userCtx.usersList.filter((user) => user.account === nftData[0].owner)[0]
          .fullName
      );
      setOwnerAvatar(
        userCtx.usersList.filter((user) => user.account === nftData[0].owner)[0]
          .avatar
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx.usersList, nftData, id]);

  /*** =============================================== */
  //      ADD PRICE FUNCTION
  /*** =============================================== */
  const makeOfferHandler = (event, id, nftKey) => {
    event.preventDefault();

    const enteredPrice = web3.utils.toWei(
      priceRefs.current[nftKey].current.value,
      "ether"
    );

    collectionCtx.contract.methods
      .approve(marketplaceCtx.contract.options.address, id)
      .send({ from: web3Ctx.account })
      .on("transactionHash", (hash) => {
        setIsModalOpen(false);
      })
      .once("sending", () => {
        collectionCtx.setNftTransactionLoading(true);
        setIsModalOpen(false);
      })
      .once("error", (e) => {
        collectionCtx.setNftTransactionLoading(false);
        setIsModalOpen(false);
      })
      .on("receipt", (receipt) => {
        marketplaceCtx.contract.methods
          .addPrice(id, enteredPrice)
          .send({ from: web3Ctx.account })
          .once("sending", () => {
            collectionCtx.setNftTransactionLoading(true);
          })
          .on("receipt", () => {
            collectionCtx.setNftTransactionLoading(false);
            setIsModalOpen(false);
            collectionCtx.getNftHistory(collectionCtx.contract, parseInt(id));
            userCtx.loadTransactions(userCtx.contract);
            userCtx.loadActivity(userCtx.contract);
          })
          .on("error", (error) => {
            collectionCtx.setNftTransactionLoading(false);
            setIsModalOpen(false);
            addToast("Oops! an error occured", {
              appearance: "error",
            });
          });
        collectionCtx.setNftTransactionLoading(false);
        setIsModalOpen(false);
        collectionCtx.getNftHistory(collectionCtx.contract, parseInt(id));
        userCtx.loadTransactions(userCtx.contract);
        userCtx.loadActivity(userCtx.contract);
      });
  };

  /*** =============================================== */
  //      BUY NFT FUNCTION
  /*** =============================================== */
  const buyHandler = (event) => {
    const buyIndex = parseInt(event.target.value);
    marketplaceCtx.contract.methods
      .buyNFT(marketplaceCtx.offers[buyIndex].offerId)
      .send({
        from: web3Ctx.account,
        value: marketplaceCtx.offers[buyIndex].price,
      })
      .once("sending", () => {
        collectionCtx.setNftTransactionLoading(true);
      })
      .on("transactionHash", (hash) => {
        collectionCtx.setNftTransactionLoading(true);
      })
      .on("receipt", () => {
        collectionCtx.setNftTransactionLoading(false);
        collectionCtx.getNftHistory(collectionCtx.contract, parseInt(id));
        userCtx.loadTransactions(userCtx.contract);
        userCtx.loadActivity(userCtx.contract);
      })
      .on("error", (error) => {
        addToast("Oops! an error occured", {
          appearance: "error",
        });
        collectionCtx.setNftTransactionLoading(false);
      });
  };

  /*** =============================================== */
  //      CANCEL OFFER FUNCTION
  /*** =============================================== */
  const cancelHandler = (event) => {
    const cancelIndex = parseInt(event.target.value);
    marketplaceCtx.contract.methods
      .cancelSale(marketplaceCtx.offers[cancelIndex].offerId)
      .send({ from: web3Ctx.account })
      .once("sending", () => {
        collectionCtx.setNftTransactionLoading(true);
      })
      .on("transactionHash", (hash) => {
        collectionCtx.setNftTransactionLoading(true);
      })
      .on("receipt", () => {
        collectionCtx.setNftTransactionLoading(false);
        collectionCtx.getNftHistory(collectionCtx.contract, id);
        userCtx.loadTransactions(userCtx.contract);
        userCtx.loadActivity(userCtx.contract);
      })
      .on("error", (error) => {
        collectionCtx.setNftTransactionLoading(false);
        addToast("Oops! an error occured", {
          appearance: "error",
        });
      });
  };

  /*** =============================================== */
  //      MAKE AUCTION FUNCTION
  /*** =============================================== */
  const makeAuctionHandler = (event, endDate, id) => {
    event.preventDefault();

    if (new Date(endDate).getTime() > new Date().getTime()) {
      collectionCtx.contract.methods
        .approve(auctionCtx.contract.options.address, parseInt(id))
        .send({ from: web3Ctx.account })
        .on("transactionHash", (hash) => {
          setIsModalOpen(false);
        })
        .once("sending", () => {
          collectionCtx.setNftTransactionLoading(true);
          setIsModalOpen(false);
        })
        .once("error", (e) => {
          collectionCtx.setNftTransactionLoading(false);
          setIsModalOpen(false);
        })
        .on("receipt", (receipt) => {
          auctionCtx.contract.methods
            .createAuction(parseInt(id), new Date(endDate).getTime())
            .send({ from: web3Ctx.account })
            .once("sending", () => {
              auctionCtx.setAuctionTransactionLoading(true);
            })
            .on("receipt", () => {
              auctionCtx.setAuctionTransactionLoading(false);
              setIsModalOpen(false);
              setSaleType("");
              userCtx.loadTransactions(userCtx.contract);
              userCtx.loadActivity(userCtx.contract);
              navigate.push("/auctions");
            })
            .on("error", (error) => {
              auctionCtx.setAuctionTransactionLoading(false);
              setIsModalOpen(false);
              setSaleType("");
              addToast("Oops! an error occured", {
                appearance: "error",
              });
            });
          auctionCtx.setAuctionTransactionLoading(false);
          collectionCtx.setNftTransactionLoading(false);
          setIsModalOpen(false);
          collectionCtx.getNftHistory(collectionCtx.contract, id);
          userCtx.loadTransactions(userCtx.contract);
          userCtx.loadActivity(userCtx.contract);
        });
    } else {
      addToast("End Date Cannot be in the past", {
        appearance: "error",
      });
    }
  };

  /*** =============================================== */
  //      CLOSE MODAL FUNCTION
  /*** =============================================== */
  function closeModalHandler() {
    setIsModalOpen(false);
    setSaleType("");
  }

  if (isCurrentAsset === false) {
    return (
      <div className="container py-5">
        <div className="row py-5 text-center">
          <div className="col-lg-6 mx-auto">
            <p className="mb-0 fw-bold" style={{ fontSize: "10rem" }}>
              404
            </p>
            <h1 className="h2 text-uppercase">Not Found</h1>
            <p className="text-muted">
              This page is not found, return to Home page
            </p>
            <Link to="/" className="btn btn-gradient-primary">
              Homepage
            </Link>
          </div>
        </div>
      </div>
    );
  }

  console.log(collectionCtx.nftCreator);
  console.log("CurrentAsset", currentAsset);
  return (
    <>
      {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}
      {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
      {marketplaceCtx.mktIsLoading ? (
        <FullScreenLoader heading="loading" />
      ) : null}
      <section className="pt-5 bg-light">
        {collectionCtx.collection.length === 0 ? (
          <div className="py-5 text-center mt-5 mb-3">
            <h1 className="h2 mt-5">Fetching item details</h1>
            <p className="text-muted">
              Please wait until we prepare your data.
            </p>
            <Loader />
          </div>
        ) : (
          currentAsset.map((asset, key) => {
            const index = marketplaceCtx.offers
              ? marketplaceCtx.offers.findIndex(
                  (offer) => offer.id === asset.id
                )
              : -1;
            const owner =
              index === -1 ? asset.owner : marketplaceCtx.offers[index].user;
            const price =
              index !== -1
                ? formatPrice(marketplaceCtx.offers[index].price).toFixed(2)
                : null;

            // console.log("asset");
            // console.log(asset);
            return (
              <div key={key}>
                <br />
                <div className="container pt-5">
                  <div className="flex flex-col justify-center items-center text-break">
                    <h1
                      className="mb-4 text-center"
                      style={{ color: "ffffff" }}
                    >
                      {" "}
                      {asset.title}{" "}
                    </h1>
                    <p
                      className="text-center text-white bg-primary px-3 rounded text-sm mb-0 py-2 mx-auto"
                      style={{ width: "fit-content", fontWeight: "600" }}
                    >
                      <img
                        src={categoryIcons_white[asset.category]}
                        alt=""
                        width="15"
                        style={{ marginRight: "3px" }}
                        className="z-index-20 "
                      />
                      {asset.category}
                    </p>
                  </div>
                  <div className="row mb-4 gy-4 mt-4">
                    <div className="col-lg-6">
                      <ItemThumbnail
                        type={asset.type}
                        thumbnail={`https://nftstorage.link/ipfs/${asset?.img}`}
                      />

                      {collectionCtx.nftHistory && collectionCtx.nftCreator && (
                        <>
                          <div className="toggle-nav mt-5 mb-2">
                            <button
                              className={`toggle-nav-btn flex-fill ${
                                historyType === "transactions" ? "active" : null
                              }`}
                              onClick={() => setHistoryType("transactions")}
                            >
                              <span className="lh-reset">Transactions</span>
                            </button>
                            <button
                              className={`toggle-nav-btn flex-fill ${
                                historyType === "prices" ? "active" : null
                              }`}
                              onClick={() => setHistoryType("prices")}
                            >
                              <span className="lh-reset">Price Log</span>
                            </button>
                          </div>
                          {historyType === "transactions" && (
                            <NftHistory
                              history={collectionCtx.nftHistory}
                              creator={collectionCtx.nftCreator}
                              owner={asset.owner}
                              mktAddress={marketplaceAddress}
                            />
                          )}
                          {historyType === "prices" && (
                            <PricesLog history={collectionCtx.nftHistory} />
                          )}
                        </>
                      )}

                      <button
                        className="btn  px-5 text-lg"
                        onClick={() => navigate.goBack()}
                      >
                        Back to exhibition
                      </button>
                    </div>

                    <div className="col-lg-6">
                      <div className="">
                        {/* <div className="d-flex align-items-center justify-content-center">
                          <Link
                            className="text-reset"
                            to={`/users/${
                              collectionCtx.nftCreator &&
                              collectionCtx.nftCreator.account
                            }`}
                          >
                            <div className="author-avatar">
                              <span
                                className="author-avatar-inner"
                                style={{
                                  background: `url(${collectionCtx.nftCreator &&
                                    collectionCtx.nftCreator.avatar !== ""
                                    ? collectionCtx.nftCreator.avatar
                                    : "/images/Login_icon.svg"
                                    })`,
                                }}
                              ></span>
                            </div>
                          </Link>

                          <div className="ms-3 text-muted d-flex align-items-center">
                            By
                            <strong className="fw-bold lh-1 ms-2 lead text-dark">
                              <Link
                                className="text-reset"
                                to={`/users/${
                                  collectionCtx.nftCreator &&
                                  collectionCtx.nftCreator.account
                                }`}
                              >
                                {collectionCtx.nftCreator &&
                                collectionCtx.nftCreator.name !== ""
                                  ? collectionCtx.nftCreator.name
                                  : "Adi Gallia"}
                              </Link>
                            </strong>
                          </div>
                        </div> */}
                      </div>

                      {collectionCtx.nftCreator && (
                        <ItemInfoPanel
                          name={asset.title}
                          category={asset.category}
                          img={`https://nftstorage.link/ipfs/${asset?.img}`}
                          artist={collectionCtx.nftCreator.account}
                          description={asset.description}
                          dateCreated={asset.yearOfCreation}
                          royalties={asset.royalties}
                          unlockable={asset.unlockable}
                          formate={asset.formate}
                          type={asset.type}
                          price={price}
                          settings={settings}
                          story={asset.artBackgroundStory}
                          author={asset.artAuthor}
                        />
                      )}

                      <br />

                      {asset.unlockable !== "" &&
                        asset.owner === web3Ctx.account && (
                          <div className="row mb-4">
                            <div className="col-xl-8">
                              <a
                                href={asset.unlockable}
                                className="btn btn-info px-4 w-100"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="las la-cloud me-2"></i> Download
                                Content
                              </a>
                            </div>
                          </div>
                        )}

                      {nftData && collectionCtx.nftCreator && (
                        <ItemAuthor
                          history={assetHistory}
                          creator={collectionCtx.nftCreator}
                          owner={nftData[0].owner}
                          ownerName={ownerName}
                          ownerAvatar={ownerAvatar}
                          marketplaceAddress={marketplaceAddress}
                        />
                      )}

                      {collectionCtx.nftCreator && (
                        <ItemChart
                          name={asset.title}
                          category={asset.category}
                          img={`https://nftstorage.link/ipfs/${asset?.img}`}
                          artist={collectionCtx.nftCreator.account}
                          description={asset.description}
                          dateCreated={asset.dateCreated}
                          royalties={asset.royalties}
                          unlockable={asset.unlockable}
                          formate={asset.formate}
                          type={asset.type}
                        />
                      )}

                      {price ? (
                        <></>
                      ) : // <div
                      //   className="text-primary text-lg"
                      //   style={{
                      //     marginLeft: "10px",
                      //     marginBottom: "5px",
                      //     fontWeight: "600",
                      //   }}
                      // >
                      //   On Sale
                      // </div>
                      null}

                      {price ? (
                        <p
                          className="text-sm text-muted fw-normal mb-6 d-flex align-items-center"
                          style={{
                            width: "min-content",
                            fontWeight: "600",
                            backgroundColor: "#2c3234",
                            borderRadius: "50px",
                            padding: "5px 10px",
                          }}
                        >
                          <span
                            className=" overflow-hidden"
                            style={{ borderRadius: "999px" }}
                          >
                            <img
                              src="https://i.ibb.co/mXBJBnP/eth.png"
                              alt=""
                              height={30}
                              width={30}
                            />
                          </span>
                          <p
                            className="mb-0 h4 d-flex align-items-end fw-bold ms-2 text-dark text-lg"
                            style={{ padding: "5px 0" }}
                          >
                            <span
                              className="text-primary"
                              style={{ padding: "0 7px 0 0 " }}
                            >
                              {price}{" "}
                            </span>
                            {"  "}
                            {settings.currency}
                          </p>
                        </p>
                      ) : owner !== web3Ctx.account ? (
                        <p
                          className=" mb-2 d-flex justify-content-center align-items-center rounded px-3  py-2 me-2 "
                          style={{
                            width: "20ch",
                            color: "#000000",
                            fontWeight: "600",
                            backgroundColor: "#8c989b",
                          }}
                        >
                          NOT FOR SALE
                        </p>
                      ) : null}

                      {index !== -1 ? (
                        owner !== web3Ctx.account ? (
                          userCtx.userIsRegistered ? (
                            <button
                              type="button"
                              className="btn btn-gradient-primary px-4"
                              value={index}
                              onClick={buyHandler}
                              style={{ marginLeft: "10px", marginTop: "5px" }}
                            >
                              BUY
                            </button>
                          ) : (
                            <>
                              {window.ethereum &&
                                networkId === settings.networkId && (
                                  <Link
                                    to="/register"
                                    className="btn btn-primary"
                                  >
                                    <i className="las la-user me-2"></i>
                                    Register to Buy
                                  </Link>
                                )}
                            </>
                          )
                        ) : (
                          <button
                            type="button"
                            value={index}
                            className="btn btn-danger px-5"
                            onClick={cancelHandler}
                          >
                            Unlist NFT from sale
                          </button>
                        )
                      ) : owner === web3Ctx.account ? (
                        <div className="col-xl-8">
                          <button
                            className="btn btn-primary px-5"
                            type="button"
                            onClick={() => {
                              setIsModalOpen(true);
                            }}
                          >
                            Create Sale
                          </button>
                          <Modal
                            status={isModalOpen}
                            variant=""
                            modalClose={closeModalHandler}
                            layout={{ width: "500px", maxWidth: "100%" }}
                          >
                            <div className="card-body text-center p-lg-5">
                              <h4 className="mb-1">List NFT for Sale</h4>
                              {saleType === "fixedPrice" && (
                                <p className="text-muted mb-4">
                                  Add price to your NFT with {settings.currency}
                                </p>
                              )}
                              {saleType === "auction" && (
                                <p className="text-muted mb-4">
                                  Add Auction end date
                                </p>
                              )}

                              {saleType === "" && (
                                <div className="d-flex flex-column">
                                  <button
                                    className="btn btn-primary m-1 w-100"
                                    type="button"
                                    onClick={() => setSaleType("fixedPrice")}
                                  >
                                    Fixed Price
                                  </button>
                                  <button
                                    className="btn btn-info m-1 w-100"
                                    type="button"
                                    onClick={() => setSaleType("auction")}
                                  >
                                    Open For Bids
                                  </button>
                                </div>
                              )}
                              {saleType === "fixedPrice" && (
                                <form
                                  onSubmit={(e) =>
                                    makeOfferHandler(e, asset.id, key)
                                  }
                                >
                                  <input
                                    type="number"
                                    step="0.01"
                                    min="0.01"
                                    max="1000000"
                                    placeholder={`Price with ${settings.currency}...`}
                                    className="form-control mb-2"
                                    ref={priceRefs.current[key]}
                                    required={true}
                                    autoFocus={true}
                                    value={offerPrice}
                                    onChange={(e) =>
                                      e.target.value < 1000000
                                        ? setOfferPrice(
                                            Math.floor(e.target.value * 100) /
                                              100
                                          )
                                        : null
                                    }
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-primary w-100 rounded-sm mb-3"
                                  >
                                    Create sale
                                  </button>
                                  <p className="mb-0 text-center text-muted">
                                    You'll get
                                    <span className="text-primary fw-normal mx-1">
                                      {offerPrice
                                        ? offerPrice -
                                          (parseFloat(offerPrice) *
                                            settings.saleCommission) /
                                            1000
                                        : 0}
                                    </span>
                                    {settings.currency} after marketplace
                                    commission
                                  </p>
                                </form>
                              )}

                              {saleType === "auction" && (
                                <form
                                  onSubmit={(e) =>
                                    makeAuctionHandler(e, endDate, id)
                                  }
                                >
                                  <div className="row gy-3 text-start">
                                    <div className="col-12">
                                      <label className="form-label text-start fw-bold">
                                        Auction Ends At
                                      </label>
                                      <input
                                        className="form-control"
                                        type="date"
                                        value={endDate}
                                        autoFocus={true}
                                        onChange={(e) =>
                                          setEndDate(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="col-12">
                                      <button
                                        type="submit"
                                        className="btn btn-primary w-100 rounded-sm mb-2"
                                      >
                                        Create sale
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              )}
                            </div>
                          </Modal>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </section>

      {similarItems && similarItems.length > 0 && (
        <section className="pb-5 bg-light">
          <div className="container pb-5">
            <header className="mb-4">
              <div className="row">
                <div className="col-lg-6">
                  <h2
                    data-aos="fade-right"
                    data-aos-delay="100"
                    data-aos-once="true"
                  >
                    Similar from this category
                  </h2>
                  <p
                    className="text-muted lead mb-0"
                    data-aos="fade-right"
                    data-aos-delay="200"
                    data-aos-once="true"
                  >
                    We encourage you to explore more works or art that might be
                    of interest.
                  </p>
                </div>
              </div>
            </header>
            <div className="row gy-5">
              {similarItems.slice(0, 3).map((NFT, key) => {
                const index = marketplaceCtx.offers
                  ? marketplaceCtx.offers.findIndex(
                      (offer) => offer.id === NFT.id
                    )
                  : -1;
                const owner =
                  index === -1 ? NFT.owner : marketplaceCtx.offers[index].user;
                const price =
                  index !== -1
                    ? formatPrice(marketplaceCtx.offers[index].price).toFixed(2)
                    : null;

                return (
                  <div className="col-lg-4" key={key}>
                    <NftItem
                      {...NFT}
                      index={index}
                      owner={owner}
                      price={price ? price : NFT.price}
                      nftKey={key}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default ItemSingle;
