export const DECIMALS = 10 ** 18;

export const ether = (wei) => wei / DECIMALS;

export const formatPrice = (price) => {
  const precision = 1000000; // Use 2 decimal places

  price = ether(price);
  price = Math.round(price * precision) / precision;

  return price;
};

export function navbarChangeStyle() {
  const navbar = document.getElementById("navbar");
  window.addEventListener("scroll", function () {
    let windowScroll = document.scrollingElement.scrollTop;
    if (windowScroll >= 10) {
      navbar.classList.add("navbar-active");
    } else {
      navbar.classList.remove("navbar-active");
    }
  });
}

export function authCloseNavbar() {
  const navLinks = document.querySelectorAll(".navbar-nav .nav-link");
  const navbarToggler = document.querySelector(".navbar-toggler");
  const navbarCollapse = document.querySelector(".navbar-collapse");

  navLinks.forEach((link) => {
    link.addEventListener("click", function () {
      navbarCollapse.classList.remove("show");
      navbarToggler.setAttribute("aria-expanded", false);
    });
  });
}

export function formatDate(itemDate) {
  let date1 = new Date();
  let date2 = new Date(itemDate);
  // To calculate the time difference of two dates
  let Difference_In_Time = date1.getTime() - date2.getTime();
  // To calculate the no. of days between two dates
  let Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
  let Difference_In_Hours = Math.floor(Difference_In_Time / (1000 * 3600));

  if (Difference_In_Days < 1) {
    return `${Difference_In_Hours} hrs`;
  } else if (Difference_In_Days === 1) {
    return `${Difference_In_Days} day`;
  } else {
    return `${Difference_In_Days} days`;
  }
}

export function formteFullDate(date) {
  return `${new Date(date).getDate()}/${
    new Date(date).getMonth() + 1
  }/${new Date(date).getFullYear()} ${new Date(date).toLocaleTimeString(
    "en-US",
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  )}`;
}

export function formatCategory(category) {
  // if (category === 'art') {
  //     category = 'Art';
  // } else if (category === 'trendingCards') {
  //     category = 'Trading Cards';
  // } else if (category === 'game') {
  //     category = 'Game';
  // } else if (category === 'memes') {
  //     category = 'Memes';
  // } else if (category === 'collectibles') {
  //     category = 'Collectibles';
  // } else if (category === 'music') {
  //     category = 'Music';
  // } else {
  //     category = 'General';
  // }
  return category;
}

export function truncate(fullStr, strLen, separator) {
  if (fullStr.length <= strLen) return fullStr;
  separator = separator || "...";
  let sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
}

export function truncateStart(fullStr, strLen, separator) {
  if (fullStr.length <= strLen) return fullStr;
  separator = separator || "...";
  let charsToShow = strLen,
    frontChars = Math.ceil(charsToShow);

  return fullStr.substr(0, frontChars) + separator;
}

export function configEtherScanUrl(network, account) {
  let blockExplorerUrl;
  if (network === 3) {
    blockExplorerUrl = "https://ropsten.etherscan.io";
  } else if (network === 4) {
    blockExplorerUrl = "https://rinkeby.etherscan.io";
  } else if (network === 42) {
    blockExplorerUrl = "https://kovan.etherscan.io";
  } else if (network === 5) {
    blockExplorerUrl = "https://goerli.etherscan.io";
  } else if (network === 56) {
    blockExplorerUrl = "https://bscscan.com";
  } else if (network === 137) {
    blockExplorerUrl = "https://polygonscan.com";
  } else if (network === 97) {
    blockExplorerUrl = "https://testnet.bscscan.com";
  } else if (network === 80001) {
    blockExplorerUrl = "https://mumbai.polygonscan.com";
  } else {
    blockExplorerUrl = "https://etherscan.io";
  }

  return `${blockExplorerUrl}/address/${account}`;
}

export function arrayMatch(arr1, arr2) {
  var arr = [];
  arr1 = arr1.toString().split(",").map(Number);
  arr2 = arr2.toString().split(",").map(Number);
  // for array1
  for (var i in arr1) {
    if (arr2.indexOf(arr1[i]) !== -1) arr.push(arr1[i]);
  }

  return arr.sort((x, y) => x - y);
}

export function generateRandomImage(max) {
  return "https://i.postimg.cc/KzFk8y0R/avatar-6.png";
}
