import React, { useEffect, useState } from "react";
import { settings } from "../../helpers/settings";

// COMPONENTS
import PgaeBanner from "../general/PageBanner";

const techQuestions = [
  {
    question: "What is an NFT?",
    answer: (
      <p className="text-muted mb-0">
        A NFT or non-fungible token is a digital asset. NFTs are unique i.e. one
        is not equal to another. For example, a €20 note is a fungible
        asset/token as one €20 note can be exchanged for another but a pair of
        trainers worn in an Olympic final would be non-fungible as they could
        not be replaced by a pair of similar store-bought trainers.
      </p>
    ),
  },
  {
    question: "What are some important things to know about NFTs?",
    answer: (
      <p className="text-muted mb-0">
        <ul className="mb-0">
          <li>
            Each NFT has a digital signature which is crucial to prove
            authenticity.
          </li>
          <li>
            Also, each NFT will be specific to a particular artwork or digital
            image; the NFT points via a URL to this artwork/digital image.
          </li>
          <li>
            NFTs ‘exist’ on blockchains and this is how the uniqueness and
            ownership can be verified.
          </li>
          <li>Each NFT is numbered and traceable.</li>
          <li>
            Finally, NFTs are underpinned by smart contracts and these contracts
            use varying ‘standards’; Forever Collection (FCA) uses the ERC-721
            token standard. More information on this is available on the
            Technical panel.
          </li>
        </ul>
      </p>
    ),
  },
  {
    question:
      "Why own an NFT as opposed to a physical asset like a postcard or print??",
    answer: (
      <p className="text-muted mb-0">
        There are numerous reasons to own NFTs.
        <ul className="mb-0">
          <li>
            Other than it being a new, exciting and fun way to own assets, NFT
            ownership allows for your assets to exist in perpetuity (on the
            blockchain); postcards and prints will likely fade over time but the
            vibrancy of NFTs remains.
          </li>
          <li>
            NFTs are also extremely collectable and tradable; it is much easier
            to re-sell an NFT than it would be to re-sell a postcard for
            example.
          </li>
          <li>
            Also, ownership of NFTs is indisputable and as ownership passes from
            one person to the next it creates an interesting story/provenance,
            not easily possible with other assets.
          </li>
          <li>
            In a nutshell, NFTs can turn run-of-the-mill commodities into assets
            with rich variation, histories and provenance.
          </li>
        </ul>
      </p>
    ),
  },
];

const purchaseQuestions = [
  {
    question: "What is the ERC-21 standard?",
    answer: (
      <p className="text-muted mb-0">
        ERC-721 is the standard token used for NFTs on the Ethereum blockchain.
        To learn more visit:
        <a
          target="_blank"
          href="https://ethereum.org/en/developers/docs/standards/tokens/erc-721/"
        >
          https://ethereum.org/en/developers/docs/standards/tokens/erc-721/
        </a>
      </p>
    ),
  },
  {
    question: "What is a blockchain?",
    answer: (
      <>
        <p className="text-muted">
          A blockchain is a distributed, public, peer-to-peer ledger that
          records transactions.
        </p>
        <p className="text-muted ">
          In simple terms, a blockchain is a collection of computers (nodes)
          that are linked and because it has no central hub it is more stable,
          more secure, has no single point of failure and it is incredibly
          difficult to manipulate data. It is effectively unchangeable and
          tamper-proof.
        </p>
        <p className="text-muted ">
          There are numerous blockchains currently in existence; Forever
          Collection uses Ethereum.
        </p>
        <p className="text-muted ">
          Every transaction on each chain has to be approved by a set number of
          nodes in order for it to permanently become part of the chain.
        </p>
        <p className="text-muted ">
          In order for changes to the chain to occur gas fees have to be paid.
        </p>
      </>
    ),
  },
  {
    question: "What are gas fees?",
    answer: (
      <>
        <p className="text-muted">
          Gas fees are the fees used in the Ethereum network to incentivise
          blockchain participants (‘miners’) to fulfil the transaction request.
        </p>
        <p className="text-muted">
          They are the cost of making an alteration to the blockchain.
        </p>
        <p className="text-muted">
          Gas fees are paid in Ether (ETH), which is the cryptocurrency used on
          the Ethereum blockchain. Like pounds are made up of pence, ETH is made
          up of gwei; one ETH is worth the equivalent of one billion gwei.
        </p>
        <p className="text-muted">
          Given the price of one ETH is many thousands of pounds for simplicity
          gas prices are often expressed in gwei.
        </p>
        <p className="text-muted">Gas fees are paid by Forever Collection.</p>
        <p className="text-muted">
          Gas fees are the cost of creating an NFT but minting is the process of
          creating an NFT.
        </p>
        <p className="text-muted">
          To find out more visit: Ethereum Gas Explained in Plain English
        </p>
      </>
    ),
  },
  {
    question: "What is meant by minting an NFT?",
    answer: (
      <>
        <p className="text-muted">
          Minting is the process behind how the NFT becomes part of the Ethereum
          blockchain.
        </p>
        <p className="text-muted">
          Just like how fiat coins, for example, pound coins, are minted, NFTs
          are also tokens that are minted and put into circulation on the
          blockchain.
        </p>
        <p className="text-muted">
          Minting is a technical process and this is something Forever
          Collection carries out.
        </p>
        <p className="text-muted">
          In simple terms, minting generates a token ID which sits on the
          blockchain and which in turn is linked to a specific URL which carries
          details of the artwork.
        </p>
      </>
    ),
  },
  {
    question: "Is all information held on the blockchain?",
    answer: (
      <p className="text-muted mb-0">
        No. Some information is held on the blockchain (on-chain) and other
        information is stored on the IPFS, which is explained below
      </p>
    ),
  },
  {
    question: "What data is stored on-chain?",
    answer: (
      <p className="text-muted mb-0">
        When an NFT is minted the following items are defined and recorded
        on-chain: artwork details, artwork data (e.g. some information about the
        edition), scarcity (Unique, Ultra-Rare, Rare, Limited etc.), total
        number minted, the total number remaining to be purchased, and IPFS
        hash.
      </p>
    ),
  },
  {
    question: "What is meant by a hash?",
    answer: (
      <>
        <p className="text-muted ">
          Simply put, a hash is part of the suite of cryptographic protections
          that underpin the integrity of the blockchain; they ensure only
          authorized changes are permitted on the chain.
        </p>
      </>
    ),
  },
];
const practicalQuestions = [
  {
    question: "If I buy an NFT, where do I store it?",
    answer: (
      <>
        <p className="text-muted">
          NFTs are stored in digital wallets, sometimes called crypto wallets.
          Forever Collection is compatible with Metamask wallets.
        </p>
        <p className="text-muted">
          You can access the wallet via a website or app and you export NFTs
          using your private key, which is also provided.
        </p>
        <p className="text-muted">
          Each wallet has an address and this address ‘exists’ on the
          blockchain.
        </p>
      </>
    ),
  },
  {
    question: "If I have an existing digital wallet, can I just use that?",
    answer: (
      <>
        <p className="text-muted">
          Yes. You simply do the following:
          <ol className="mb-0">
            <li>Go to the “Login” Page</li>
            <li>Click on the “Connect with my wallet” button</li>
          </ol>
        </p>
      </>
    ),
  },
  {
    question: "Can I change my wallet provider?",
    answer: (
      <>
        <p className="text-muted">Yes. You can do that.</p>
      </>
    ),
  },
  {
    question: "What are the environmental costs of owning an NFT?",
    answer: (
      <>
        <p className="text-muted">
          NFT environmental impacts are presently higher than we would ideally
          like. This is because Ethereum currently uses Proof of Work, which is
          very energy intensive. However, in 2022 Ethereum 2.0 will be released,
          which uses Proof of Stake and the energy consumption is less than 1%
          of that used in Proof of Work.
        </p>
        <p className="text-muted">
          Forever Collection will adopt Ethereum 2.0 as soon as it is made
          available. However, given the importance of climate impacts between
          now and then for every British Museum x Forever Collection NFT
          purchased on the platform Forever Collection will sponsor the planting
          of a new tree.
        </p>
        <p className="text-muted">
          Each tree over its life will absorb as much as a tonne of CO2,
          considerably more than generated in the process of buying and owning a
          single NFT. For further information on Proof of Stake Systems, see:
          https://ethereum.org/en/developers/docs/consensus-mechanisms/pos/
        </p>
      </>
    ),
  },
];

const licenseQuestions = [
  {
    question: "How can I buy a Forever Collection NFT?",
    answer: (
      <>
        <p className="text-muted">
          You can buy a Forever Collection NFT either using your credit card or
          the cryptocurrency Ether. <br /> There are two ways to buy: open
          auction and fixed price.
        </p>
      </>
    ),
  },
  {
    question: "How does an open auction work?",
    answer: (
      <>
        <p className="text-muted">
          Bidding in an open auction is easy. Simply place a bid on the edition
          you want and wait for the auction to end. If another user places a
          higher bid, you will be re-credited and you will get notified so that
          you have a chance to place a higher bid. For all primary sales, at the
          end of the auction, the user with the highest bid wins the auction and
          the edition is transferred to their Gallery
        </p>
      </>
    ),
  },
  {
    question: "How do I sell my Forever Collection NFT?",
    answer: (
      <>
        <p className="text-muted">
          When looking at the My Gallery page, if you are the owner, you will
          find an option to put the NFT up for sale. However, Forever Collection
          is in charge of whitelisting the credible sellers, so feel free to
          email us as business@forevercollection.art
        </p>
      </>
    ),
  },
  {
    question:
      "How long does it take for my NFT or ETH to arrive after I have bought or sold my NFT?",
    answer: (
      <>
        <p className="text-muted">
          We are normally able to guarantee quick transactions with a maximum
          wait of 24 hours. However, there are times when delays occur.
        </p>
      </>
    ),
  },
  {
    question: "Why do transaction delays sometimes occur?",
    answer: (
      <>
        <p className="text-muted">
          Following a surge in demand for Ethereum-based projects, there can be
          a significant increase in gas fees. In unexpected peak times, we can
          experience issues that may affect your Forever Collection experience.
          When gas fees are high NFT and ETH transfers can take up to a few
          days.
        </p>
      </>
    ),
  },
];

const supportQuestions = [
  {
    question: "Which wallet is connected by default?",
    answer: (
      <>
        <p className="text-muted">
          "The wallet connected by default is the one linked to the provider you used to log in. You can find your connected wallet by a green dot in the upper right-hand corner of the wallet information.",

        </p>
      </>),
  },
  {
    question: "How can I transfer ETH from one wallet to another?",
    answer: (
      <>
        <p className="text-muted">
          To transfer ETH from one wallet to another, you need to have at least
          two wallets linked to your account. Once you have two wallets linked,
          open the wallet manager, select the wallet from which you would like
          to transfer ETH and follow the steps.
        </p>
      </>
    ),
  },
  {
    question: "How can I switch from one wallet to another?",
    answer: (
      <>
        <p className="text-muted">
          To switch from one wallet to another, open the wallet manager and
          select the “Switch” button. Then select the wallet you want to connect
          to and follow the steps. At the end of the process, you may receive a
          signature request depending on the wallet provider.
        </p>
      </>
    ),
  },
  {
    question:
      "I tried to connect with a wallet I’m having trouble. What should I do?",
    answer: (
      <>
        <p className="text-muted">
          If you are encountering issues with connecting or with the wallet
          manager itself, you may reach out to us at
          support@forevercollection.art
        </p>
      </>
    ),
  },
];

function FAQ() {
  /*** =============================================== */
  //      CHANGE PAGE TITLE
  /*** =============================================== */
  useEffect(() => {
    document.title = `FAQ | ${settings.UISettings.marketplaceBrandName}`;
  }, []);

  const [tab, setTab] = useState("tech-questions");

  return (
    <>
      <PgaeBanner heading="Frequently Asked Questions" />

      <section className="py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-8 order-2 order-lg-1">
              {tab === "tech-questions" && (
                <>
                  <h2 className="mb-4">General</h2>
                  <div className="accordion" id="accordionExample">
                    {techQuestions.map((el, index) => {
                      return (
                        <div className="accordion-item mb-3" key={index}>
                          <h2
                            className="accordion-header"
                            id={`heading${index + 1}`}
                          >
                            <button
                              className={`accordion-button shadow-0 fw-bold ${index === 0 ? "" : "collapsed"
                                }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${index + 1}`}
                              aria-expanded={index === 0 ? true : false}
                              aria-controls={`collapse${index + 1}`}
                            >
                              {el.question}
                            </button>
                          </h2>
                          <div
                            id={`collapse${index + 1}`}
                            className={`accordion-collapse collapse ${index === 0 ? "show" : ""
                              }`}
                            aria-labelledby={`heading${index + 1}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">{el.answer}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {tab === "purchase" && (
                <>
                  <h2 className="mb-4">Technical Information</h2>
                  <div className="accordion" id="accordionExample">
                    {purchaseQuestions.map((el, index) => {
                      return (
                        <div className="accordion-item mb-3" key={index}>
                          <h2
                            className="accordion-header"
                            id={`heading${index + 1}`}
                          >
                            <button
                              className={`accordion-button shadow-0 fw-bold ${index === 0 ? "" : "collapsed"
                                }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${index + 1}`}
                              aria-expanded={index === 0 ? true : false}
                              aria-controls={`collapse${index + 1}`}
                            >
                              {el.question}
                            </button>
                          </h2>
                          <div
                            id={`collapse${index + 1}`}
                            className={`accordion-collapse collapse ${index === 0 ? "show" : ""
                              }`}
                            aria-labelledby={`heading${index + 1}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">{el.answer}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {tab === "practical" && (
                <>
                  <h2 className="mb-4">Practical Information</h2>
                  <div className="accordion" id="accordionExample">
                    {practicalQuestions.map((el, index) => {
                      return (
                        <div className="accordion-item mb-3" key={index}>
                          <h2
                            className="accordion-header"
                            id={`heading${index + 1}`}
                          >
                            <button
                              className={`accordion-button shadow-0 fw-bold ${index === 0 ? "" : "collapsed"
                                }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${index + 1}`}
                              aria-expanded={index === 0 ? true : false}
                              aria-controls={`collapse${index + 1}`}
                            >
                              {el.question}
                            </button>
                          </h2>
                          <div
                            id={`collapse${index + 1}`}
                            className={`accordion-collapse collapse ${index === 0 ? "show" : ""
                              }`}
                            aria-labelledby={`heading${index + 1}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">{el.answer}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {tab === "license" && (
                <>
                  <h2 className="mb-4">Buying & Selling NFTs</h2>
                  <div className="accordion" id="accordionExample">
                    {licenseQuestions.map((el, index) => {
                      return (
                        <div className="accordion-item mb-3" key={index}>
                          <h2
                            className="accordion-header"
                            id={`heading${index + 1}`}
                          >
                            <button
                              className={`accordion-button shadow-0 fw-bold ${index === 0 ? "" : "collapsed"
                                }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${index + 1}`}
                              aria-expanded={index === 0 ? true : false}
                              aria-controls={`collapse${index + 1}`}
                            >
                              {el.question}
                            </button>
                          </h2>
                          <div
                            id={`collapse${index + 1}`}
                            className={`accordion-collapse collapse ${index === 0 ? "show" : ""
                              }`}
                            aria-labelledby={`heading${index + 1}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">{el.answer}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {tab === "support" && (
                <>
                  <h2 className="mb-4">Wallet Management</h2>
                  <div className="accordion" id="accordionExample">
                    {supportQuestions.map((el, index) => {
                      return (
                        <div className="accordion-item mb-3" key={index}>
                          <h2
                            className="accordion-header"
                            id={`heading${index + 1}`}
                          >
                            <button
                              className={`accordion-button shadow-0 fw-bold ${index === 0 ? "" : "collapsed"
                                }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${index + 1}`}
                              aria-expanded={index === 0 ? true : false}
                              aria-controls={`collapse${index + 1}`}
                            >
                              {el.question}
                            </button>
                          </h2>
                          <div
                            id={`collapse${index + 1}`}
                            className={`accordion-collapse collapse ${index === 0 ? "show" : ""
                              }`}
                            aria-labelledby={`heading${index + 1}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">{el.answer}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>

            <div className="col-lg-4 order-1 order-lg-2">
              <h2 className="mb-4">Quick Nav</h2>

              <div className="card bg-light shadow-0">
                <div className="card-body p-4 p-lg-5">
                  <ul className="p-0 m-0 list-unstyled">
                    <li className="mb-3">
                      <button
                        className={`btn btn-link p-0 fw-bold text-decoration-none shadow-0 ${tab === "tech-questions"
                            ? "text-primary"
                            : "text-dark"
                          }`}
                        onClick={() => setTab("tech-questions")}
                      >
                        General
                      </button>
                    </li>
                    <li className="mb-3">
                      <button
                        className={`btn btn-link p-0 fw-bold text-decoration-none shadow-0 ${tab === "purchase" ? "text-primary" : "text-dark"
                          }`}
                        onClick={() => setTab("purchase")}
                      >
                        Technical Information
                      </button>
                    </li>
                    <li className="mb-3">
                      <button
                        className={`btn btn-link p-0 fw-bold text-decoration-none shadow-0 ${tab === "practical" ? "text-primary" : "text-dark"
                          }`}
                        onClick={() => setTab("practical")}
                      >
                        Practical Information
                      </button>
                    </li>
                    <li className="mb-3">
                      <button
                        className={`btn btn-link p-0 fw-bold text-decoration-none shadow-0 ${tab === "license" ? "text-primary" : "text-dark"
                          }`}
                        onClick={() => setTab("license")}
                      >
                        Buying & Selling NFTs
                      </button>
                    </li>
                    <li className="mb-3">
                      <button
                        className={`btn btn-link p-0 fw-bold text-decoration-none shadow-0 ${tab === "support" ? "text-primary" : "text-dark"
                          }`}
                        onClick={() => setTab("support")}
                      >
                        Wallet Management
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FAQ;
