import React, { useEffect } from "react";
import { settings } from "../../helpers/settings";

import PageBanner from "../general/PageBanner";
import AboutInfo from "../about/AboutInfo";
function About() {
  useEffect(() => {
    document.title = `About Us | ${settings.UISettings.marketplaceBrandName}`;
  });
  return (
    <>
      <PageBanner heading={"About Us"} />
      <section className="py-5">
        <div className="container py-5">
          <div className="row g-5">
            <AboutInfo gridWidth="col-lg-8 mx-auto" />
            <h5 className="col-lg-8 mx-auto">
              We’re preserving art in a digital museum. Create, sell, and
              collect digital art NFTs in your own decentralized blockchain
              ecosystem. The Forever Collection facilitates a new way of
              cataloguing, preserving, and sharing art. Our goal is to make art
              accessible and easier to digitally preserve.
            </h5>
            <div className="col-12 text-center">
              <h2 className="h3 mb-1">
                Subscribe to our newsletter to stay up to date on the latest
                news and auctions.
              </h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default About;