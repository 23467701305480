import React from "react";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const melodyStyle = {
  fontSize: "7rem",
  color: "#fff",
  position: "absolute",
  top: "calc(50% - 3rem)",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function ItemThumbnail({ thumbnail, type }) {
  return (
    <div
      className={`select-none pointer-events-none card shadow rounded-xl ${
        type === "audio" ? "audio" : ""
      }`}
    >
      <div className="card-body position-relative d-flex align-items-end">
        {type === "image" ? (
          <img className="img-fluid rounded w-100" src={thumbnail} alt="..." />
        ) : type === "audio" ? (
          <>
            <i className="las la-music" style={melodyStyle}></i>
            <AudioPlayer
              src={thumbnail}
              autoPlayAfterSrcChange={false}
              showJumpControls={false}
            />
          </>
        ) : (
          <ReactPlayer
            url={thumbnail}
            controls={true}
            width="100%"
            height="auto"
          />
        )}
      </div>
    </div>
  );
}

export default ItemThumbnail;
