import React, { useEffect, useContext, useState } from "react";
import AuctionContext from "../../providers/auction-context";
import UserContext from "../../providers/user-context";
import Web3Context from "../../providers/web3-context";
import DataTable, { createTheme } from "react-data-table-component";
import { formatPrice, formteFullDate } from "../../helpers/utils";
import { settings } from "../../helpers/settings";
import { useToasts } from "react-toast-notifications";

createTheme(
  "solarized",
  {
    background: {
      default: "#2c3234",
    },
  },
  "dark"
);

function UserBidsTable() {
  const auctionCtx = useContext(AuctionContext);
  const web3Ctx = useContext(Web3Context);
  const userCtx = useContext(UserContext);
  const [activeBids, setActiveBids] = useState([]);
  const { addToast } = useToasts();

  /*** =============================================== */
  //      GET USER BIDS
  /*** =============================================== */
  useEffect(() => {
    if (auctionCtx.contract) {
      auctionCtx.loadUserBids(auctionCtx.contract, web3Ctx.account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionCtx.contract, web3Ctx.account]);

  /*** =============================================== */
  //      GET USER ACTIVE BIDS
  /*** =============================================== */
  useEffect(() => {
    if (auctionCtx.contract) {
      setActiveBids(auctionCtx.userBids.filter((bid) => bid.amount !== 0));
    }
  }, [auctionCtx.contract, web3Ctx.account, auctionCtx.userBids]);

  /*** =============================================== */
  //      WITHDRAW BID FUNCTION
  /*** =============================================== */
  const withdrawBidHandler = (event, tokenId, auctionId) => {
    event.preventDefault();

    auctionCtx.contract.methods
      .withdraw(tokenId, auctionId)
      .send({ from: web3Ctx.account })
      .once("sending", () => {
        auctionCtx.setAuctionTransactionLoading(true);
      })
      .on("transactionHash", (hash) => {
        auctionCtx.setAuctionTransactionLoading(true);
      })
      .on("receipt", () => {
        auctionCtx.setAuctionTransactionLoading(false);
        userCtx.loadTransactions(userCtx.contract);
        userCtx.loadActivity(userCtx.contract);
        auctionCtx.loadUserBids(auctionCtx.contract, web3Ctx.account);
      })
      .on("error", (error) => {
        addToast("Oops! an error occured", {
          appearance: "error",
        });
        auctionCtx.setAuctionTransactionLoading(false);
      });
  };

  /*** =============================================== */
  //      TABLE COLUMNS
  /*** =============================================== */
  const columns = [
    {
      name: "Auction",
      selector: (row) => row.tokenId,
      cell: (row) => (
        <div row={row}>
          {auctionCtx.auctionsData.length > 0 && (
            <div className="d-flex align-items-center">
              <div className="author-avatar rounded-xl">
                <span
                  className="author-avatar-inner rounded-xl"
                  style={{
                    background: `url(https://nftstorage.link/ipfs/${
                      auctionCtx.auctionsData.filter(
                        (auc) => auc.tokenId === row.tokenId
                      )[0]?.img
                    })`,
                  }}
                ></span>
              </div>
              <p className="fw-bold mb-0 ms-3">
                {
                  auctionCtx.auctionsData.filter(
                    (auc) => auc.tokenId === row.tokenId
                  )[0].title
                }
              </p>
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Bid Time",
      selector: (row) => row.bidTime,
      cell: (row) => (
        <div row={row}>
          <p className="mb-0 fw-bold">{formteFullDate(row.bidTime)}</p>
        </div>
      ),
    },
    {
      name: "Bid Amount",
      selector: (row) => row.amount,
      cell: (row) => (
        <div row={row}>
          <p className="mb-0 fw-bold">
            {formatPrice(row.amount).toFixed(3)} {settings.currency}
          </p>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.tokenId,
      cell: (row) => (
        <div row={row}>
          {auctionCtx.auctionsData.length > 0 && (
            <button
              className="btn btn-danger btn-sm pt-2"
              type="button"
              onClick={(e) =>
                withdrawBidHandler(
                  e,
                  row.tokenId,
                  auctionCtx.auctionsData.filter(
                    (auc) => auc.tokenId === row.tokenId
                  )[0].auctionId
                )
              }
            >
              Withdraw
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    activeBids.length > 0 && (
      <>
        <h3 className="h3 mb-4 text-center">My Bids</h3>
        <DataTable
          columns={columns}
          data={activeBids}
          pagination={activeBids.length >= 10 && true}
          responsive
          theme="solarized"
        />
      </>
    )
  );
}

export default UserBidsTable;
