import realismImg from "../icons/realism.svg";
import ModernArt from "../icons/modern art.svg";
import ImpressionismImg from "../icons/impressionism.svg";
import AbstractionImg from "../icons/abstraction.svg";
import Photorealism from "../icons/photorealism.svg";
import Collectibles from "../icons/collectibles.svg";

import RealismImg_white from "../icons/Realism_white.svg";
import ModernArt_white from "../icons/Modern art_white.svg";
import ImpressionismImg_white from "../icons/Impressionism_white.svg";
import AbstractionImg_white from "../icons/Abstraction_white.svg";
import Photorealism_white from "../icons/Photorealism_white.svg";
import Collectibles_white from "../icons/Collectibles_white.svg";

export const categoryOptions = [
  { label: "Realism", value: "Realism", icon: realismImg },
  { label: "Modern Art", value: "Modern Art", icon: ModernArt },
  { label: "Impressionism", value: "Impressionism", icon: ImpressionismImg },
  { label: "Abstraction", value: "Abstraction", icon: AbstractionImg },
  { label: "Photorealism", value: "Photorealism", icon: Photorealism },
  { label: "Collectibles", value: "Collectibles", icon: Collectibles },
];

export const categoryIcons = {
  Realism: realismImg,
  "Modern Art": ModernArt,
  Impressionism: ImpressionismImg,
  Abstraction: AbstractionImg,
  Photorealism: Photorealism,
  Collectibles: Collectibles,
};
// export const categoryIcons_white = {
//   Realism: realismImg,
//   "Modern Art": ModernArt,
//   Impressionism: ImpressionismImg,
//   Abstraction: AbstractionImg,
//   Photorealism: Photorealism,
//   Collectibles: Collectibles,
// };

export const categoryIcons_white = {
  Realism: RealismImg_white,
  "Modern Art": ModernArt_white,
  Impressionism: ImpressionismImg_white,
  Abstraction: AbstractionImg_white,
  Photorealism: Photorealism_white,
  Collectibles: Collectibles_white,
};

export const categorySelectBox = [
  { label: "All", value: "all" },
  { label: "Realism", value: "Realism" },
  { label: "Modern Art", value: "Modern Art" },
  { label: "Impressionism", value: "Impressionism" },
  { label: "Abstraction", value: "Abstraction" },
  { label: "Photorealism", value: "Photorealism" },
  { label: "Collectibles", value: "Collectibles" },
];

export const particlesOptions = {
  fpsLimit: 15,
  fullScreen: {
    enable: false,
  },
  particles: {
    color: {
      value: "#3275ac",
    },
    links: {
      color: "#ffffff",
      distance: 150,
      enable: false,
      opacity: 0,
      width: 1,
    },
    collisions: {
      enable: false,
    },
    move: {
      direction: "top",
      enable: true,
      outMode: "out",
      random: true,
      speed: 0.5,
      straight: false,
    },
    number: {
      value: 250,
    },
    opacity: {
      value: 0.5,
    },
    shape: {
      type: "circle",
    },
    size: {
      random: true,
      value: 4,
    },
  },
  detectRetina: true,
};
