import React, { useEffect, useContext, useState, useMemo } from "react";
import CollectionContext from "../../providers/collection-context";
import MarketplaceContext from "../../providers/marketplace-context";
import AuctionContext from "../../providers/auction-context";
import { formatPrice } from "../../helpers/utils";
import { Link } from "react-router-dom";
import { categorySelectBox } from "../../helpers/constants";
import { settings } from "../../helpers/settings";
import Select from "react-dropdown-select";

// COMPONENTS
import PageBanner from "../general/PageBanner";
import NftItem from "../general/NftItem";
import MetaMaskLoader from "../general/MetaMaskLoader";
import FullScreenLoader from "../general/FullScreenLoader";

// SELECT OPTIONS
const priceOptions = [
  { label: "All", value: "all" },
  { label: "Only on Sale", value: "saleOnly" },
  { label: "Not for sale", value: "notForSale" },
];

const sorting = [
  { label: "Newest First", value: "newest" },
  { label: "Oldest First", value: "oldest" },
  { label: "Highest Price", value: "highPrice" },
  { label: "Lowest Price", value: "lowPrice" },
];

function Explore() {
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const auctionCtx = useContext(AuctionContext);
  const [baseFilter, setBaseFilter] = useState("all");
  const [sortFilter, setSortFilter] = useState("all");
  const [priceFilter, setPriceFilter] = useState("all");
  const [withPriceCollection, setWithPriceCollection] = useState([]);
  const [renderedItems, setRenderedItems] = useState(9);

  /*** =============================================== */
  //      CHANGE PAGE TITLE
  /*** =============================================== */
  useEffect(() => {
    document.title = `Explore NFTs | ${settings.UISettings.marketplaceBrandName}`;
  }, []);

  /*** =============================================== */
  //      MERGE NFT COLLECTION WITH NFT OFFERS
  /*** =============================================== */
  useEffect(() => {
    if (
      marketplaceCtx.contract &&
      collectionCtx.contract &&
      collectionCtx.collection.length > 0
    ) {
      let offersMap = marketplaceCtx.offers.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      let combined = collectionCtx.collection.map((d) =>
        Object.assign(d, offersMap[d.id])
      );
      let final = combined.map((el) => {
        if (el.price) {
          return { ...el, owner: el.user };
        } else if (!el.price) {
          return { ...el, price: 0 };
        }
        return { ...el };
      });
      setWithPriceCollection(final);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    marketplaceCtx.offers,
    collectionCtx.contract,
    marketplaceCtx.contract,
    collectionCtx.collection,
    auctionCtx.auctions,
  ]);

  /*** =============================================== */
  //      GET BASE CATEGORIES
  /*** =============================================== */
  const baseExploreItems = useMemo(() => {
    if (baseFilter === "all") {
      return collectionCtx.collection.filter(
        (nft) =>
          !auctionCtx.auctions
            .filter((auc) => auc.isActive === true)
            .some((auc) => nft.id === auc.tokenId)
      );
    } else {
      return collectionCtx.collection
        .filter(
          (nft) =>
            !auctionCtx.auctions
              .filter((auc) => auc.isActive === true)
              .some((auc) => nft.id === auc.tokenId)
        )
        .filter((item) => item.category === baseFilter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    priceFilter,
    sortFilter,
    baseFilter,
    collectionCtx.collection,
    withPriceCollection,
    auctionCtx.auctions,
    auctionCtx.auctionsData,
  ]);

  /*** =============================================== */
  //      FORMATE ACCORDING TO SALE STATUS
  /*** =============================================== */
  const priceFilteredItems = useMemo(() => {
    if (priceFilter === "saleOnly") {
      return baseExploreItems.filter(
        (item) =>
          marketplaceCtx.offers.some((offer) => item.id === offer.id) ||
          item.lazy === true
      );
    } else if (priceFilter === "notForSale") {
      return baseExploreItems.filter(
        (item) =>
          !marketplaceCtx.offers.some((offer) => item.id === offer.id) &&
          item.lazy === undefined
      );
    } else {
      return baseExploreItems;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseFilter,
    sortFilter,
    priceFilter,
    baseExploreItems,
    collectionCtx.collection,
  ]);

  /*** =============================================== */
  //      SORTING COLLECTION
  /*** =============================================== */
  const updatedExploreItems = useMemo(() => {
    if (sortFilter === "newest") {
      return priceFilteredItems.sort((a, b) => {
        return new Date(b.dateCreated) - new Date(a.dateCreated);
      });
    } else if (sortFilter === "oldest") {
      return priceFilteredItems.sort((a, b) => {
        return new Date(a.dateCreated) - new Date(b.dateCreated);
      });
    } else if (sortFilter === "highPrice") {
      return withPriceCollection
        .filter((x) => priceFilteredItems.some((y) => x.id === y.id))
        .filter((el) => el.price > 0)
        .sort((a, b) => {
          return b.price - a.price;
        });
    } else if (sortFilter === "lowPrice") {
      return withPriceCollection
        .filter((x) => priceFilteredItems.some((y) => x.id === y.id))
        .filter((el) => el.price > 0)
        .sort((a, b) => {
          return a.price - b.price;
        });
    } else {
      return priceFilteredItems;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortFilter, priceFilteredItems, priceFilter, baseExploreItems]);

  console.log(collectionCtx.collection);
  console.log(collectionCtx.totalSupply);
  return (
    <>
      {auctionCtx.fetchingLoading ? (
        <FullScreenLoader heading="Updating NFTs" />
      ) : null}
      {marketplaceCtx.mktIsLoading ? (
        <FullScreenLoader heading="Fetching NFTs" />
      ) : null}
      {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
      {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}
      <PageBanner heading={"Explore Exhibitions"} />
      <section className="py-5">
        {/* FILTER CONTROLS */}
        <div className="container pt-5">
          {collectionCtx.collection.length !== 0 && (
            <header className="mb-2">
              <ul className="list-inline mb-0">
                <li className="list-inline-item mb-3 me-3">
                  <p className="text-sm fw-bold pe-lg-4 mb-3">
                    Filter by Category
                  </p>
                  <div className="input-icon flex-nowrap category-select">
                    {/* <div className='input-icon-text bg-none'>
                                            <i className='las la-icons text-primary z-index-20'></i>
                                        </div> */}
                    <Select
                      searchable={false}
                      options={categorySelectBox}
                      className="form-select rounded-xl border-gray-300 shadow-0 bg-white ps- "
                      value={baseFilter}
                      onChange={(values) => {
                        setBaseFilter(values.map((el) => el.value).toString());
                      }}
                    />
                  </div>
                </li>
                <li className="list-inline-item mb-3 me-3">
                  <p className="text-sm fw-bold pe-lg-4 mb-3">
                    Filter by Price
                  </p>
                  <div className="input-icon flex-nowrap category-select">
                    {/* <div className='input-icon-text bg-none'>
                                            <i className='lab la-ethereum text-primary z-index-20'></i>
                                        </div> */}
                    <Select
                      searchable={false}
                      options={priceOptions}
                      className="form-select rounded-xl border-gray-300 shadow-0 bg-white ps-"
                      value={priceFilter}
                      onChange={(values) =>
                        setPriceFilter(values.map((el) => el.value).toString())
                      }
                    />
                  </div>
                </li>
                <li className="list-inline-item mb-3">
                  <p className="text-sm fw-bold pe-lg-4 mb-3">Sort By</p>
                  <div className="input-icon flex-nowrap category-select">
                    {/* <div className='input-icon-text bg-none'>
                                            <i className='lab la-ethereum text-primary z-index-20'></i>
                                        </div> */}
                    <Select
                      searchable={false}
                      options={sorting}
                      className="form-select rounded-xl border-gray-300 shadow-0 bg-white ps-"
                      value={sortFilter}
                      onChange={(values) =>
                        setSortFilter(values.map((el) => el.value).toString())
                      }
                    />
                  </div>
                </li>
              </ul>
            </header>
          )}

          {collectionCtx.collection.length !== 0 ? (
            <>
              <div className="row gy-4 mb-5 align-items-stretch">
                {updatedExploreItems.slice(0, renderedItems).map((NFT, key) => {
                  const index = marketplaceCtx.offers
                    ? marketplaceCtx.offers.findIndex(
                        (offer) => offer.id === NFT.id
                      )
                    : -1;
                  const owner =
                    index === -1
                      ? NFT.owner
                      : marketplaceCtx.offers[index].user;
                  const price =
                    index !== -1
                      ? formatPrice(marketplaceCtx.offers[index].price).toFixed(
                          2
                        )
                      : null;

                  return (
                    <div
                      className={`col-xl-4 col-md-6 ${NFT.category}`}
                      key={NFT.id}
                    >
                      <NftItem
                        {...NFT}
                        noAnimation={true}
                        index={index}
                        owner={owner}
                        price={price ? price : NFT.price}
                        nftKey={key}
                        // category={category}
                      />
                    </div>
                  );
                })}
              </div>
              {updatedExploreItems.length > renderedItems && (
                <div className="text-center pt-4">
                  <button
                    className="btn btn-dark"
                    type="button"
                    onClick={() => setRenderedItems(renderedItems + 3)}
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="text-center">
                <h4 className="text-center">There're no NFTs at the moment</h4>
                <p className="text-muted mb-3">
                  Once you've created an NFT we'll render it here
                </p>
                <Link className="btn btn-gradient-primary mb-5" to="/mint">
                  Create NFT
                </Link>
              </div>
            </>
          )}

          {collectionCtx.collection.length !== 0 &&
            collectionCtx.totalSupply !== "0" &&
            updatedExploreItems.length === 0 && (
              <h4 className="text-center">
                There're no NFTs match your filter
              </h4>
            )}

          {/* <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={collectionCtx.collection.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    /> */}
        </div>
      </section>
    </>
  );
}

export default Explore;
