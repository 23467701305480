import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { formteFullDate, truncateStart } from "../../helpers/utils";
import { settings } from "../../helpers/settings";
import UserContext from "../../providers/user-context";
import DataTable, { createTheme } from "react-data-table-component";
import CollectionContext from "../../providers/collection-context";
import MarketplaceContext from "../../providers/marketplace-context";
import AuctionContext from "../../providers/auction-context";
import ReactPlayer from "react-player";

createTheme(
  "solarized",
  {
    background: {
      default: "#2c3234",
    },
  },
  "dark"
);

function NftTable({ blockNfts }) {
  const userCtx = useContext(UserContext);
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const auctionCtx = useContext(AuctionContext);
  const [collection, setCollection] = useState(null);
  const [selectedNfts, setSelectedNfts] = useState([]);

  /*** =============================================== */
  //      MERGE NFT COLLECTION WITH NFT OFFERS
  /*** =============================================== */
  useEffect(() => {
    if (
      (marketplaceCtx.contract &&
        collectionCtx.contract &&
        collectionCtx.collection.length > 0 &&
        collectionCtx.collection &&
        marketplaceCtx.contractAddress,
      userCtx.usersList)
    ) {
      let offersMap = marketplaceCtx.offers.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      let combined = collectionCtx.collection
        .filter(
          (nft) =>
            !auctionCtx.auctions
              .filter((auc) => auc.isActive === true)
              .some((auc) => nft.id === auc.tokenId)
        )
        .filter((nft) => !nft.lazy)
        .map((d) => Object.assign(d, offersMap[d.id]));
      console.log("combined", combined);
      let final = combined.map((el) => {
        if (el.price) {
          return {
            ...el,
            ownerName:
              el.owner === marketplaceCtx.contractAddress
                ? userCtx.usersList.filter(
                    (user) => user.account === el.user
                  )[0].fullName
                : userCtx.usersList.filter(
                    (user) => user.account === el.owner
                  )[0].fullName,
            ownerEmail:
              el.owner === marketplaceCtx.contractAddress
                ? userCtx.usersList.filter(
                    (user) => user.account === el.user
                  )[0].email
                : userCtx.usersList.filter(
                    (user) => user.account === el.owner
                  )[0].email,
            ownerAvatar:
              el.owner === marketplaceCtx.contractAddress
                ? userCtx.usersList.filter(
                    (user) => user.account === el.user
                  )[0].avatar
                : userCtx.usersList.filter(
                    (user) => user.account === el.owner
                  )[0].avatar,
          };
        } else if (!el.price) {
          return {
            ...el,
            price: 0,
            ownerName:
              el.owner === marketplaceCtx.contractAddress
                ? userCtx.usersList.filter(
                    (user) => user.account === el.user
                  )[0].fullName
                : userCtx.usersList.filter(
                    (user) => user.account === el.owner
                  )[0].fullName,
            ownerEmail:
              el.owner === marketplaceCtx.contractAddress
                ? userCtx.usersList.filter(
                    (user) => user.account === el.user
                  )[0].email
                : userCtx.usersList.filter(
                    (user) => user.account === el.owner
                  )[0].email,
            ownerAvatar:
              el.owner === marketplaceCtx.contractAddress
                ? userCtx.usersList.filter(
                    (user) => user.account === el.user
                  )[0].avatar
                : userCtx.usersList.filter(
                    (user) => user.account === el.owner
                  )[0].avatar,
          };
        }
        return { ...el };
      });
      setCollection(final);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    marketplaceCtx.offers,
    collectionCtx.collection,
    collectionCtx.contract,
    marketplaceCtx.contract,
    collectionCtx.collection,
    collectionCtx.nftHistory,
    marketplaceCtx.contractAddress,
    userCtx.usersList,
  ]);

  /*** =============================================== */
  //      TABLE COLUMNS
  /*** =============================================== */
  const columns = [
    {
      name: "NFT Title",
      selector: (row) => row.title,
      cell: (row) => (
        <div row={row}>
          <Link className="text-reset" to={`/assets/${row.id}`}>
            <div className="d-flex align-items-center py-3 overflow-hidden">
              <div className="author-avatar rounded-xl overflow-hidden">
                {row.type === "image" && (
                  <span
                    className="author-avatar-inner rounded-xl"
                    style={{
                      background: `url(https://nftstorage.link/ipfs/${row?.img})`,
                    }}
                  ></span>
                )}
                {row.type === "audio" && (
                  <>
                    <span
                      className="author-avatar-inner rounded-xl"
                      style={{
                        background: `linear-gradient(45deg, #4ca1af, #c4e0e5)`,
                      }}
                    ></span>
                    <i className="las la-music text-white position-absolute top-50 start-50 translate-middle z-index-20"></i>
                  </>
                )}
                {row.type === "video" && (
                  <div className="player-wrapper z-index-20">
                    <ReactPlayer
                      url={`https://nftstorage.link/ipfs/${row?.img}`}
                      controls={false}
                      width="140%"
                      height="100%"
                    />
                  </div>
                )}
              </div>
              <div className="ms-3">
                <p className="fw-bold text-base mb-0">
                  {truncateStart(row.title, 15)}
                </p>
              </div>
            </div>
          </Link>
        </div>
      ),
    },
    {
      name: "Price",
      selector: (row) => row.price,
      cell: (row) => (
        <p className="fw-bold text-base mb-0">
          {row.price > 0 ? (
            <span>
              {(row.price / 10 ** 18).toFixed(2)} {settings.currency}
            </span>
          ) : (
            "Not Set"
          )}
        </p>
      ),
    },
    {
      name: "Date Created",
      selector: (row) => row.dateCreated,
      cell: (row) => (
        <div row={row}>
          <div className="d-flex align-items-center">
            <p className="mb-0 fw-bold">{formteFullDate(row.dateCreated)}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Category",
      selector: (row) => row.category,
      cell: (row) => (
        <div row={row}>
          <div className="d-flex align-items-center">
            <p className="mb-0 fw-bold">{row.category}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Royalties",
      selector: (row) => row.royalties,
      cell: (row) => <p className="fw-bold text-base mb-0">{row.royalties}%</p>,
    },
    {
      name: "Owner",
      selector: (row) => row.owner,
      cell: (row) => (
        <Link className="text-reset" to={`/users/${row.owner}`}>
          <div className="d-flex align-items-center py-3 px-3">
            <div className="author-avatar">
              <span
                className="author-avatar-inner"
                style={{ background: `url(${row.ownerAvatar})` }}
              ></span>
            </div>
          </div>
        </Link>
      ),
    },
    {
      name: "Select",
      selector: (row) => row.owner,
      cell: (row) => (
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input cursor-pointer"
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedNfts([...selectedNfts, row.id]);
              } else {
                setSelectedNfts(selectedNfts.filter((nft) => nft !== row.id));
              }
            }}
          />
          <div className="form-check-label"></div>
        </div>
      ),
    },
  ];

  return (
    <>
      {collection && (
        <>
          <DataTable
            columns={columns}
            data={collection}
            pagination={collection.length >= 10 && true}
            responsive
            theme="solarized"
          />
          {selectedNfts.length > 0 && (
            <button
              className="btn btn-primary w-100 py-2 mt-3"
              onClick={() => {
                blockNfts(selectedNfts);
                setSelectedNfts([]);
              }}
            >
              Block Selected
            </button>
          )}
        </>
      )}
    </>
  );
}

export default NftTable;
