import React, { useState, useContext, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Select from "react-dropdown-select";
import Web3 from "web3";
import Web3Context from "../../providers/web3-context";
import CollectionContext from "../../providers/collection-context";
import UserContext from "../../providers/user-context";
import { categoryOptions } from "../../helpers/constants";
import { categoryIcons } from "../../helpers/constants";
import { settings } from "../../helpers/settings";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import AOS from "aos";
import ArtWorkAuthor from "../../icons/ArtWorkAuthor.svg";
// COMPONENTS
import PageBanner from "../general/PageBanner";
import ItemPreview from "../create/ItemPreview";
import SuccessMessage from "../general/SuccessMessage";
import MetaMaskLoader from "../general/MetaMaskLoader";

//ICONS
// import la_image_golden from "../../../assets/icons/la_image_golden.png";
import la_image_golden from "./../../icons/la-image-golden.svg";
import ionic_md_eye from "./../../icons/ionic-md-eye.svg";
import { addData } from "../../config/firebase";

// IPFS CREATE HOST
// const ipfsClient = require("ipfs-http-client");
// const ipfs = ipfsClient.create({
//   host: "ipfs.infura.io",
//   port: 5001,
//   protocol: "https",
// });

function CreateItem() {
  const history = useHistory();
  const web3Ctx = useContext(Web3Context);
  const userCtx = useContext(UserContext);
  // const collectionCtx = useContext(CollectionContext);
  const [mintSuccess, setMintSuccess] = useState(false);
  const [enteredName, setEnteredName] = useState("");
  const [enteredArtAuthor, setEnteredArtAuthor] = useState("");
  const [enteredArtDescription, setEnteredArtDescription] = useState("");
  const [enteredYearOfCreation, setEnteredYearOfCreation] = useState("");
  const [descriptionIsValid, setDescriptionIsValid] = useState(true);
  const [enteredDescription, setEnteredDescription] = useState("");
  const [enteredArtBackgroundStory, setEnteredArtBackgroundStory] =
    useState("");
  const [nameIsValid, setNameIsValid] = useState(true);
  const [priceIsValid, setPriceIsValid] = useState(true);
  const [selectedFile, setSelectedFile] = useState();
  const [withUnlockable, setWithUnlockable] = useState(false);
  const [unlockable, setUnlockable] = useState("");
  const [royalties, setRoyalties] = useState();
  const [price, setPrice] = useState();
  const [isMetaMaskOpened, setIsMetaMaskOpened] = useState(false);
  const [fileIsValid, setFileIsValid] = useState(true);
  const [fileValidationMsg, setFileValidationMsg] = useState("");
  const [preview, setPreview] = useState();
  const [nftType, setNftType] = useState("image");
  const [enteredCategory, setEnteredCategory] = useState("General");
  const [formate, setFormate] = useState("");
  const { addToast } = useToasts();
  const today = new Date();
  const [networkId, setNetworkId] = useState(0);
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  // myHeaders.append("x-agent-id", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDhGRWFBYWMzYjZDODAxOTk5NTZEZTdBNzQ1OWI3NTYyZjY5ODhFMjciLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY1NjIzNTQxOTkyMSwibmFtZSI6IkNsdWI0NCJ9.A-JOR2myP8euZSdRIWtXLRsx-3J1GLCZ3lmi6BULpVU");
  myHeaders.append("Content-Type", "*/*");
  myHeaders.append(
    "Authorization",
    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDhGRWFBYWMzYjZDODAxOTk5NTZEZTdBNzQ1OWI3NTYyZjY5ODhFMjciLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY1NjIzNTQxOTkyMSwibmFtZSI6IkNsdWI0NCJ9.A-JOR2myP8euZSdRIWtXLRsx-3J1GLCZ3lmi6BULpVU"
  );

  /*** =============================================== */
  //      GET ACTIVE NETWORK ID
  /*** =============================================== */
  useEffect(() => {
    async function getNetworkId() {
      if (window.ethereum) {
        const networkId = await web3Ctx.loadNetworkId(
          new Web3(window.ethereum)
        );
        setNetworkId(networkId);
      }
    }
    getNetworkId();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*** =============================================== */
  //      CHANGE PAGE TITLE
  /*** =============================================== */
  useEffect(() => {
    document.title = `Mint an NFT | ${settings.UISettings.marketplaceBrandName}`;
  }, []);

  /*** =============================================== */
  //      INITIATE AOS ANIMATION
  /*** =============================================== */
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  /*** =============================================== */
  //      CATCH NFT IMAGE
  /*** =============================================== */
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    console.log(formate);
    return () => URL.revokeObjectURL(objectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  /*** =============================================== */
  //      CATCH NFT NAME
  /*** =============================================== */
  const enteredNameHandler = (event) => {
    setEnteredName(event.target.value);
  };

  /*** =============================================== */
  //      CATCH NFT DESCRIPTION
  /*** =============================================== */
  const enteredDescriptionHandler = (event) => {
    setEnteredDescription(event.target.value);
  };

  /*** =============================================== */
  //      CATCH NFT UNCLOCKABLE CONTENT
  /*** =============================================== */
  const enteredUnlockableHandler = (event) => {
    setUnlockable(event.target.value);
  };

  /*** =============================================== */
  //      CATCH NFT Royalty
  /*** =============================================== */

  const enteredRoyaltyHandler = (event) => {
    // const value = event.target.value.replace(/\D/g, "");
    const value = event.target.value;
    if (value > 100 || value < 0) {
      return;
    }
    return setRoyalties(Math.floor(value * 100) / 100);
  };

  const enteredPriceHandler = (event) => {
    const value = event.target.value;
    if (value > 1000000) {
      return;
    }
    return setPrice(Math.floor(value * 100) / 100);
  };

  /*** =============================================== */
  //      CATCH NFT Art Author
  /*** =============================================== */

  const enteredAuthorHandler = (event) => {
    setEnteredArtAuthor(event.target.value);
  };

  /*** =============================================== */
  //      CATCH NFT Art Description
  /*** =============================================== */

  const enteredArtDescriptionHandler = (event) => {
    setEnteredArtDescription(event.target.value);
  };

  /*** =============================================== */
  //      CATCH NFT Art Background Story
  /*** =============================================== */

  const enteredArtBackgroundStoryHandler = (event) => {
    setEnteredArtBackgroundStory(event.target.value);
  };

  /*** =============================================== */
  //      CATCH NFT Art Year of Creation
  /*** =============================================== */

  const enteredYearOfCreationHandler = (event) => {
    // const re = /^[0-9\b]+$/;
    // if (
    //   !(event.target.value <= 9999 && event.target.value >= 0) ||
    //   event.target.value.length > 4
    // )
    //   return false;
    const value = event.target.value;
    if (RegExp(/^[0-9\b]+$/).test(value) === false) {
      return;
    }
    setEnteredYearOfCreation(event.target.value);
  };
  /*** =============================================== */
  //      VALIDATE UPLOADED IMAGE SIZE
  /*** =============================================== */
  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    if (e.target.files.length > 0) {
      for (let i = 0; i <= e.target.files.length - 1; i++) {
        const fsize = e.target.files.item(i).size;
        const file = Math.round(fsize / 1024);
        // The size of the file.
        if (file >= settings.NFTmaxSize) {
          // setFileValidationMsg(
          //   "File too Big, please select a file less than 1mb"
          // );
          // setFileIsValid(false);
          // setSelectedFile(undefined);
          // return;
        } else {
          setFileIsValid(true);
          setFormate(e.target.files.item(i).type);
        }
      }
    }
    setSelectedFile(e.target.files[0]);
  };

  /*** =============================================== */
  //      SUBMIT MINTING FORM
  /*** =============================================== */
  const submissionHandler = (event) => {
    event.preventDefault();

    // Validate form fields
    enteredName ? setNameIsValid(true) : setNameIsValid(false);
    price > 0 ? setPriceIsValid(true) : setPriceIsValid(false);
    enteredDescription
      ? setDescriptionIsValid(true)
      : setDescriptionIsValid(false);
    selectedFile ? setFileIsValid(true) : setFileIsValid(false);
    const formIsValid =
      enteredName && enteredDescription && selectedFile && price > 0;

    // Upload file to IPFS and push to the blockchain
    const mintNFT = async () => {
      setIsMetaMaskOpened(true);

      // Add file to the IPFS

      var file = selectedFile;

      let fileAdded = await fetch("https://api.nft.storage/upload", {
        method: "POST",
        headers: myHeaders,
        body: file,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => result.value.cid)
        .catch((error) => console.log("error", error));

      const metadata = {
        title: "Asset Metadata",
        type: "object",
        properties: {
          name: {
            type: "string",
            description: enteredName,
          },
          description: {
            type: "string",
            description: enteredDescription,
          },
          creator: {
            type: "string",
            description: web3Ctx.account,
          },
          image: {
            type: "string",
            description: fileAdded,
          },
          category: {
            type: "string",
            description: enteredCategory,
          },
          royalties: {
            type: "string",
            description: royalties,
          },
          artAuthor: {
            type: "string",
            description: enteredArtAuthor,
          },
          artDescription: {
            type: "string",
            description: enteredArtDescription,
          },
          artBackgroundStory: {
            type: "string",
            description: enteredArtBackgroundStory,
          },
          yearOfCreation: {
            type: "string",
            description: enteredYearOfCreation,
          },
          unlockable: {
            type: "string",
            description: unlockable,
          },
          dateCreated: {
            type: "string",
            description: today,
          },
          type: {
            type: "string",
            description: nftType,
          },
          formate: {
            type: "string",
            description: formate,
          },
        },
      };

      const metadataAdded = await fetch("https://api.nft.storage/upload", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(metadata),
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => result.value.cid)
        .catch((error) => console.log("error", error));
      console.log(metadataAdded);
      if (!metadataAdded) {
        console.error("Something went wrong when uploading the file");
        return;
      }

      /*** =============================================== */
      //      MINTING NFT
      /*** =============================================== */
      // collectionCtx.contract.methods
      //   .MintNFT(metadataAdded, parseInt(royalties))
      //   .send({ from: web3Ctx.account })
      //   .on("transactionHash", (hash) => {
      //     setIsMetaMaskOpened(true);
      //   })
      //   .once("sending", () => {
      //     setIsMetaMaskOpened(true);
      //   })
      //   .on("error", (e) => {
      //     addToast("Something went wrong when pushing to the blockchain", {
      //       appearance: "error",
      //     });
      //     setIsMetaMaskOpened(false);
      //   })
      //   .on("receipt", () => {
      //     addToast("Great! you have succefully minted your NFT", {
      //       appearance: "success",
      //     });
      //     setIsMetaMaskOpened(false);
      //     setMintSuccess(true);
      //     collectionCtx.loadTotalSupply(collectionCtx.contract);
      //     setTimeout(() => {
      //       history.push("/explore");
      //     }, 2500);
      //   });
      setIsMetaMaskOpened(true);

      await addData(
        web3Ctx.account,
        metadataAdded,
        price.toString(),
        parseInt(royalties)
      ).then((res) => {
        console.log(res);
        setIsMetaMaskOpened(false);
        setMintSuccess(true);
        // collectionCtx.loadTotalSupply(collectionCtx.contract);
        setTimeout(() => {
          history.push("/explore");
        }, 2500);
      });

      // console.log(
      //   addData(web3Ctx.account, metadataAdded, price, parseInt(royalties))
      // );
    };

    formIsValid && mintNFT();
  };

  /*** =============================================== */
  //      INJECT VALIDATION CLASSES TO INPUT FIELDS
  /*** =============================================== */
  const nameClass = nameIsValid ? "form-control" : "form-control is-invalid";
  const descriptionClass = descriptionIsValid
    ? "form-control"
    : "form-control is-invalid";
  const priceClass = priceIsValid ? "form-control" : "form-control is-invalid";
  const fileClass = fileIsValid ? "form-control" : "form-control is-invalid";

  /*** =============================================== */
  //      SUCCESS MESSAGE AFTER MINTING
  /*** =============================================== */
  if (mintSuccess)
    return (
      <SuccessMessage
        heading="Great! You've successfully minted your NFT"
        subheading="We're redirecting to homepage"
      />
    );

  return (
    <>
      {isMetaMaskOpened ? <MetaMaskLoader /> : null}
      <PageBanner heading="Mint an NFT" />
      <section className="py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-xl-8" data-aos="fade" data-aos-delay="100">
              <div className=" pb-4">
                <div className="d-flex align-items-center ">
                  {/* <i className="las la-image la-3x text-primary me-2"></i> */}
                  <h2 className="h4 mb-0">Upload file</h2>
                </div>
                {/* <div className="toggle-nav">
                  <button
                    className={`toggle-nav-btn flex-fill ${nftType === "image" ? "active" : null
                      }`}
                    onClick={() => {
                      setNftType("image");
                      nftType !== "image" && setSelectedFile(null);
                    }}
                  >
                    Image
                  </button>
                  <button
                    className={`toggle-nav-btn flex-fill ${nftType === "audio" ? "active" : null
                      }`}
                    onClick={() => {
                      setNftType("audio");
                      nftType !== "audio" && setSelectedFile(null);
                    }}
                  >
                    Audio
                  </button>
                  <button
                    className={`toggle-nav-btn flex-fill ${nftType === "video" ? "active" : null
                      }`}
                    onClick={() => {
                      setNftType("video");
                      nftType !== "video" && setSelectedFile(null);
                    }}
                  >
                    Video
                  </button>
                </div> */}
              </div>

              <form className="" onSubmit={submissionHandler}>
                {/* {nftType === "image" ? (
                  <div className="d-flex align-items-center justify-content-center mb-4">
                    <i className="las la-image la-3x text-primary me-2"></i>
                    <h2 className="h4 mb-0">Upload Image</h2>
                  </div>
                ) : nftType === "audio" ? (
                  <div className="d-flex align-items-center justify-content-center mb-4">
                    <i className="las la-record-vinyl la-3x text-primary me-2"></i>
                    <h2 className="h4 mb-0">Upload Audio</h2>
                  </div>
                ) : (
                  nftType === "video" && (
                    <div className="d-flex align-items-center justify-content-center mb-4">
                      <i className="las la-video la-3x text-primary me-2"></i>
                      <h2 className="h4 mb-0">Upload Video</h2>
                    </div>
                  )
                )} */}

                <div className="row mb-5">
                  <div className="col-lg-12  ">
                    <input
                      className={`form-control shadow-0 bg-none custom-file-upload ${fileClass}`}
                      type="file"
                      autoComplete="off"
                      name="nft_image"
                      id="nft_image"
                      accept={`${
                        nftType === "image"
                          ? ".jpg, .png, .gif"
                          : nftType === "audio"
                          ? ".mp3"
                          : ".mp4, .webm, .gov"
                      }`}
                      placeholder="e.g. Crypto Funk"
                      onChange={onSelectFile}
                    />
                    <label
                      className={`form-label text-gray-500 text-center border-gray-400 ${
                        selectedFile ? "p-3" : "p-5"
                      }`}
                      htmlFor="nft_image"
                    >
                      {!selectedFile && (
                        <div className="my-5 pt-5 pb-5">
                          {/* <i
                            className={`las text-muted mt-4 ${nftType === "image"
                              ? "la-image"
                              : nftType === "audio"
                                ? "la-record-vinyl"
                                : "la-video"
                              }`}
                            style={{ fontSize: "5rem", color: "#cea14f",  }}
                          ></i> */}
                          <img
                            src={la_image_golden}
                            alt="upload"
                            className="img-fluid  mb-2"
                            style={{
                              width: "55px",
                              height: "55px",
                              marginTop: "35px",
                            }}
                          />
                          <h6
                            className="mb-0 fw-normal text-gray-500 mb-2"
                            style={{ fontSize: "1rem", color: "#cea14f" }}
                          >
                            Click here to upload your {nftType}
                          </h6>
                          <p
                            className="text-muted mb-4"
                            style={{ fontSize: "1rem", color: "#8c989b" }}
                          >
                            Waiting to catch your file
                          </p>
                          <p className="text-danger">{fileValidationMsg}</p>
                        </div>
                      )}

                      {selectedFile &&
                        (nftType === "image" ? (
                          <img
                            src={preview}
                            className="img-fluid"
                            alt={enteredName}
                          />
                        ) : nftType === "audio" ? (
                          <AudioPlayer
                            src={preview}
                            autoPlayAfterSrcChange={false}
                            showJumpControls={false}
                          />
                        ) : (
                          <ReactPlayer
                            url={preview}
                            controls={true}
                            width="100%"
                          />
                        ))}
                    </label>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-4">
                  {/* <i className="las la-icons la-3x me-2 text-primary"></i> */}
                  <h2 className="h4 mb-0">Add Info</h2>
                </div>

                <div>
                  <div className="row gy-3 has-field-icons">
                    <div className="col-lg-12">
                      <label
                        className="form-label text-dark lead fw-bold"
                        htmlFor="nft_title"
                        style={{ color: "ffffff" }}
                      >
                        Title
                      </label>
                      <div className="input-icon">
                        <div className="input-icon-text bg-none">
                          <i className="text-primary las la-user-edit"></i>
                        </div>
                        <input
                          maxLength={60}
                          className={`form-control bg-white shadow-0 ${nameClass}`}
                          type="text"
                          autoComplete="off"
                          name="nft_title"
                          id="nft_title"
                          placeholder="The Starry Night"
                          value={enteredName}
                          onChange={enteredNameHandler}
                          style={{ color: "ffffff" }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label
                        className="form-label text-dark lead fw-bold"
                        htmlFor="nft_category"
                        style={{ color: "ffffff" }}
                      >
                        Category
                      </label>
                      <div className="input-icon flex category-select">
                        <div className="input-icon-text bg-none">
                          {enteredCategory === "General" ? (
                            <i className="las la-icons text-primary z-index-20"></i>
                          ) : (
                            <img
                              src={categoryIcons[enteredCategory]}
                              alt=""
                              width="15"
                              className="z-index-20"
                            />
                          )}
                        </div>
                        <Select
                          searchable={false}
                          options={categoryOptions}
                          className="form-select border-gray-300 shadow-0 bg-white"
                          value={enteredCategory}
                          onChange={(value) =>
                            setEnteredCategory(
                              value.map((el) => el.value).toString()
                            )
                          }
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label
                        className="form-label text-dark lead fw-bold"
                        htmlFor="nft_royalties"
                        style={{ color: "ffffff" }}
                      >
                        Royalties
                      </label>
                      <div className="input-icon flex-nowrap category-select">
                        <div className="input-icon-text bg-none">
                          <i className="las la-percentage text-primary z-index-20"></i>
                        </div>

                        <input
                          className={`form-control bg-white shadow-0 ${nameClass}`}
                          type="number"
                          min="0"
                          placeholder="0"
                          max={100}
                          maxLength={4}
                          pattern="\d+"
                          value={royalties}
                          onChange={enteredRoyaltyHandler}
                          style={{ color: "ffffff" }}
                          step="0.01"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label
                        className="form-label text-dark lead fw-bold"
                        htmlFor="nft_title"
                        style={{ color: "ffffff" }}
                      >
                        Art Work Author
                      </label>
                      <div className="input-icon flex-nowrap category-select">
                        <div className="input-icon-text bg-none">
                          <i className="text-primary las la-user text-primary z-index-20">
                            {/* <img src={ArtWorkAuthor} alt="" /> */}
                          </i>
                        </div>
                        <input
                          maxLength={140}
                          className={`form-control bg-white shadow-0 ${nameClass}`}
                          type="text"
                          autoComplete="off"
                          name="art_author"
                          id="art_author"
                          placeholder="Vincent van Gogh"
                          value={enteredArtAuthor}
                          onChange={enteredAuthorHandler}
                          style={{ color: "ffffff" }}
                        />
                      </div>
                    </div>

                    {/* <div className="col-lg-6">
                      <label
                        className="form-label text-dark lead fw-bold"
                        htmlFor="nft_title"
                        style={{ color: "ffffff" }}
                      >
                        Art Work Description
                      </label>
                      <div className="input-icon flex-nowrap category-select">
                        <div className="input-icon-text bg-none">
                          <i className="las la-pen-nib text-primary z-index-20"></i>
                        </div>
                        <input
                          maxLength={500}
                          className={`form-control bg-white shadow-0 ${nameClass}`}
                          type="text"
                          autoComplete="off"
                          name="art_description"
                          id="art_description"
                          placeholder="The comparatively abstract painting "
                          value={enteredArtDescription}
                          onChange={enteredArtDescriptionHandler}
                          style={{ color: "ffffff" }}
                        />
                      </div>
                    </div> */}

                    <div className="col-lg-6">
                      <label
                        className="form-label text-dark lead fw-bold"
                        htmlFor="nft_title"
                        style={{ color: "ffffff" }}
                      >
                        Year of Creation
                      </label>
                      <div className="input-icon flex-nowrap category-select">
                        <div className="input-icon-text bg-none">
                          <i className="las la-calendar-minus text-primary z-index-20"></i>
                        </div>
                        <input
                          maxLength={4}
                          minLength={4}
                          className={`form-control bg-white shadow-0 ${nameClass}`}
                          type="text"
                          autoComplete="off"
                          name="art_backgroundStory"
                          id="art_backgroundStory"
                          // defaultValue={new Date().toISOString().slice(0, 10)}
                          placeholder="Year"
                          // pattern="/^[0-9\b]+$/"
                          value={enteredYearOfCreation}
                          onChange={enteredYearOfCreationHandler}
                          style={{ color: "ffffff" }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <label
                        className="form-label text-dark lead fw-bold"
                        htmlFor="nft_title"
                        style={{ color: "ffffff" }}
                      >
                        Art Work Background Story
                      </label>
                      <div className="input-icon flex-nowrap category-select">
                        <div className="input-icon-text bg-none ">
                          <i className="las la-palette text-primary z-index-20 "></i>
                        </div>
                        <textarea
                          maxLength={500}
                          rows="4"
                          className={`form-control bg-white shadow-0 ${nameClass}`}
                          type="text"
                          autoComplete="off"
                          name="art_backgroundStory"
                          id="art_backgroundStory"
                          placeholder="Van Gogh was living in an asylum…"
                          value={enteredArtBackgroundStory}
                          onChange={enteredArtBackgroundStoryHandler}
                          style={{ color: "ffffff" }}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <label
                        className="form-label text-dark lead fw-bold"
                        htmlFor="nft_description"
                        style={{ color: "ffffff" }}
                      >
                        Description
                      </label>
                      <div className="input-icon">
                        <div className="input-icon-text bg-none">
                          <i
                            className="las la-align-left text-primary"
                            style={{ color: "cea14f" }}
                          ></i>
                        </div>
                        <textarea
                          maxLength={500}
                          rows="4"
                          className={`form-control shadow-0 bg-white pt-3 ${descriptionClass}`}
                          name="nft_description"
                          id="nft_description"
                          placeholder="Provide some good description about your asset"
                          value={enteredDescription}
                          onChange={enteredDescriptionHandler}
                          style={{ backgroundColor: "#fff", color: "ffffff" }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <label
                        className="form-label text-dark lead fw-bold"
                        htmlFor="nft_price"
                        style={{ color: "ffffff" }}
                      >
                        Price
                      </label>
                      <div className="input-icon flex-nowrap category-select">
                        <div className="input-icon-text bg-none">
                          <i className="las la-percentage text-primary z-index-20"></i>
                        </div>

                        <input
                          className={`form-control bg-white shadow-0 ${priceClass}`}
                          type="number"
                          min="0"
                          placeholder="0"
                          max={1000000}
                          pattern="\d+"
                          value={price}
                          onChange={enteredPriceHandler}
                          style={{ color: "ffffff" }}
                          step="0.01"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-check form-switch d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="unlockable"
                          onChange={() => {
                            setWithUnlockable(!withUnlockable);
                            setUnlockable("");
                          }}
                          style={{ color: "ffffff" }}
                        />
                        <label
                          className="fw-bold h6 ms-3 pt-1 mb-0"
                          htmlFor="unlockable"
                          style={{ color: "ffffff" }}
                        >
                          Unlock once purchased
                        </label>
                      </div>
                    </div>

                    {withUnlockable && (
                      <div className="col-lg-12">
                        <label
                          className="form-label text-dark lead fw-bold"
                          htmlFor="unlockableContent"
                          style={{ color: "ffffff" }}
                        >
                          Unlockable Content
                        </label>
                        <div className="input-icon">
                          <div className="input-icon-text bg-none">
                            <i className="text-primary las la-cloud"></i>
                          </div>
                          <input
                            className={`form-control bg-white shadow-0 ${unlockable}`}
                            type="text"
                            autoComplete="off"
                            name="unlockable_content"
                            id="unlockableContent"
                            placeholder="Add a download url for your content..."
                            value={withUnlockable ? unlockable : ""}
                            onChange={enteredUnlockableHandler}
                            style={{ color: "ffffff" }}
                          />
                        </div>
                      </div>
                    )}

                    {/* SUBMIT */}
                    <div className="col-12">
                      {userCtx.userIsRegistered ? (
                        <>
                          {userCtx.appOwner === web3Ctx.account ||
                          /* {web3Ctx.account === web3Ctx.account ? ( */
                          (userCtx.whiteList &&
                            userCtx.whiteList
                              .map((user) => user.address)
                              .includes(web3Ctx.account)) ? (
                            <button
                              className="btn btn-primary mb-3"
                              type="submit"
                            >
                              <i className="lab la-ethereum me-2"></i>Mint NFT
                            </button>
                          ) : (
                            <>
                              <button
                                className="btn btn-primary mb-3"
                                type="button"
                                disabled={true}
                              >
                                <i className="lab la-ethereum me-2"></i>Mint NFT
                              </button>
                              <p className="text0sm text-muted">
                                Only WhiteListed Addresses can mint.
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {window.ethereum &&
                          networkId === settings.networkId ? (
                            <Link className="btn btn-primary" to="/register">
                              <i className="las la-user me-2"></i>Register to
                              Mint
                            </Link>
                          ) : (
                            <div className="py-3 px-4 d-inline-block lh-reset bg-light rounded">
                              <p className="fw-bold mb-2">
                                Visitors cannot perform this action
                              </p>
                              <span className="text-muted">
                                Connect a wallet and try again
                              </span>
                            </div>
                          )}
                        </>
                      )}
                      <p className="mt-2">
                        It may take time before NFT appear after mint, depends
                        on network load.
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            {/* PREVIEW ITEM */}
            <div className="col-xl-4" data-aos="fade" data-aos-delay="200">
              <ItemPreview
                heading="Preview item"
                type={nftType}
                preview={preview}
                title={enteredName}
                category={enteredCategory}
                author={
                  userCtx.userInformation
                    ? userCtx.userInformation.fullName
                    : "Vincent Van Gogh"
                }
                royalties={royalties}
                price={price}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CreateItem;
