import React, { useEffect, useState } from "react";
import { settings } from "../../helpers/settings";

// COMPONENTS
import PgaeBanner from "../general/PageBanner";

function PrivacyPolicy() {
  /*** =============================================== */
  //      CHANGE PAGE TITLE
  /*** =============================================== */
  useEffect(() => {
    document.title = `FCA PRIVACY POLICY | ${settings.UISettings.marketplaceBrandName}`;
  }, []);

  const [tab, setTab] = useState("tech-questions");

  return (
    <>
      <PgaeBanner heading="FCA PRIVACY POLICY" />

      <section className="py-5">
        <div className="container py-5">
          <div className="row g-5">
            This Privacy Policy ("Policy") describes how Forever Collection Art
            ("FCA," "we," "us," or "our") collects, uses, and shares personal
            information of users of our website, mobile application, and other
            online products and services (collectively, the "Services"). We are
            committed to protecting your privacy and for all inquiries related
            to privacy please email: legal@forevercollection.art The legal basis
            for the data processing described in this Policy is your consent,
            and also the fulfilment of our obligations and provision of
            Services.
          </div>
          <br />
          <br />
          <h5>COLLECTION OF INFORMATION</h5>
          We may collect personal information, such as your name, email address,
          and other contact information that allows someone to identify you
          individually (“Personal Data”), when you use the Services. Personal
          data does not include anonymous or de-identified aggregated
          information that does not identify you as an individual or that is not
          associated with or linked to your Personal Data (“Anonymous Data”) We
          may collect Personal Data about your location, device, and usage of
          the Services. In addition, we may collect Personal Data you provide to
          us through customer support or other interactions with us. We may
          receive Personal and/or Anonymous Data about you from companies that
          offer their products and/or services for use in conjunction with our
          Service or whose products and/or services may be linked from our
          Services such as third party blockchain wallet or any other third
          party provider. We may collect data that is visible and accessible
          such as information regarding purchases/sales/transfer of NFTs which
          may be associated with other data you have provided to us.
          <br />
          <br />
          <h5>USE OF INFORMATION</h5>
          FCA processes Personal Data for special purposes, each of them being
          duly legitimated by a valid legal basis. We may use the Personal Data
          we collect to provide and improve the Services, to conduct research.
          We may also use Personal Data for creating personalized experiences
          and improving the platform. We may also use the Personal Data for
          development of new products, services, features, functionality. In
          addition, we may use the Personal Data for communication with you
          (directly or through third party) including for customer service, to
          provide you with updated and other information related to the website,
          provide with marketing information and your use of the Services. FCA
          uses the Personal Data we collect from or about You to provide a
          smooth and secure access to our website, services, and offerings, to
          ensure a great user experience, to evaluate system security and
          stability, and for further administrative purposes.
          <br />
          <br />
          <h5>SHARING OF INFORMATION</h5>
          We may share your information with third parties as described in this
          Policy or disclosed to you at the time of collection. We may also
          share your Personal Data with our partners to provide you with the
          best services. Your Personal Data may also be shared with blockchain
          nodes to facilitate transactions on the platform. However, we will not
          share your information with any third parties for marketing purposes
          without your explicit consent and we do not guarantee that other
          platforms will follow best privacy practices. We are not responsible
          for the privacy practices or content of third-party websites. We
          encourage you to review the privacy policies of those third-party
          sites to learn about their practices or what rights you may have.
          <br />
          <br />
          <h5>COOKIES</h5>
          What cookies do we use? We use cookies to help us remember
          non-personal data such as your user preferences to optimize your user
          experience on our Platform or to recognize your device the next time
          you visit our Platform. Certain cookies contain personal data for
          example, if you click on ‘remember me’ when logging in, a cookie will
          store your username. Cookies can be categorised by the role they
          fulfil on our Platform:
          <ul>
            <li>
              Functional cookies are essential in order to enable you to use and
              move around our Platform and to ensure that our Platform functions
              properly.
            </li>
            <li>
              Analytical cookies are used to collect information on visitor
              behaviour (e.g. the pages you view most often) to optimise the
              usability of the Platform.
            </li>
            <li>
              Advertising cookies are used to follow browsing behaviour over a
              longer period of time across various websites. These cookies build
              individual user profiles according to their interests. Based on
              these interests, the content of the advertisements on our website
              will be adapted for different visitor groups.
            </li>
            <li>
              Social cookies are used to enable logged on users of selected
              social media sites to directly share content from the Platform.
            </li>
          </ul>
          <br />
          <h6>Unforeseen cookies</h6>
          Because of how the internet and how websites work, it’s possible that
          we may have missed a cookie that is placed by a third party on our
          Platform. If you happen to find one that isn’t listed above, then
          please let us know. <br />
          <br />
          <h6>Consent</h6>
          You give your consent for cookies to be placed and read out on our
          Platform by clicking “agree” on the cookie notice or by continuing to
          use the Platform. For more information about the use of the
          information collected through cookies see our Privacy Statement.
          <br />
          <br />
          <h6>Refusing cookies</h6>
          You can use the settings within your browser to control the cookies
          that are set on your computer or mobile device. To learn more, please
          look at the cookie settings available in your specific web browser(s).
          The links below will take you directly to your browser’s manual.
          <br />
          <ul>
            <li>- Google Chrome</li>
            <li>– Firefox</li>
            <li>– Internet Explorer</li>
            <li>– Safari</li>
          </ul>
          You may also visit www.youronlinechoices.eu for details on how to
          remove cookies of specific parties and for further information on
          cookies generally.
          <br />
          <br /> However, please be aware that cookies are important to many
          aspects of the Platform. If you set your browser to reject cookies,
          you may not be able to enjoy all of the features of the Platform.
          <br />
          <br />
          <br /> <h6>Google Analytics</h6>
          Google Analytics and Google Remarketing Analytics is a service of
          Google Inc., 1600 Amphitheater Parkway, Mountain View, CA 94043, USA
          (“Google”). Google Analytics uses cookies, which allows an analysis of
          the use of our Website by Google. The information collected
          anonymously about the use of our Website (including your IP address)
          collected by the cookie is generally transferred to a server of Google
          in the USA and stored there. For more information please read the
          privacy policy of Google:
          https://support.google.com/analytics/answer/6004245?hl=en
          <br />
          <br />
          You can prevent Google’s processing of the data (including your IP
          address) generated by the cookie and your use of our Website
          (including your IP address) as well as the processing of this data by
          Google by downloading the browser plug-in available under the
          following link And install. For more information about Google
          Analytics and privacy, please visit
          http://tools.google.com/dlpage/gaoptout?hl=en.
          <br />
          <br />
          Social plugins Our Platform uses social plugins (“plugins”) from
          different social networks, including Facebook, Twitter, Google+,
          LinkedIn, Instagram and Pinterest. With the help of these plugins, you
          can, for example, share content or recommend products. The plugins are
          deactivated by default and therefore do not send any data.
          <br />
          br By clicking on the “Enable Social Media” button you can activate
          the plugins. The plugins can of course be deactivated with one click
          again. When these plugins are enabled, your browser connects directly
          to the servers of the respective social network as soon as they access
          any of our Platforms. The content of the plugin is transmitted
          directly from the social network to your browser and integrated into
          it by the Platform. If you do not want social networks to collect data
          about you through active plugins, you can either disable the social
          plugins s or choose the option “Block third-party cookies” in your
          browser settings. Changes to this Cookie Statement This statement may
          be changed from time to time. The new statement will be effective
          immediately upon posting. Please check our statement frequently to
          stay informed about how we use cookies. This statement was last
          updated on January 11th, 2023. This statement is exclusively governed
          by the laws of the Netherlands. Any and all disputes that may arise
          with respect to this statement will be referred exclusively to the
          competent court in the Netherlands.
          <br />
          <br />
          <h5>SECURITY</h5>
          <br />
          We take reasonable measures to protect the information we collect from
          loss, misuse, and unauthorized access, disclosure, alteration, and
          destruction. However, no Internet or email transmission is ever fully
          secure or error free.
          <br />
          We implement appropriate technical and organizational measures to
          ensure the security and confidentiality of personal data. This
          includes measures such as encryption, firewalls, and regular security
          audits. Our employees and contractors are trained on data protection
          policies and practices, and are contractually obligated to maintain
          the confidentiality of personal data.
          <br />
          In the event of a data breach, we will promptly notify you of the
          incident in accordance with the requirements for data breach
          notification. We will also cooperate with any investigation related to
          the data breach, and will take appropriate measures to address and
          prevent future breaches.
          <br /> <br /> <h5>PRIVACY OF CHILDREN</h5>
          <br /> Our Services are not directed to children under 13, and we do
          not knowingly collect personal information from children under 13. If
          we learn that we have collected the personal information of a child
          under 13, we will take steps to delete the information as soon as
          possible.
          <br />
          <br />
          <h5>YOUR RIGHTS</h5> <br />
          You have the right to access, rectify, delete, object to the
          processing and request portability of the Personal Data. You also have
          the right to object to the processing of Personal Data for certain
          purposes and the right to complain to a supervisory authority. We will
          assist you in exercising these rights and respond to such requests in
          a timely manner. <br /> Note that the Services employ Smart Contracts
          that collect information that is then stored on a BlockChain that FCA
          does not control. If you use the Services, you are consenting to allow
          your information to be cryptographically transmitted and stored on
          that blockchain, and any deletion or modification of that information
          (to the extent possible on a blockchain) is governed by the terms of
          the relevant Smart Contract. The information stored on the blockchain
          may include purchases, sales, and transfers related to your blockchain
          address and NFTs held at that address.
          <br />
          <br />
          <h5>STORAGE</h5>
          <br />
          FCA stores Personal Data are only as long as necessary to fulfil the
          contractual or legal obligations to which we have collected the data.
          We delete the data immediately unless we need the data until the
          expiration of the statutory limitation period for evidence for civil
          claims or for statutory storage requirements. Even after that, we may
          have to save your data for accounting reasons. We are obliged to do so
          because of legal documentation obligations which may arise from legal
          obligations.
          <br />
          <br />
          <h5>CALIFORNIA CONSUMER PRIVACY</h5>
          <br />
          If you are a California resident, you have certain rights under the
          California Consumer Privacy Act (“CCPA”). These include the right to
          (i) request access to, details regarding, and a copy of the personal
          information we have collected about you and/or shared with third
          parties; (ii) request deletion of the personal information that we
          have collected about you; and (iii) the right to opt-out of sale of
          your personal information. As the terms are defined under the CCPA, we
          do not “sell” your “personal information.”
          <br />
          <br />
          <h5>CHANGES TO THIS POLICY</h5>
          <br />
          We may revise this Policy from time to time. The most current version
          of the Policy will govern our use of your Personal Data and will
          always be at [https://forevercollection.art/privacy-policy]. If we
          make a change to this Policy that, in our sole discretion, is
          material, we will notify you via email to the email address associated
          with your account. By continuing to access or use the Services after
          those changes become effective, you agree to be bound by the revised
          Policy.
          <br />
          <br />
          <h5>CONTACT US</h5>
          <br />
          If you have any questions or concerns or complaints about our Privacy
          Policy or our data collection or processing practices, or if you want
          to report any security violations to us, please contact us via:
          legal@forevercollection.art
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
