import React, { useEffect } from "react";
import { settings } from "../../helpers/settings";

// COMPONENTS
import PageBanner from "../general/PageBanner";
import ContactForm from "../contact/ContactForm";
import ContactInfo from "../contact/ContactInfo";

function Contact() {
  /*** =============================================== */
  //      CHANGE PAGE TITLE
  /*** =============================================== */
  useEffect(() => {
    document.title = `Contact Us | ${settings.UISettings.marketplaceBrandName}`;
  });

  return (
    <>
      <PageBanner heading={"Contact Us"} />
      <section className="py-5">
        <div className="container py-5">
          <div className="row g-5">
            <ContactInfo gridWidth="col-lg-8 mx-auto" />
            <ContactForm gridWidth="col-lg-8 mx-auto" />
            <div className="col-12 text-center">
              <h2 className="h3 mb-1">Get Connected!</h2>
              <p className="small text-muted mb-3">
                Follow us on social media and join us in our Community
                discussions on Telegram.
              </p>
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a
                    className="social-link bg-hover-primary"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/forevercollectionart/"
                    target="_blank"
                  >
                    <i className="lab la-linkedin"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="social-link bg-hover-primary"
                    rel="noreferrer"
                    href="https://www.facebook.com/Forevercollectionart/"
                    target="_blank"
                  >
                    <i className="lab la-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="social-link bg-hover-primary"
                    rel="noreferrer"
                    href="https://www.instagram.com/forevercollectionart/"
                    target="_blank"
                  >
                    <i className="lab la-instagram"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="social-link bg-hover-primary"
                    rel="noreferrer"
                    href="https://twitter.com/forevercollart"
                    target="_blank"
                  >
                    <i className="lab la-twitter"></i>
                  </a>
                </li>
                {/* <li className='list-inline-item'>
                                    <a className='social-link bg-hover-primary' rel='noreferrer' href='/' target="_blank">
                                        <i className='lab la-discord'></i>
                                    </a>
                                </li> */}
                <li className="list-inline-item">
                  <a
                    className="social-link bg-hover-primary"
                    rel="noreferrer"
                    href="https://t.me/forevercollectionart"
                    target="_blank"
                  >
                    <i className="lab la-telegram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
