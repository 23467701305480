import React, { useEffect, useContext, useState } from 'react';
import AOS from 'aos';
import MarketplaceContext from '../../providers/marketplace-context';
import AuctionContext from '../../providers/auction-context';
import { settings } from '../../helpers/settings';

// COMPONENTS
import PageBanner from '../general/PageBanner';
import FullScreenLoader from '../general/FullScreenLoader';
import UserInfo from '../user/UserInfo';
import InfoForm from '../user/InfoForm';
import UserBidsTable from '../tables/UserBidsTable';
import MetaMaskLoader from '../general/MetaMaskLoader';

function MyInfo() {
    const marketplaceCtx = useContext(MarketplaceContext);
    const auctionCtx = useContext(AuctionContext);
    const [requestEdit, setRequestEdit] = useState(false);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `My Information | ${settings.UISettings.marketplaceBrandName}`;
    }, []);

    /*** =============================================== */
    //      INITIATE AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 1000, once: true });
    }, []);

    /*** =============================================== */
    //      OPEN EDIT INFO FORM
    /*** =============================================== */
    function requestEditHandler() {
        setRequestEdit(true);
        window.scrollTo(0, 0);
    }

    /*** =============================================== */
    //      CLOSE EDIT INFO FORM
    /*** =============================================== */
    function cancelEditHandler() {
        setRequestEdit(false);
        window.scrollTo(0, 0);
    }

    return (
        <>
            {auctionCtx.fetchingLoading ? <FullScreenLoader heading='Updating your Bids' /> : null}
            {/* {marketplaceCtx.mktIsLoading ? <FullScreenLoader heading='loading' /> : null} */}
            {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}
            <PageBanner heading='My Account' />
            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-8 mx-auto'>
                            {requestEdit ? (
                                <InfoForm editInfo={cancelEditHandler} />
                            ) : (
                                <>
                                    <UserInfo editInfo={requestEditHandler} />

                                    <div className='table-width-sm mt-5' data-aos='fade-up' data-aos-delay='600'>
                                        <UserBidsTable />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MyInfo;
