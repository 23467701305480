import React, {
  useContext,
  useRef,
  createRef,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import web3 from "../../connect-web3/web3";
import Web3 from "web3";
import Web3Context from "../../providers/web3-context";
import CollectionContext from "../../providers/collection-context";
import MarketplaceContext from "../../providers/marketplace-context";
import AuctionContext from "../../providers/auction-context";
import UserContext from "../../providers/user-context";
import { formatDate, truncateStart } from "../../helpers/utils";
import { settings } from "../../helpers/settings";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import AOS from "aos";

// COMPOENTNS
import NftCategory from "./NftCategory";
import Modal from "./Modal";

import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

const melodyStyle = {
  fontSize: "5rem",
  color: "#fff",
  position: "absolute",
  top: "4rem",
  left: "50%",
  transform: "translateX(-50%)",
};

function NftItem({
  img,
  title,
  owner,
  price,
  category,
  dateCreated,
  id,
  index,
  nftKey,
  noAnimation,
  unlockable,
  royalties,
  type,
  lazy,
}) {
  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const userCtx = useContext(UserContext);
  const auctionCtx = useContext(AuctionContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saleType, setSaleType] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [endDate, setEndDate] = useState(new Date().getTime());
  const [ownerName, setOwnerName] = useState("Loading...");
  const [ownerAvatar, setOwnerAvatar] = useState("");
  const [networkId, setNetworkId] = useState(0);
  const { addToast } = useToasts();

  /*** =============================================== */
  //      GET ACTIVE NETWORK ID
  /*** =============================================== */
  useEffect(() => {
    async function getNetworkId() {
      if (window.ethereum) {
        const networkId = await web3Ctx.loadNetworkId(
          new Web3(window.ethereum)
        );
        setNetworkId(networkId);
      }
    }

    getNetworkId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*** =============================================== */
  //      INITIATE AOS ANIAMATION
  /*** =============================================== */
  useEffect(() => {
    AOS.init({ duration: 1000, offset: 10 });
  }, []);

  /*** =============================================== */
  //      GET OWNER NAME & AVATAR
  /*** =============================================== */
  useEffect(() => {
    if (
      userCtx.contract &&
      userCtx.usersList &&
      userCtx.usersList.length > 0 &&
      collectionCtx.collection &&
      collectionCtx.collection.length > 0
    ) {
      if (
        owner === auctionCtx.contract.options.address ||
        owner === marketplaceCtx.contract.options.address
      ) {
        setOwnerAvatar("/images/mkt-avatar.png");
        setOwnerName("Marketplace");
      } else {
        const nftOwnerName = userCtx.usersList.filter(
          (user) => user.account === owner
        )[0].fullName;
        const nftOwnerAvatar = userCtx.usersList.filter(
          (user) => user.account === owner
        )[0].avatar;
        setOwnerAvatar(nftOwnerAvatar);
        setOwnerName(nftOwnerName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx.usersList, userCtx.contract, collectionCtx.collection]);

  /*** =============================================== */
  //      CONNECT WALLET
  /*** =============================================== */
  const connectWalletHandler = async () => {
    try {
      // Request account access
      await window.ethereum.request({ method: "eth_requestAccounts" });
    } catch (error) {
      console.error(error);
    }
    // Load accounts
    web3Ctx.loadAccount(web3);
  };

  /*** =============================================== */
  //      DECLARE PRICE REFERENCE
  /*** =============================================== */
  const priceRefs = useRef([]);
  if (priceRefs.current.length !== collectionCtx.collection.length) {
    priceRefs.current = Array(collectionCtx.collection.length)
      .fill()
      .map((_, i) => priceRefs.current[i] || createRef());
  }

  /*** =============================================== */
  //      CREATE SALE FUNCTION
  /*** =============================================== */
  const makeOfferHandler = (event, id, nftKey) => {
    event.preventDefault();

    const enteredPrice = web3.utils.toWei(
      priceRefs.current[nftKey].current.value,
      "ether"
    );

    collectionCtx.contract.methods
      .approve(marketplaceCtx.contract.options.address, id)
      .send({ from: web3Ctx.account })
      .on("transactionHash", (hash) => {
        setIsModalOpen(false);
      })
      .once("sending", () => {
        collectionCtx.setNftTransactionLoading(true);
        setIsModalOpen(false);
      })
      .once("error", (e) => {
        collectionCtx.setNftTransactionLoading(false);
        setIsModalOpen(false);
      })
      .on("receipt", (receipt) => {
        marketplaceCtx.contract.methods
          .addPrice(id, enteredPrice)
          .send({ from: web3Ctx.account })
          .once("sending", () => {
            collectionCtx.setNftTransactionLoading(true);
          })
          .on("receipt", () => {
            collectionCtx.setNftTransactionLoading(false);
            setIsModalOpen(false);
            collectionCtx.getNftHistory(collectionCtx.contract, id);
            userCtx.loadTransactions(userCtx.contract);
            userCtx.loadActivity(userCtx.contract);
          })
          .on("error", (error) => {
            collectionCtx.setNftTransactionLoading(false);
            setIsModalOpen(false);
            addToast("Oops! an error occured", {
              appearance: "error",
            });
          });
        collectionCtx.setNftTransactionLoading(false);
        setIsModalOpen(false);
        collectionCtx.getNftHistory(collectionCtx.contract, id);
        userCtx.loadTransactions(userCtx.contract);
        userCtx.loadActivity(userCtx.contract);
      });
  };

  /*** =============================================== */
  //      BUY NFT FUNCTION
  /*** =============================================== */
  const buyHandler = (event) => {
    const buyIndex = parseInt(event.target.value);
    marketplaceCtx.contract.methods
      .buyNFT(marketplaceCtx.offers[buyIndex].offerId)
      .send({
        from: web3Ctx.account,
        value: marketplaceCtx.offers[buyIndex].price,
      })
      .once("sending", () => {
        collectionCtx.setNftTransactionLoading(true);
      })
      .on("transactionHash", (hash) => {
        collectionCtx.setNftTransactionLoading(true);
      })
      .on("receipt", () => {
        collectionCtx.setNftTransactionLoading(false);
        userCtx.loadTransactions(userCtx.contract);
        userCtx.loadActivity(userCtx.contract);
      })
      .on("error", (error) => {
        addToast("Oops! an error occured", {
          appearance: "error",
        });
        collectionCtx.setNftTransactionLoading(false);
      });
  };

  /*** =============================================== */
  //      CANCEL SALE FUNCTION
  /*** =============================================== */
  const cancelHandler = (event) => {
    const cancelIndex = parseInt(event.target.value);
    marketplaceCtx.contract.methods
      .cancelSale(marketplaceCtx.offers[cancelIndex].offerId)
      .send({ from: web3Ctx.account })
      .once("sending", () => {
        collectionCtx.setNftTransactionLoading(true);
      })
      .on("transactionHash", (hash) => {
        collectionCtx.setNftTransactionLoading(true);
      })
      .on("receipt", () => {
        collectionCtx.setNftTransactionLoading(false);
        collectionCtx.getNftHistory(
          collectionCtx.contract,
          marketplaceCtx.offers[cancelIndex].offerId
        );
        userCtx.loadTransactions(userCtx.contract);
        userCtx.loadActivity(userCtx.contract);
      })
      .on("error", (error) => {
        collectionCtx.setNftTransactionLoading(false);
        addToast("Oops! an error occured", {
          appearance: "error",
        });
      });
  };

  /*** =============================================== */
  //      MAKE AUCTION FUNCTION
  /*** =============================================== */
  const makeAuctionHandler = (event, endDate, id) => {
    event.preventDefault();

    if (new Date(endDate).getTime() > new Date().getTime()) {
      collectionCtx.contract.methods
        .approve(auctionCtx.contract.options.address, id)
        .send({ from: web3Ctx.account })
        .on("transactionHash", (hash) => {
          setIsModalOpen(false);
        })
        .once("sending", () => {
          collectionCtx.setNftTransactionLoading(true);
          setIsModalOpen(false);
        })
        .once("error", (e) => {
          collectionCtx.setNftTransactionLoading(false);
          setIsModalOpen(false);
        })
        .on("receipt", (receipt) => {
          auctionCtx.contract.methods
            .createAuction(parseInt(id), new Date(endDate).getTime() + 400000)
            .send({ from: web3Ctx.account })
            .once("sending", () => {
              auctionCtx.setAuctionTransactionLoading(true);
            })
            .on("receipt", () => {
              auctionCtx.setAuctionTransactionLoading(false);
              setIsModalOpen(false);
              setSaleType("");
              userCtx.loadTransactions(userCtx.contract);
              userCtx.loadActivity(userCtx.contract);
            })
            .on("error", (error) => {
              auctionCtx.setAuctionTransactionLoading(false);
              setIsModalOpen(false);
              setSaleType("");
              addToast("Oops! an error occured", {
                appearance: "error",
              });
            });
          auctionCtx.setAuctionTransactionLoading(false);
          collectionCtx.setNftTransactionLoading(false);
          setIsModalOpen(false);
          collectionCtx.getNftHistory(collectionCtx.contract, id);
          userCtx.loadTransactions(userCtx.contract);
          userCtx.loadActivity(userCtx.contract);
        });
    } else {
      addToast("End Date Cannot be in the past", {
        appearance: "error",
      });
    }
  };

  /*** =============================================== */
  //      CLOSE MODAL FUNCTION
  /*** =============================================== */
  function closeModalHandler() {
    setIsModalOpen(false);
    setSaleType("");
  }

  return (
    <>
      <div
        className={`card rounded card-hover-image position-relative ${category}`}
        data-aos={`${noAnimation ? "" : "fade-up"}`}
        data-aos-once="true"
        data-aos-delay={(nftKey + 1) * 100}
      >
        <div className="card-body p-3 position-relative">
          <div className="position-relative mb-4 shadow">
            <div
              className={`card-img-holder rounded overflow-hidden ${
                type === "audio" ? "audio" : ""
              }`}
            >
              {type === "image" ? (
                <div
                  className="w-100 h-100 card-img-holder-inner"
                  style={{
                    backgroundImage: `url(https://nftstorage.link/ipfs/${img})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                ></div>
              ) : type === "audio" ? (
                <>
                  <i className="las la-music" style={melodyStyle}></i>
                  <AudioPlayer
                    src={`https://nftstorage.link/ipfs/${img}`}
                    autoPlayAfterSrcChange={false}
                    showJumpControls={false}
                  />
                </>
              ) : (
                type === "video" && (
                  <ReactPlayer
                    url={`https://nftstorage.link/ipfs/${img}`}
                    controls={true}
                    width="100%"
                    height="auto"
                  />
                )
              )}
            </div>
            {unlockable !== "" && owner === web3Ctx.account && (
              <div className="position-absolute top-0 end-0 m-3">
                <a
                  href={unlockable}
                  className="btn btn-info px-3"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="las la-cloud"></i>
                </a>
              </div>
            )}
          </div>

          <div className="fw-bold lead mb-3 d-flex align-items-center justify-content-between">
            {id.length >= 15 ? (
              <Link
                className="text-reset text-nowrap text-truncate"
                // style={{ maxWidth: "150px" }}
                to={`/lazy/${id}`}
              >
                {/* {truncateStart(title, 25)} */}
                {title}
              </Link>
            ) : (
              <Link
                className="text-reset text-nowrap text-truncate"
                to={`/assets/${id}`}
              >
                {/* {truncateStart(title, 25)} */}
                {title}
              </Link>
            )}
            <div className="ms-3">
              <NftCategory category={category} />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="author position-static z-index-20 d-flex align-items-center">
              <Link className="text-reset" to={`/users/${owner}`}>
                <div className="author-avatar">
                  <span
                    className="author-avatar-inner"
                    style={{
                      background: `url(${
                        ownerAvatar !== ""
                          ? ownerAvatar
                          : "/images/Login_icon.svg"
                      })`,
                    }}
                  ></span>
                </div>
              </Link>
              <div className="ms-2">
                <p className="text-muted fw-normal mb-0 lh-1">
                  <span className="text-xs">Owned By</span>
                  <strong className="d-block fw-bold h6 text-dark mb-0">
                    <Link className="text-reset" to={`/users/${owner}`}>
                      {truncateStart(ownerName, 10)}
                    </Link>
                  </strong>
                </p>
              </div>
            </div>

            <p className="text-muted fw-normal mb-0 lh-1">
              <span className="text-xs">Current Price</span>
              {index !== -1 ? (
                owner !== web3Ctx.account ? (
                  <strong className="d-block fw-bold lead text-dark h2 mb-0">
                    {price} {settings.currency}
                  </strong>
                ) : (
                  <strong className="d-block fw-bold lead text-dark h2 mb-0">
                    {price} {settings.currency}
                  </strong>
                )
              ) : price ? (
                <strong className="d-block fw-bold lead text-dark h2 mb-0">
                  {price} {settings.currency}
                </strong>
              ) : owner === web3Ctx.account ? (
                <strong className="d-block fw-bold lead text-dark h2 mb-0">
                  Not Set
                </strong>
              ) : (
                <strong className="d-block fw-bold lead text-dark h2 mb-0">
                  Not Set
                </strong>
              )}
            </p>
          </div>
          {index !== -1 ? (
            owner !== web3Ctx.account ? (
              <>
                <div className="card-ribbon">
                  {/* <span className="bg-danger px-2 py-1 rounded-sm">
                    On Sale
                  </span>{" "} */}
                  {unlockable !== "" && (
                    <span className="px-2 py-1 rounded-sm bg-dark text-white ms-1">
                      Unlockable
                    </span>
                  )}
                </div>
                {/* <div className="card-action">
                  {userCtx.userIsRegistered ? (
                    <button
                      type="button"
                      className="btn btn-primary text-nowrap"
                      value={index}
                      onClick={buyHandler}
                    >
                      <i className="lab la-ethereum me-2"></i>
                      Purchase NFT
                    </button>
                  ) : (
                    <>
                      {web3Ctx.account ? (
                        <Link
                          className="btn btn-primary text-nowrap"
                          value={index}
                          to="/register"
                        >
                          <i className="las la-user me-2"></i>
                          Register to Buy
                        </Link>
                      ) : (
                        <>
                          <div className="card-ribbon">
                            <span className="bg-danger px-2 py-1 rounded-sm">
                              NOT FOR SALE
                            </span>{" "}
                            {unlockable !== "" && (
                              <span className="px-2 py-1 rounded-sm bg-dark text-white ms-1">
                                Unlockable
                              </span>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div> */}
              </>
            ) : (
              <>
                {/* <div className="card-action">
                  <button
                    type="button"
                    value={index}
                    className="btn btn-danger text-nowrap"
                    onClick={cancelHandler}
                  >
                    Unlist NFT from sale
                  </button>
                </div> */}
              </>
            )
          ) : owner === web3Ctx.account && lazy === null ? (
            <>
              <div className="card-ribbon top-0 mt-4 pt-2">
                <span className="bg-primary px-2 py-1 rounded-sm">Owned</span>{" "}
                {unlockable !== "" && (
                  <span className="px-2 py-1 rounded-sm bg-dark text-white ms-1">
                    Unlockable
                  </span>
                )}
              </div>
              {/* <div className="card-action">
                <button
                  className="btn btn-primary text-nowrap"
                  type="button"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  Create Sale
                </button>
              </div> */}
              <Modal
                status={isModalOpen}
                variant="modal-card-inner"
                modalClose={closeModalHandler}
                layout={{ width: "400px", maxWidth: "100%" }}
              >
                <div className="card-body text-center py-lg-5">
                  <h4 className="mb-1">List NFT for Sale</h4>
                  {saleType === "fixedPrice" && (
                    <p className="text-muted mb-4">
                      Add price to your NFT with {settings.currency}
                    </p>
                  )}
                  {saleType === "auction" && (
                    <p className="text-muted mb-4">Add Auction end date</p>
                  )}

                  {saleType === "" && (
                    <div className="d-flex flex-column">
                      <button
                        className="btn btn-primary m-1 w-100"
                        type="button"
                        onClick={() => setSaleType("fixedPrice")}
                      >
                        Fixed Price
                      </button>
                      <button
                        className="btn btn-info m-1 w-100"
                        type="button"
                        onClick={() => setSaleType("auction")}
                      >
                        Open For Bids
                      </button>
                    </div>
                  )}

                  {saleType === "fixedPrice" && (
                    <form onSubmit={(e) => makeOfferHandler(e, id, nftKey)}>
                      <input
                        type="number"
                        step="0.001"
                        min="0.0000000000000000000000001"
                        placeholder={`Price with ${settings.currency}...`}
                        className="form-control mb-2"
                        ref={priceRefs.current[nftKey]}
                        required={true}
                        autoFocus={true}
                        value={offerPrice}
                        onChange={(e) => setOfferPrice(e.target.value)}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary w-100 rounded-sm mb-2"
                      >
                        Create sale
                      </button>
                      <p className="mb-0 text-center text-muted">
                        You'll get
                        <span className="text-primary fw-normal mx-1">
                          {offerPrice
                            ? offerPrice -
                              (parseFloat(offerPrice) *
                                settings.saleCommission) /
                                1000
                            : 0}
                        </span>
                        {settings.currency} after marketplace commission
                      </p>
                    </form>
                  )}

                  {saleType === "auction" && (
                    <form onSubmit={(e) => makeAuctionHandler(e, endDate, id)}>
                      <div className="row gy-3 text-start">
                        <div className="col-12">
                          <label className="form-label text-start fw-bold">
                            Auction Ends At
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            value={endDate}
                            autoFocus={true}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-100 rounded-sm mb-2"
                          >
                            Create sale
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </Modal>
            </>
          ) : !lazy ? (
            <>
              <div className="card-ribbon">
                <span
                  className=" px-2 py-1 rounded-sm"
                  style={{ backgroundColor: "#8c989b", color: "#000000" }}
                >
                  NOT FOR SALE
                </span>{" "}
                {unlockable !== "" && (
                  <span className="px-2 py-1 rounded-sm bg-dark text-white ms-1">
                    Unlockable
                  </span>
                )}
              </div>
            </>
          ) : null}

          <div className="text-muted fw-normaltext-sm d-flex align-items-center mt-4 justify-content-between">
            <p className="mb-0 text-xs d-flex align-items-center">
              <i className="las la-percentage me-1"></i>
              <span className="me-1 text-primary">{royalties * 1}%</span>
              Royalties
            </p>
            <p className="text-xs mb-0 d-flex align-items-center">
              <i className="la-sm text-primary las la-clock mx-1 text-primary"></i>
              {formatDate(dateCreated)} ago
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default NftItem;
