import React, {
  useContext,
  useRef,
  createRef,
  useEffect,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Countdown from "react-countdown";
import web3 from "../../connect-web3/web3";
import Web3 from "web3";
import Web3Context from "../../providers/web3-context";
import UserContext from "../../providers/user-context";
import AuctionContext from "../../providers/auction-context";
import { formatDate, truncateStart, formatPrice } from "../../helpers/utils";
import { settings } from "../../helpers/settings";
import NftCategory from "./NftCategory";
import Modal from "./Modal";
import AOS from "aos";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

const melodyStyle = {
  fontSize: "5rem",
  color: "#fff",
  position: "absolute",
  top: "4rem",
  left: "50%",
  transform: "translateX(-50%)",
};

function AuctionItem({
  img,
  title,
  owner,
  user,
  category,
  dateCreated,
  tokenId,
  auctionId,
  index,
  endAt,
  nftKey,
  noAnimation,
  unlockable,
  royalties,
  bids,
  type,
}) {
  const web3Ctx = useContext(Web3Context);
  const auctionCtx = useContext(AuctionContext);
  const userCtx = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ownerName, setOwnerName] = useState("Loading...");
  const [ownerAvatar, setOwnerAvatar] = useState("");
  const [bidPrice, setBidPrice] = useState("");
  const { addToast } = useToasts();
  const [isCurrentBidder, setIsCurrentBidder] = useState(false);
  const [auctionEnded, setAuctionEnded] = useState(false);
  const [topBidder, setTopBidder] = useState("");
  const [topBid, setTopBid] = useState(0);
  const [networkId, setNetworkId] = useState(0);
  const navigate = useHistory();

  /*** =============================================== */
  //      GET ACTIVE NETWORK ID
  /*** =============================================== */
  useEffect(() => {
    async function getNetworkId() {
      if (window.ethereum) {
        const networkId = await web3Ctx.loadNetworkId(
          new Web3(window.ethereum)
        );
        setNetworkId(networkId);
      }
    }
    getNetworkId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*** =============================================== */
  //      GET TOP BID
  /*** =============================================== */
  useEffect(() => {
    if (bids.length > 0) {
      const auctionBids = bids
        .filter((bid) => bid.withdraw !== true)
        .map((bid) => bid.amount);
      if (auctionBids.length > 0) {
        setTopBid(Math.max(...auctionBids));
      } else {
        setTopBid(0);
      }
    } else {
      setTopBid(0);
    }
  }, [bids]);

  /*** =============================================== */
  //      VALIDATE IF THE USER IS CURRENT BIDDER
  /*** =============================================== */
  useEffect(() => {
    if (bids.length > 0) {
      const bidders = bids
        .filter((bid) => bid.withdraw !== true)
        .map((bid) => bid.bidder);
      if (bidders.includes(web3Ctx.account)) {
        setIsCurrentBidder(true);
      } else {
        setIsCurrentBidder(false);
      }
    } else {
      setIsCurrentBidder(false);
    }
  }, [bids, web3Ctx.account]);

  /*** =============================================== */
  //      GET TOP BIDDER
  /*** =============================================== */
  useEffect(() => {
    if (bids.filter((bid) => bid.withdraw !== true).length > 0) {
      const auctionBids = bids
        .filter((bid) => bid.withdraw !== true)
        .map((bid) => bid.amount);
      const maxBid = Math.max(...auctionBids);
      const topBidder = bids
        .filter((bid) => bid.withdraw !== true)
        .filter((bid) => bid.amount === maxBid)[0].bidder;
      setTopBidder(topBidder);
    }
  }, [bids]);

  /*** =============================================== */
  //      CHECK IF AUCTION IS STILL OPEN
  /*** =============================================== */
  useEffect(() => {
    if (endAt <= new Date().getTime()) {
      setAuctionEnded(true);
    }
  }, [endAt]);

  const Completionist = () => (
    <div className="text-center p-4 rounded-lg bg-light mt-4">
      <h6 className="text-center mb-0 fw-bold text-uppercase letter-spacing-0">
        Auction Ended
      </h6>
      {web3Ctx.account === topBidder && topBid > 0 && (
        <>
          <p className="text-muted mb-2 text-center">
            Great! you win the auction.
          </p>
          <button
            className="btn btn-primary btn-sm py-2 px-4"
            type="button"
            onClick={claimNFTHandler}
          >
            <span className="lh-reset">Claim your NFT</span>
          </button>
        </>
      )}

      {topBid === 0 && web3Ctx.account === user && (
        <>
          <p className="text-muted mb-2 text-center">No one was interested.</p>
          <button
            className="btn btn-primary btn-sm py-2 px-4"
            type="button"
            onClick={cancelHandler}
          >
            <span className="lh-reset">Restore your NFT</span>
          </button>
        </>
      )}

      {topBid > 0 && web3Ctx.account === user && (
        <>
          <p className="text-muted mb-2 text-center">
            This NFT has another owner now, it's no longer yours
          </p>
        </>
      )}
    </div>
  );

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      return (
        <div className="countdown rounded-lg bg-light mt-4 mb-5">
          <div className="countdown-item flex-fill">
            <div className="countdown-item-number bg-white w-100">{days}</div>
            <span>Days</span>
          </div>
          <div className="countdown-item flex-fill">
            <div className="countdown-item-number bg-white w-100">{hours}</div>
            <span>Hours</span>
          </div>
          <div className="countdown-item flex-fill">
            <div className="countdown-item-number bg-white w-100">
              {minutes}
            </div>
            <span>Mins</span>
          </div>
          <div className="countdown-item flex-fill">
            <div className="countdown-item-number bg-white w-100">
              {seconds}
            </div>
            <span>Secs</span>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    AOS.init({ duration: 1000, offset: 10 });
  }, []);

  /*** =============================================== */
  //      DECLARE PRICE REFERENCE
  /*** =============================================== */
  const priceRefs = useRef([]);
  if (priceRefs.current.length !== auctionCtx.auctions.length) {
    priceRefs.current = Array(auctionCtx.auctions.length)
      .fill()
      .map((_, i) => priceRefs.current[i] || createRef());
  }

  /*** =============================================== */
  //      GET OWNER AVATAR & NAME
  /*** =============================================== */
  useEffect(() => {
    if (
      userCtx.contract &&
      userCtx.usersList &&
      userCtx.usersList.length > 0 &&
      auctionCtx.auctionsData &&
      auctionCtx.auctionsData.length > 0
    ) {
      const nftOwnerName = userCtx.usersList.filter(
        (u) => u.account === user
      )[0].fullName;
      const nftOwnerAvatar = userCtx.usersList.filter(
        (u) => u.account === user
      )[0].avatar;
      setOwnerAvatar(nftOwnerAvatar);
      setOwnerName(nftOwnerName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userCtx.usersList,
    userCtx.contract,
    auctionCtx.auctionsData,
    auctionCtx.contract,
  ]);

  /*** =============================================== */
  //      CANCEL AUCTION FUNCTION
  /*** =============================================== */
  const cancelHandler = (event) => {
    auctionCtx.contract.methods
      .cancelAuction(tokenId, auctionId)
      .send({ from: web3Ctx.account })
      .once("sending", () => {
        auctionCtx.setAuctionTransactionLoading(true);
      })
      .on("transactionHash", (hash) => {
        auctionCtx.setAuctionTransactionLoading(true);
      })
      .on("receipt", () => {
        auctionCtx.setAuctionTransactionLoading(false);
        userCtx.loadTransactions(userCtx.contract);
        userCtx.loadActivity(userCtx.contract);
      })
      .on("error", (error) => {
        auctionCtx.setAuctionTransactionLoading(false);
        addToast("Oops! an error occured", {
          appearance: "error",
        });
      });
  };

  /*** =============================================== */
  //      PLACE BID FUNCTION
  /*** =============================================== */
  const placeBidHandler = (event, nftKey) => {
    event.preventDefault();

    const enteredPrice = web3.utils.toWei(
      priceRefs.current[nftKey].current.value,
      "ether"
    );

    auctionCtx.contract.methods
      .bid(tokenId, auctionId, enteredPrice)
      .send({ from: web3Ctx.account, value: enteredPrice })
      .once("sending", () => {
        auctionCtx.setAuctionTransactionLoading(true);
        setIsModalOpen(false);
      })
      .on("transactionHash", (hash) => {
        auctionCtx.setAuctionTransactionLoading(true);
      })
      .on("receipt", () => {
        userCtx.loadTransactions(userCtx.contract);
        userCtx.loadActivity(userCtx.contract);
      })
      .on("error", (error) => {
        addToast("Oops! an error occured", {
          appearance: "error",
        });
        auctionCtx.setAuctionTransactionLoading(false);
      });
  };

  /*** =============================================== */
  //      WITHDRAW BID FUNCTION
  /*** =============================================== */
  const withdrawBidHandler = (event) => {
    event.preventDefault();

    auctionCtx.contract.methods
      .withdraw(tokenId, auctionId)
      .send({ from: web3Ctx.account })
      .once("sending", () => {
        auctionCtx.setAuctionTransactionLoading(true);
      })
      .on("transactionHash", (hash) => {
        auctionCtx.setAuctionTransactionLoading(true);
      })
      .on("receipt", () => {
        userCtx.loadTransactions(userCtx.contract);
        userCtx.loadActivity(userCtx.contract);
      })
      .on("error", (error) => {
        addToast("Oops! an error occured", {
          appearance: "error",
        });
        auctionCtx.setAuctionTransactionLoading(false);
      });
  };

  /*** =============================================== */
  //      CLAIM WINNDED NFT
  /*** =============================================== */
  const claimNFTHandler = (event) => {
    event.preventDefault();

    auctionCtx.contract.methods
      .endAuction(tokenId, auctionId)
      .send({
        from: topBidder,
      })
      .once("sending", () => {
        auctionCtx.setAuctionTransactionLoading(true);
      })
      .on("transactionHash", (hash) => {
        auctionCtx.setAuctionTransactionLoading(true);
      })
      .on("receipt", () => {
        auctionCtx.setAuctionTransactionLoading(false);
        userCtx.loadTransactions(userCtx.contract);
        userCtx.loadActivity(userCtx.contract);
        navigate.push("/auctions");
      })
      .on("error", (error) => {
        addToast("Oops! an error occured", {
          appearance: "error",
        });
        auctionCtx.setAuctionTransactionLoading(false);
      });
  };

  // claimNFTHandler();

  /*** =============================================== */
  //      CLOSE MODAL FUNCTION
  /*** =============================================== */
  function closeModalHandler() {
    setIsModalOpen(false);
  }

  return (
    <>
      <div
        className={`card rounded card-hover-image position-relative ${category}`}
        data-aos={`${noAnimation ? "" : "fade-up"}`}
        data-aos-once="true"
        data-aos-delay={(nftKey + 1) * 100}
      >
        <div className="card-body p-3 position-relative">
          <div className="position-relative mb-4 shadow">
            <div
              className={`card-img-holder rounded overflow-hidden ${
                type === "audio" ? "audio" : ""
              }`}
            >
              {type === "image" ? (
                <div
                  className="w-100 h-100 card-img-holder-inner"
                  style={{
                    backgroundImage: `url(https://nftstorage.link/ipfs/${img})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                ></div>
              ) : type === "audio" ? (
                <>
                  <i className="las la-music" style={melodyStyle}></i>
                  <AudioPlayer
                    src={`https://nftstorage.link/ipfs/${img}`}
                    autoPlayAfterSrcChange={false}
                    showJumpControls={false}
                  />
                </>
              ) : (
                type === "video" && (
                  <ReactPlayer
                    url={`https://nftstorage.link/ipfs/${img}`}
                    controls={true}
                    width="100%"
                    height="auto"
                  />
                )
              )}
            </div>

            {unlockable !== "" && owner === web3Ctx.account && (
              <div className="position-absolute top-0 end-0 m-3">
                <a
                  href={unlockable}
                  className="btn btn-info px-3"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="las la-cloud"></i>
                </a>
              </div>
            )}
          </div>

          <div className="fw-bold lead mb-3 d-flex align-items-center justify-content-between">
            <Link
              className="text-reset text-truncate"
              to={`/nftauction/${tokenId}`}
            >
              {/* {truncateStart(title, 25)}
               */}
              {title}
            </Link>
            <div className="ms-3">
              <NftCategory category={category} />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="author position-static z-index-20 d-flex align-items-center">
              <Link className="text-reset" to={`/users/${user}`}>
                <div className="author-avatar">
                  <span
                    className="author-avatar-inner"
                    style={{
                      background: `url(${
                        ownerAvatar !== ""
                          ? ownerAvatar
                          : "/images/Login_icon.svg"
                      })`,
                    }}
                  ></span>
                </div>
              </Link>
              <div className="ms-2">
                <p className="text-muted fw-normal mb-0 lh-1">
                  <span className="text-xs">Owned By</span>
                  <strong className="d-block fw-bold h6 text-dark mb-0">
                    <Link className="text-reset" to={`/users/${user}`}>
                      {truncateStart(ownerName, 10)}
                    </Link>
                  </strong>
                </p>
              </div>
            </div>

            <p className="text-muted fw-normal mb-0 lh-1">
              <span className="text-xs">Highest Bid</span>
              {index !== -1 ? (
                <strong className="d-block fw-bold lead text-dark h2 mb-0">
                  {topBid > 0 ? formatPrice(topBid).toFixed(3) : 0}{" "}
                  {settings.currency}
                </strong>
              ) : owner === web3Ctx.account ? (
                <strong className="d-block fw-bold lead text-dark h2 mb-0">
                  Not Set
                </strong>
              ) : (
                <strong className="d-block fw-bold lead text-dark h2 mb-0">
                  Not Set
                </strong>
              )}
            </p>
          </div>
          {index !== -1 ? (
            user !== web3Ctx.account ? (
              <>
                <div className="card-ribbon">
                  {auctionEnded !== true ? (
                    <span className="bg-danger px-2 py-1 rounded-sm">
                      Open for Bids
                    </span>
                  ) : (
                    <span
                      className="text-black px-2 py-1 rounded-sm"
                      style={{ backgroundColor: "#8c989b" }}
                    >
                      NOT FOR SALE
                    </span>
                  )}
                  {unlockable !== "" && (
                    <span className="px-2 py-1 rounded-sm bg-dark text-white ms-1">
                      Unlockable
                    </span>
                  )}
                </div>
                {/* <div className="card-action">
                  {auctionEnded !== true && (
                    <>
                      {userCtx.userIsRegistered ? (
                        <>
                          {isCurrentBidder === false && (
                            <button
                              type="button"
                              className="btn btn-primary text-nowrap"
                              value={index}
                              onClick={() => {
                                setIsModalOpen(true);
                              }}
                            >
                              <i className="lab la-ethereum me-2"></i>
                              Place Bid
                            </button>
                          )}
                          {isCurrentBidder === true && (
                            <button
                              type="button"
                              className="btn btn-danger text-nowrap"
                              value={index}
                              onClick={withdrawBidHandler}
                            >
                              <i className="lab la-ethereum me-2"></i>
                              Withdraw Bid
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          {window.ethereum &&
                            networkId === settings.networkId && (
                              <Link
                                className="btn btn-primary text-nowrap"
                                value={index}
                                to="/register"
                              >
                                <i className="las la-user me-2"></i>
                                Register to Bid
                              </Link>
                            )}
                        </>
                      )}
                    </>
                  )}
                </div> */}

                <Modal
                  status={isModalOpen}
                  variant="modal-card-inner"
                  modalClose={closeModalHandler}
                  layout={{ width: "400px", maxWidth: "100%" }}
                >
                  <div className="card-body text-center py-lg-5">
                    <form onSubmit={(e) => placeBidHandler(e, nftKey)}>
                      <input
                        type="number"
                        step="0.001"
                        min="0.0000000000000000000000001"
                        placeholder={`Price with ${settings.currency}...`}
                        className="form-control mb-2"
                        required={true}
                        autoFocus={true}
                        ref={priceRefs.current[nftKey]}
                        value={bidPrice}
                        onChange={(e) => setBidPrice(e.target.value)}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary w-100 rounded-sm mb-2"
                      >
                        Place Bid
                      </button>
                      <p className="mb-0 text-center text-muted">
                        You'll find your funds into{" "}
                        <span className="text-primary">My Account</span> page if
                        you haven't won this auction
                      </p>
                    </form>
                  </div>
                </Modal>
              </>
            ) : (
              <>
                {/* {auctionEnded !== true && (
                  <div className="card-action">
                    <button
                      type="button"
                      value={nftKey}
                      className="btn btn-danger text-nowrap"
                      onClick={cancelHandler}
                    >
                      Cancel Auction
                    </button>
                  </div>
                )} */}
              </>
            )
          ) : (
            <>
              <div className="card-ribbon">
                <span
                  className="text-black px-2 py-1 rounded-sm"
                  style={{ backgroundColor: "#8c989b" }}
                >
                  NOT FOR SALE
                </span>{" "}
                {unlockable !== "" && (
                  <span className="px-2 py-1 rounded-sm bg-dark text-white ms-1">
                    Unlockable
                  </span>
                )}
              </div>
            </>
          )}

          <Countdown
            date={endAt}
            renderer={renderer}
            onComplete={() => setAuctionEnded(true)}
          />

          <div className="text-muted fw-normaltext-sm d-flex align-items-center mt-4 justify-content-between">
            <p className="mb-0 text-xs d-flex align-items-center">
              <i className="las la-percentage me-1"></i>
              <span className="me-1 text-primary">{royalties}%</span>
              Royalties
            </p>
            <p className="text-xs mb-0 d-flex align-items-center">
              <i className="la-sm text-primary las la-clock mx-1 text-primary"></i>
              {formatDate(dateCreated)} ago
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuctionItem;
