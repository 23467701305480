const { initializeApp } = require("firebase/app");
// import { initializeApp } from 'firebase/app';
const {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
} = require("firebase/firestore");
const firebaseConfig = require("./firebase.config.json");

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const getAllData = async () => {
  let alldata = [];
  const querySnapshot = await getDocs(collection(db, "FCA"));
  querySnapshot.forEach((doc) => {
    console.log(`${doc.id} => ${doc.data()}`);
    let data = doc.data();
    console.log(data);
    data.id = doc.id;
    console.log(data);
    alldata.push(data);
  });

  return alldata;
};

export const addData = async (wallet, ipfshash, price, royalty) => {
  const walletRef = await addDoc(collection(db, "FCA"), {
    creator: wallet,
    asset: ipfshash,
    price: price,
    royalties: royalty,
  });
  return walletRef.id;
};

// setdata();

// --------------------------------------------

// pushdata();

// --------------------------------------------

export const getData = async (docId) => {
  const docRef = doc(db, "FCA", docId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return true;
  } else {
    return false;
  }
};
export const getFeaturedData = async (docId) => {
  const docRef = doc(db, "Featured", "FCA");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data().id;
  } else {
    return false;
  }
};
export const deletedata = async (docId) => {
  const docRef = doc(db, "FCA", docId);
  await deleteDoc(docRef);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log("still exists");
  } else {
    console.log("it worked!");
  }
};

// deletedata();

// --------------------------------------------

// const RTdocs = async () => {
//   const walletRef = db.collection("FCA").doc("Wallets");
//   walletRef.onSnapshot((doc) => {
//     console.log(doc.data());
//   });
// };

// RTdocs();
