import React, { useEffect, useContext, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import CollectionContext from '../../providers/collection-context';
import MarketplaceContext from '../../providers/marketplace-context';
import AuctionContext from '../../providers/auction-context';
import { formatPrice, formatCategory } from '../../helpers/utils';
import { settings } from '../../helpers/settings';

// COMPONENTS
import PageBanner from '../general/PageBanner';
import NftItem from '../general/NftItem';
import AuctionItem from '../general/AuctionItem';
import Loader from '../general/Loader';
import FullScreenLoader from '../general/FullScreenLoader';
import MetaMaskLoader from '../general/MetaMaskLoader';

function Category() {
    const collectionCtx = useContext(CollectionContext);
    const marketplaceCtx = useContext(MarketplaceContext);
    const auctionCtx = useContext(AuctionContext);
    const [currentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const { category } = useParams();
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = collectionCtx.collection.slice(indexOfFirstItem, indexOfLastItem);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `${category} | ${settings.UISettings.marketplaceBrandName}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {auctionCtx.fetchingLoading ? <FullScreenLoader heading='Loading' /> : null}
            {auctionCtx.auctionTransactionLoading ? <FullScreenLoader heading='loading' /> : null}
            {marketplaceCtx.mktIsLoading ? <FullScreenLoader heading='loading' /> : null}
            {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
            <PageBanner heading={`${category} NFTs`} />
            <section className='py-5'>
                <div className='container py-5'>
                    {collectionCtx.collection.length !== 0 && collectionCtx.totalSupply !== '0' ? (
                        <div className='row gy-4 mb-5'>
                            {currentItems
                                .filter(
                                    (nft) =>
                                        !auctionCtx.auctions
                                            .filter((auc) => auc.isActive === true)
                                            .some((auc) => nft.id === auc.tokenId)
                                )
                                .filter((el) => el.category === category)
                                .map((NFT, key) => {
                                    const index = marketplaceCtx.offers
                                        ? marketplaceCtx.offers.findIndex((offer) => offer.id === NFT.id)
                                        : -1;
                                    const owner = index === -1 ? NFT.owner : marketplaceCtx.offers[index].user;
                                    const price =
                                        index !== -1
                                            ? formatPrice(marketplaceCtx.offers[index].price).toFixed(2)
                                            : null;

                                    return (
                                        <div className={`col-xl-4 col-md-6 mix ${NFT.category}`} key={key}>
                                            <NftItem {...NFT} index={index} owner={owner} price={price} nftKey={key} />
                                        </div>
                                    );
                                })}

                            {auctionCtx.auctionsData
                                .filter((auc) => auc.active === true)
                                .filter((el) => el.category === category)
                                .map((AUC, key) => {
                                    return (
                                        <div className='col-xl-4 col-md-6' key={key}>
                                            <AuctionItem {...AUC} nftKey={key} />
                                        </div>
                                    );
                                })}
                        </div>
                    ) : (
                        <>
                            <h6 className='fw-normal text-muted text-center mb-0'>
                                Fetching data from the blockchain please wait...
                            </h6>
                            <Loader />
                        </>
                    )}
                    {currentItems.filter((el) => el.category === category).length === 0 &&
                        collectionCtx.collection.length !== 0 &&
                        collectionCtx.totalSupply !== '0' && (
                            <div className='text-center'>
                                <h4 className='text-center'>There're no NFTs at the moment</h4>
                                <p className='text-muted mb-3'>
                                    Once there'll be NFTs that match is category we'll render them here
                                </p>
                                <Link className='btn btn-gradient-primary' to='/'>
                                    Return Home
                                </Link>
                            </div>
                        )}
                </div>
            </section>
        </>
    );
}

export default Category;
