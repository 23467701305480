import React, { useContext, useEffect, useState } from "react";
import Select from "react-dropdown-select";
import UserContext from "../../providers/user-context";
import MarketplaceContext from "../../providers/marketplace-context";
import Web3Context from "../../providers/web3-context";
import { useToasts } from "react-toast-notifications";

// COMPONENTS
import MetaMaskLoader from "../general/MetaMaskLoader";
import WhiteListTable from "../tables/WhiteListTable";

function WhiteList() {
  const userCtx = useContext(UserContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const web3Ctx = useContext(Web3Context);
  const [metaMaskOpened, setMetaMaskOpened] = useState(false);
  const { addToast } = useToasts();
  const [usersList, setUsersList] = useState(null);
  const [whiteList, setWhiteList] = useState(null);
  const [choosedAddress, setChoosedAddress] = useState("");
  const [whitelistAddress, setwhitelistAddress] = useState("");

  /*** =============================================== */
  //      SELECT ITEM TEMPLATE
  /*** =============================================== */
  const ListItem = ({ avatar, fullName, email }) => {
    return (
      <div className="d-flex align-items-center">
        <div className="author-avatar">
          <span
            className="author-avatar-inner"
            style={{ background: `url(${avatar})` }}
          ></span>
        </div>
        <div className="ms-3">
          <p className="fw-bold text-base mb-0">{fullName}</p>
          <p className="text-xxs mb-0">{email}</p>
        </div>
      </div>
    );
  };

  /*** =============================================== */
  //      GET USERS LIST SELECT OPTIONS
  /*** =============================================== */
  useEffect(() => {
    if (
      userCtx.contract &&
      userCtx.usersList &&
      marketplaceCtx.contract &&
      userCtx.appOwner &&
      userCtx.whiteList
    ) {
      const users = userCtx.usersList
        .filter((user) => {
          return user.account !== userCtx.appOwner;
        })
        .filter(
          (user) =>
            !userCtx.whiteList.map((el) => el.address).includes(user.account)
        )
        .map((user) => {
          return {
            value: user.account,
            label: <ListItem {...user} />,
          };
        });
      setUsersList(users);
    }
  }, [
    userCtx.contract,
    userCtx.usersList,
    marketplaceCtx.contract,
    userCtx.appOwner,
    userCtx.whiteList,
  ]);

  /*** =============================================== */
  //      GET WHITELISTED USERS SELECT OPTIONS
  /*** =============================================== */
  useEffect(() => {
    if (userCtx.contract && userCtx.whiteList && userCtx.usersList) {
      const users = userCtx.whiteList
        .filter(
          (user) =>
            user.address !== "0x0000000000000000000000000000000000000000"
        )
        .map((user) => {
          return {
            value: user.address,
            label: (
              <ListItem
                fullName={
                  userCtx.usersList.filter(
                    (el) => el.account === user.address
                  )[0].fullName
                }
                email={
                  userCtx.usersList.filter(
                    (el) => el.account === user.address
                  )[0].email
                }
                avatar={
                  userCtx.usersList.filter(
                    (el) => el.account === user.address
                  )[0].avatar
                }
              />
            ),
          };
        });
      setWhiteList(users);
    }
  }, [userCtx.whiteList, userCtx.usersList, userCtx.contract]);

  /*** =============================================== */
  //      ADD TO WHITELIST FUNCTION
  /*** =============================================== */
  function addToWhiteListHandler(e) {
    e.preventDefault();
    if (choosedAddress !== "") {
      userCtx.contract.methods
        .addToWhitelist(choosedAddress)
        .send({ from: web3Ctx.account })
        .once("sending", function (payload) {
          setMetaMaskOpened(true);
        })
        .on("transactionHash", (hash) => {
          addToast(`Great! you have a new user to whitelist`, {
            appearance: "success",
          });
        })
        .on("receipt", (receipt) => {
          setMetaMaskOpened(false);
          setChoosedAddress("");
          userCtx.loadWhiteList(userCtx.contract);
          userCtx.loadActivity(userCtx.contract);
        })
        .on("error", (e) => {
          addToast("Oops! an error occured", {
            appearance: "error",
          });
          setMetaMaskOpened(false);
        });
    }
  }

  /*** =============================================== */
  //      REMOVE FROM WHITELIST FUNCTION
  /*** =============================================== */
  function removeFromWhiteListHandler(e) {
    e.preventDefault();
    if (whitelistAddress !== "") {
      userCtx.contract.methods
        .removeFromWhitelist(whitelistAddress)
        .send({ from: web3Ctx.account })
        .once("sending", function (payload) {
          setMetaMaskOpened(true);
        })
        .on("transactionHash", (hash) => {
          addToast(`Great! you have a deleted a user from whitelist`, {
            appearance: "success",
          });
        })
        .on("receipt", (receipt) => {
          setMetaMaskOpened(false);
          setwhitelistAddress("");
          userCtx.loadWhiteList(userCtx.contract);
          userCtx.loadActivity(userCtx.contract);
        })
        .on("error", (e) => {
          addToast("Something went wrong when pushing to the blockchain", {
            appearance: "error",
          });
          setMetaMaskOpened(false);
        });
    }
  }

  return (
    <>
      {metaMaskOpened ? <MetaMaskLoader /> : null}
      <div className="row gy-4">
        <div
          className="col-lg-6 z-index-40"
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <div className="card shadow-0 p-lg-3">
            <div className="card-body p-4">
              <h5 className="mb-4">Add to WhiteList</h5>

              <form onSubmit={addToWhiteListHandler}>
                {usersList && (
                  <>
                    <Select
                      searchable={false}
                      options={usersList}
                      className="form-select border-gray-300 ps-3 shadow-0 bg-white"
                      value={choosedAddress}
                      onChange={(values) =>
                        setChoosedAddress(
                          values.map((el) => el.value).toString()
                        )
                      }
                    />

                    <button
                      className="btn btn-primary w-100 mt-3"
                      type="submit"
                    >
                      Add to WhiteList
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>

        <div
          className="col-lg-6 z-index-30"
          data-aos="fade-left"
          data-aos-delay="200"
        >
          <div className="card shadow-0 p-lg-3">
            <div className="card-body p-4">
              <h5 className="mb-4">Remove from WhiteList</h5>

              <form onSubmit={removeFromWhiteListHandler}>
                {whiteList && (
                  <>
                    <Select
                      searchable={false}
                      options={whiteList}
                      className="form-select border-gray-300 ps-3 shadow-0 bg-white"
                      value={whitelistAddress}
                      onChange={(values) =>
                        setwhitelistAddress(
                          values.map((el) => el.value).toString()
                        )
                      }
                    />

                    <button
                      className="btn btn-primary w-100 mt-3"
                      type="submit"
                    >
                      Remove from WhiteList
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>

        <div
          className="col-lg-12 z-index-20"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="card shadow-0 p-lg-3">
            <div className="card-body p-4">
              <h5 className="mb-4">WhiteList Users</h5>
              <WhiteListTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhiteList;
