import React, { useReducer } from "react";
import { deletedata, getAllData, getFeaturedData } from "../config/firebase";

import CollectionContext from "./collection-context";

const defaultCollectionState = {
  contract: null,
  totalSupply: null,
  assetHistory: null,
  nftHistory: null,
  nftCreator: null,
  collection: [],
  nftTransactionLoading: false,
  nftIsLoading: true,
};

const collectionReducer = (state, action) => {
  if (action.type === "CONTRACT") {
    return {
      ...state,
      contract: action.contract,
    };
  }

  if (action.type === "LOADSUPPLY") {
    return {
      ...state,
      totalSupply: action.totalSupply,
    };
  }

  if (action.type === "LOADCOLLECTION") {
    return {
      ...state,
      collection: action.collection,
    };
  }

  if (action.type === "GETASSETHISTORY") {
    return {
      ...state,
      assetHistory: action.assetHistory,
    };
  }

  if (action.type === "UPDATECOLLECTION") {
    const index = state.collection.findIndex(
      (NFT) => NFT.id === parseInt(action.NFT.id)
    );
    let collection = [];

    if (index === -1) {
      collection = [action.NFT, ...state.collection];
    } else {
      collection = [...state.collection];
    }

    return {
      ...state,
      collection: collection,
    };
  }

  if (action.type === "UPDATEOWNER") {
    const index = state.collection.findIndex(
      (NFT) => NFT.id === parseInt(action.id)
    );
    let collection = [...state.collection];
    collection[index].owner = action.newOwner;

    return {
      ...state,
      collection: collection,
    };
  }

  if (action.type === "LOADING") {
    return {
      ...state,
      nftIsLoading: action.loading,
    };
  }

  if (action.type === "GETNFTHISTORY") {
    return {
      ...state,
      nftCreator: {
        account: action.nftHistory[0][0][0],
        name: action.nftHistory[0][0][1],
        avatar: action.nftHistory[0][0][2],
        time: parseInt(action.nftHistory[0][2]) * 1000,
      },
      nftHistory: action.nftHistory.slice(1).map((el) => {
        return {
          from: {
            account: el[0][0],
            name: el[0][1],
            avatar: el[0][2],
          },
          to: {
            account: el[1][0],
            name: el[1][1],
            avatar: el[1][2],
          },
          time: parseInt(el[2]) * 1000,
          price: el[3],
        };
      }),
    };
  }

  if (action.type === "GETAUCTIONBIDS") {
    return {
      ...state,
      auctionBids: action.auctionBids,
    };
  }

  if (action.type === "TRANSACTIONLOADING") {
    return {
      ...state,
      nftTransactionLoading: action.loading,
    };
  }

  if (action.type === "UPDATEAUCTION") {
    return {
      ...state,
      auctionIsUpdating: action.loading,
    };
  }

  return defaultCollectionState;
};

const CollectionProvider = (props) => {
  const [CollectionState, dispatchCollectionAction] = useReducer(
    collectionReducer,
    defaultCollectionState
  );

  const loadContractHandler = (web3, NFTCollection, deployedNetwork) => {
    const contract = deployedNetwork
      ? new web3.eth.Contract(NFTCollection.abi, deployedNetwork.address)
      : "";
    dispatchCollectionAction({ type: "CONTRACT", contract: contract });
    return contract;
  };

  const loadTotalSupplyHandler = async (contract) => {
    const totalSupply = await contract.methods.totalSupply().call();
    console.log("Total Supply: ", totalSupply);
    dispatchCollectionAction({ type: "LOADSUPPLY", totalSupply: totalSupply });
    return totalSupply;
  };

  const loadCollectionHandler = async (contract, totalSupply) => {
    let collection = [];

    const firebaseData = await getAllData();
    const featuredData = await getFeaturedData();

    for (let i = 0; i < totalSupply; i++) {
      const hash = await contract.methods._tokensURIs(i).call();
      try {
        const response =
          hash &&
          hash !== "" &&
          (await fetch(`https://nftstorage.link/ipfs/${hash}?clear`));
        if (!response.ok) {
          console.log("IPFS call has an error");
        }

        const metadata = await response.json();
        const owner = await contract.methods.ownerOf(i + 1).call();
        for (let index = 0; index < firebaseData.length; index++) {
          const element = firebaseData[index];
          if (element.asset === hash) {
            deletedata(element.id);
          }
        }
        collection = [
          {
            id: i + 1,
            title: metadata.properties.name.description,
            img: metadata.properties.image.description,
            description: metadata.properties.description.description,
            category: metadata.properties.category.description,
            dateCreated: metadata.properties.dateCreated.description,
            royalties: metadata.properties.royalties.description,
            unlockable: metadata.properties.unlockable.description,
            type: metadata.properties.type.description,
            formate: metadata.properties.formate.description,
            artBackgroundStory:
              metadata.properties.artBackgroundStory.description,
            artAuthor: metadata.properties.artAuthor.description,
            yearOfCreation: metadata.properties.yearOfCreation.description,
            creator: metadata.properties.creator.description,
            owner: owner,
            featured: featuredData.includes(i + 1),
          },
          ...collection,
        ];
      } catch {
        console.log("an NFT was blocked");
      }
    }
    for (let i = 0; i < firebaseData.length; i++) {
      const hash = firebaseData[i].asset;
      try {
        const response =
          hash &&
          hash !== "" &&
          (await fetch(`https://nftstorage.link/ipfs/${hash}?clear`));
        if (!response.ok) {
          console.log("IPFS call has an error");
        }

        const metadata = await response.json();
        const owner = firebaseData[i].creator;

        collection = [
          {
            id: firebaseData[i].id,
            title: metadata.properties.name.description,
            img: metadata.properties.image.description,
            description: metadata.properties.description.description,
            category: metadata.properties.category.description,
            dateCreated: metadata.properties.dateCreated.description,
            royalties: metadata.properties.royalties.description,
            unlockable: metadata.properties.unlockable.description,
            type: metadata.properties.type.description,
            formate: metadata.properties.formate.description,
            artBackgroundStory:
              metadata.properties.artBackgroundStory.description,
            artAuthor: metadata.properties.artAuthor.description,
            yearOfCreation: metadata.properties.yearOfCreation.description,
            owner: owner,
            creator: metadata.properties.creator.description,
            price: firebaseData[i].price,
            hash: hash,
            lazy: true,
            featured: featuredData.includes(firebaseData[i].id),
          },
          ...collection,
        ];
      } catch {
        console.log("an NFT was blocked");
      }
    }
    dispatchCollectionAction({
      type: "LOADCOLLECTION",
      collection: collection,
    });
  };

  const updateCollectionHandler = async (contract, id, owner) => {
    let NFT;
    const hash = await contract.methods.tokenURI(id).call();
    try {
      const response =
        hash &&
        hash !== "" &&
        (await fetch(`https://nftstorage.link/ipfs/${hash}?clear`));
      if (!response.ok) {
        console.log("IPFS call has an error");
      }

      const metadata = await response.json();

      NFT = {
        id: id,
        title: metadata.properties.name.description,
        img: metadata.properties.image.description,
        description: metadata.properties.description.description,
        category: metadata.properties.category.description,
        dateCreated: metadata.properties.dateCreated.description,
        royalties: metadata.properties.royalties.description,
        unlockable: metadata.properties.unlockable.description,
        type: metadata.properties.type.description,
        formate: metadata.properties.formate.description,
        artBackgroundStory: metadata.properties.artBackgroundStory.description,
        artAuthor: metadata.properties.artAuthor.description,
        yearOfCreation: metadata.properties.yearOfCreation.description,
        owner: owner,
      };
    } catch {
      console.log("an NFT was blocked");
    }
    dispatchCollectionAction({ type: "UPDATECOLLECTION", NFT: NFT });
  };

  const updateOwnerHandler = (id, newOwner) => {
    dispatchCollectionAction({
      type: "UPDATEOWNER",
      id: id,
      newOwner: newOwner,
    });
  };

  const getAssetHistoryHandler = async (contract, id) => {
    const assetHistory = await contract.methods.getTrack(id).call();
    dispatchCollectionAction({
      type: "GETASSETHISTORY",
      assetHistory: assetHistory,
    });
    return assetHistory;
  };

  const getNftHistoryHandler = async (contract, id) => {
    try {
      const nftHistory = await contract.methods.getNFTTransactions(id).call();
      dispatchCollectionAction({
        type: "GETNFTHISTORY",
        nftHistory: nftHistory,
      });
      return nftHistory;
    } catch (error) {
      return;
    }
  };

  const setNftIsLoadingHandler = (loading) => {
    dispatchCollectionAction({ type: "LOADING", loading: loading });
  };
  const setNftTransactionLoadingHandler = (loading) => {
    dispatchCollectionAction({ type: "TRANSACTIONLOADING", loading: loading });
  };

  const collectionContext = {
    contract: CollectionState.contract,
    totalSupply: CollectionState.totalSupply,
    collection: CollectionState.collection,
    nftIsLoading: CollectionState.nftIsLoading,
    nftTransactionLoading: CollectionState.nftTransactionLoading,
    assetHistory: CollectionState.assetHistory,
    nftHistory: CollectionState.nftHistory,
    nftCreator: CollectionState.nftCreator,
    loadContract: loadContractHandler,
    loadTotalSupply: loadTotalSupplyHandler,
    loadCollection: loadCollectionHandler,
    updateCollection: updateCollectionHandler,
    updateOwner: updateOwnerHandler,
    setNftIsLoading: setNftIsLoadingHandler,
    setNftTransactionLoading: setNftTransactionLoadingHandler,
    getAssetHistory: getAssetHistoryHandler,
    getNftHistory: getNftHistoryHandler,
  };

  return (
    <CollectionContext.Provider value={collectionContext}>
      {props.children}
    </CollectionContext.Provider>
  );
};

export default CollectionProvider;
