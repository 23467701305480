import { getFeaturedData } from "../config/firebase";

export const settings = {
  UISettings: {
    logo: "/images/logo-white.png",
    logoLight: "/images/logo.png",
    marketplaceBrandName: "Forever Collection Art",
    marketplaceBrandDescription: "Full functional NFT Marketplace",
    marketplaceAuthor: "Web3 Monkeys | Mohamed Hasan & Mohamed Saad",
    contactFormAddressId: "xyyodedw",
    newsletterAddressId: "xlezgplp",
    usedNetworkName: "Mumbai Testnet",
    featuredItems: getFeaturedData(),
  },
  chainName: "Mumbai",
  currency: "MATIC",
  rpcUrl: "https://rpc-mumbai.maticvigil.com",
  networkId: 80001,
  blockExplorerUrls: "https://mumbai.polygonscan.com/",
  NFTmaxSize: 102400, // 100MB
  royalties: [
    { label: "None", value: 0 },
    { label: "5%", value: 5 },
    { label: "10%", value: 10 },
    { label: "15%", value: 15 },
    { label: "20%", value: 20 },
  ],
  saleCommission: 25, // 1000 represents 100%, e.g. 25 represents 2.5% etc...
};
