import React, { useReducer } from "react";

import AuctionContext from "./auction-context";

const defaultAuctionState = {
  contract: null,
  auctions: [],
  auctionsData: [],
  userBids: [],
  userFunds: 0,
  auctionTransactionLoading: false,
  fetchingLoading: false,
};

const auctionReducer = (state, action) => {
  if (action.type === "CONTRACT") {
    return {
      ...state,
      contract: action.contract,
    };
  }

  if (action.type === "LOADFUNDS") {
    return {
      ...state,
      userFunds: parseInt(action.userFunds),
    };
  }

  if (action.type === "GETAUCTIONS") {
    return {
      ...state,
      auctions: action.auctions
        .filter((auc) => auc[1] !== "")
        .map((auc) => {
          return {
            tokenId: parseInt(auc[0]),
            tokenUri: auc[1],
            auctionId: parseInt(auc[2]),
            owner: auc[3],
            endAt: parseInt(auc[4]),
            isActive: auc[5],
            isCancelled: auc[6],
            bids: auc[7].map((bid) => {
              return {
                bidder: bid[1],
                amount: parseInt(bid[2]),
                bidTime: parseInt(bid[3]),
                withdraw: bid[4],
              };
            }),
          };
        }),
    };
  }

  if (action.type === "GETAUCTIONSDATA") {
    return {
      ...state,
      auctionsData: action.auctionsData,
    };
  }

  if (action.type === "GETUSERBIDS") {
    return {
      ...state,
      userBids: action.userBids.map((bid) => {
        return {
          tokenId: parseInt(bid[0]),
          amount: parseInt(bid[2]),
          bidTime: parseInt(bid[3]),
          withdraw: bid[4],
        };
      }),
    };
  }

  if (action.type === "TRANSACTIONLOADING") {
    return {
      ...state,
      auctionTransactionLoading: action.loading,
    };
  }

  if (action.type === "FETCHING") {
    return {
      ...state,
      fetchingLoading: action.loading,
    };
  }

  return defaultAuctionState;
};

const AuctionProvider = (props) => {
  const [AuctionState, dispatchAuctionAction] = useReducer(
    auctionReducer,
    defaultAuctionState
  );

  const loadContractHandler = (web3, NFTAuction, deployedNetwork) => {
    const contract = deployedNetwork
      ? new web3.eth.Contract(NFTAuction.abi, deployedNetwork.address)
      : "";
    dispatchAuctionAction({ type: "CONTRACT", contract: contract });
    return contract;
  };

  const loadUserFundsHandler = async (contract, account) => {
    const userFunds = await contract.methods.userFunds(account).call();
    dispatchAuctionAction({ type: "LOADFUNDS", userFunds: userFunds });
    return userFunds;
  };

  const loadAuctionsHandler = async (contract) => {
    dispatchAuctionAction({ type: "FETCHING", loading: true });
    try {
      const auctions = await contract.methods.getAuctions().call();
      dispatchAuctionAction({ type: "GETAUCTIONS", auctions: auctions });
      if (auctions.ok) {
        setTimeout(() => {
          dispatchAuctionAction({ type: "FETCHING", loading: false });
        }, 3000);
      }
      return auctions;
    } catch (error) {
      console.log(error);
      dispatchAuctionAction({ type: "FETCHING", loading: false });
    }
  };

  const loadAuctionsDataHandler = async (nftContract, auctions) => {
    let auctionsData = [];
    dispatchAuctionAction({ type: "FETCHING", loading: true });
    for (let i = 0; i < auctions.length; i++) {
      try {
        if (auctions.length === 0) {
          dispatchAuctionAction({ type: "FETCHING", loading: false });
        }
        const response = await fetch(
          `https://nftstorage.link/ipfs/${auctions[i].tokenUri}?clear`
        );
        if (!response.ok) {
          dispatchAuctionAction({ type: "FETCHING", loading: false });
          throw new Error("Something went wrong");
        }
        if (response.ok) {
          setTimeout(() => {
            dispatchAuctionAction({ type: "FETCHING", loading: false });
          }, 3000);
        }
        const metadata = await response.json();
        const owner = await nftContract.methods
          .ownerOf(auctions[i].tokenId)
          .call();
        auctionsData = [
          {
            tokenId: auctions[i].tokenId,
            auctionId: auctions[i].auctionId,
            title: metadata.properties.name.description,
            img: metadata.properties.image.description,
            description: metadata.properties.description.description,
            category: metadata.properties.category.description,
            dateCreated: metadata.properties.dateCreated.description,
            royalties: metadata.properties.royalties.description,
            type: metadata.properties.type.description,
            formate: metadata.properties.formate.description,
            artBackgroundStory:
              metadata.properties.artBackgroundStory.description,
            artAuthor: metadata.properties.artAuthor.description,
            yearOfCreation: metadata.properties.yearOfCreation.description,
            unlockable: metadata.properties.unlockable.description,
            endAt: auctions[i].endAt,
            bids: auctions[i].bids,
            owner: owner,
            cancelled: auctions[i].isCancelled,
            active: auctions[i].isActive,
            user: auctions[i].owner,
          },
          ...auctionsData,
        ];
      } catch (error) {
        console.log(error);
        dispatchAuctionAction({ type: "FETCHING", loading: false });
      }
    }
    dispatchAuctionAction({
      type: "GETAUCTIONSDATA",
      auctionsData: auctionsData,
    });
  };

  const loadUserBidsHandler = async (contract, address) => {
    try {
      const userBids = await contract.methods.userBids(address).call();
      dispatchAuctionAction({ type: "GETUSERBIDS", userBids: userBids });
      return userBids;
    } catch (error) {
      console.log(error);
    }
  };

  const setAuctionTransactionLoadingHandler = (loading) => {
    dispatchAuctionAction({ type: "TRANSACTIONLOADING", loading: loading });
  };

  const setFetchingLoadingHandler = (loading) => {
    dispatchAuctionAction({ type: "FETCHING", loading: loading });
  };

  const auctionContext = {
    contract: AuctionState.contract,
    userFunds: AuctionState.userFunds,
    auctions: AuctionState.auctions,
    auctionsData: AuctionState.auctionsData,
    userBids: AuctionState.userBids,
    auctionTransactionLoading: AuctionState.auctionTransactionLoading,
    fetchingLoading: AuctionState.fetchingLoading,
    loadContract: loadContractHandler,
    loadUserFunds: loadUserFundsHandler,
    loadAuctions: loadAuctionsHandler,
    loadAuctionsData: loadAuctionsDataHandler,
    setFetchingLoading: setFetchingLoadingHandler,
    loadUserBids: loadUserBidsHandler,
    setAuctionTransactionLoading: setAuctionTransactionLoadingHandler,
  };

  return (
    <AuctionContext.Provider value={auctionContext}>
      {props.children}
    </AuctionContext.Provider>
  );
};

export default AuctionProvider;
