import React, { useEffect, useState, useContext } from "react";
import reactImageSize from "react-image-size";

function ItemInfoPanel({
  img,
  description,
  category,
  story,
  author,
  year,
  dateCreated,
}) {
  const [imgSize, setImgSize] = useState("");

  /*** =============================================== */
  //      GET NFT IMAGE DIMENSIONS
  /*** =============================================== */
  useEffect(() => {
    async function getImageSize(x) {
      try {
        const { width, height } = await reactImageSize(x);
        setImgSize(`${width} x ${height}`);
      } catch {
        setImgSize("Not detected");
      }
    }
    getImageSize(img);
  }, [img]);

  return (
    <>
      <div className="flex flex-col justify-content-between align-items-center">
        <p
          className=" mb-2 text-yellow text-lg text-primary text-break"
          style={{ color: "#FFD700", fontWeight: "600" }}
        >
          Art work Description
        </p>
        <p className="text-muted mb-4 text-lg text-break">{description}</p>
      </div>

      <div className="flex flex-col justify-content-between align-items-center">
        <p
          className=" mb-2 text-yellow text-lg text-primary text-break"
          style={{ color: "#FFD700", fontWeight: "600" }}
        >
          Art work background story
        </p>
        <p className="text-muted mb-4 text-lg text-break">{story}</p>
      </div>

      <div className="flex flex-col justify-content-between align-items-center">
        <p
          className=" mb-2 text-yellow text-lg text-primary text-break"
          style={{ color: "#FFD700", fontWeight: "600" }}
        >
          Art work Author
        </p>
        <p className="text-muted mb-4 text-lg text-break">{author}</p>
      </div>

      <div className="flex flex-col justify-content-between align-items-center">
        <p
          className=" mb-2 text-yellow text-lg text-primary"
          style={{ color: "#FFD700", fontWeight: "600" }}
        >
          Year of Creation
        </p>
        <p className="text-muted mb-4 text-lg">{dateCreated.slice(0, 4)}</p>
      </div>
    </>
  );
}

export default ItemInfoPanel;
