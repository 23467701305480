import React, { useContext, useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { Route, Switch, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { settings } from "./helpers/settings";
import web3 from "./connect-web3/web3";
import Web3 from "web3";
import Web3Context from "./providers/web3-context";
import CollectionContext from "./providers/collection-context";
import MarketplaceContext from "./providers/marketplace-context";
import AuctionContext from "./providers/auction-context";
import UserContext from "./providers/user-context";

// CONTRACT ABIs
import Collectible from "./contracts/Collectible.json";
import Marketplace from "./contracts/Marketplace.json";
import UserInfo from "./contracts/UserInfo.json";
import NFTAuction from "./contracts/NFTAuction.json";

// COMPONENTS
import Header from "./components/general/Header";
import Footer from "./components/general/Footer";
import ScrollTopButton from "./components/general/ScrollTopButton";
import NotFound from "./components/general/NotFound";
import NoMetaMaskAlert from "./components/general/NoMetaMaskAlert";
import NoContractAlert from "./components/general/NoContractAlert";
import ScrollToTop from "./components/general/ScrollToTop";
import RegisterAlert from "./components/user/RegisterAlert";
import ViewOnlyAlert from "./components/general/ViewOnlyAlert";

// PAGES
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import CreateItem from "./components/pages/CreateItem";
import Explore from "./components/pages/Explore";
import Auctions from "./components/pages/Auctions";
import Authors from "./components/pages/Authors";
import Search from "./components/pages/Search";
import ItemSingle from "./components/pages/ItemSingle";
import AuctionSingle from "./components/pages/AuctionSingle";
import Category from "./components/pages/Category";
import MyInfo from "./components/pages/MyInfo";
import Register from "./components/pages/Register";
import UserGallery from "./components/pages/UserGallery";
import Admin from "./components/pages/Admin";
import Activity from "./components/pages/Activity";
import FAQ from "./components/pages/FAQ";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";

// Main Style
import "./App.dark.css";
import "./mode.switcher.css";
import ItemSingleFB from "./components/pages/ItemSingleFB";

function App() {
  const [noMetaMask, setNoMetaMask] = useState(false);
  const [noContract, setNoContract] = useState(false);
  const [registeredAlert, setRegisteredAlert] = useState(true);
  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const auctionCtx = useContext(AuctionContext);
  const userCtx = useContext(UserContext);
  const [networkType, setNetworkType] = useState(null);
  const [topSellers, setTopSellers] = useState([]);
  const [networkId, setNetworkId] = useState(4);
  const [web3Provider, setWeb3Provider] = useState(
    window.ethereum ? new Web3(window.ethereum) : new Web3(settings.rpcUrl)
  );
  const { addToast } = useToasts();
  const history = useHistory();

  /*** =============================================== */
  //      GET TOP SELLERS
  /*** =============================================== */
  useEffect(() => {
    if (marketplaceCtx.sellers && marketplaceCtx.sellers.length > 0) {
      setTopSellers(marketplaceCtx.sellers);
    }
  }, [marketplaceCtx.contract, marketplaceCtx.sellers]);

  /*** =============================================== */
  //      GET ACTIVE NETWORK ID
  /*** =============================================== */
  useEffect(() => {
    async function getNetworkId() {
      if (window.ethereum) {
        const networkId = await web3Ctx.loadNetworkId(
          new Web3(window.ethereum)
        );
        setNetworkId(networkId);
      }
    }
    getNetworkId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*** =============================================== */
  //      TOGGLE WEB3 PROVIDER
  /*** =============================================== */
  useEffect(() => {
    if (window.ethereum && networkId === settings.networkId) {
      setWeb3Provider(new Web3(window.ethereum));
    } else {
      setWeb3Provider(new Web3(settings.rpcUrl));
    }
  }, [networkId]);

  useEffect(() => {
    /*** =============================================== */
    //      CHECK IF THE BROWSER CONTAINS METAMASK
    /*** =============================================== */
    if (!web3) {
      setNoMetaMask(true);
      document.body.style.overflow = "hidden";
      return;
    }

    /*** =============================================== */
    //      GET BLOCKCHAIN DATA
    /*** =============================================== */
    const calclateInitialSettings = async () => {
      // Request accounts acccess if needed

      // Load account
      const account = await web3Ctx.loadAccount(web3Provider);

      // Load Network ID
      const networkId = await web3Ctx.loadNetworkId(web3Provider);
      // }

      // Load Contracts
      const nftDeployedNetwork = Collectible.networks[networkId];
      const nftContract = collectionCtx.loadContract(
        web3Provider,
        Collectible,
        nftDeployedNetwork
      );
      const mktDeployedNetwork = Marketplace.networks[networkId];
      const mktContract = marketplaceCtx.loadContract(
        web3Provider,
        Marketplace,
        mktDeployedNetwork
      );
      const userDeployedNetwork = UserInfo.networks[networkId];
      const userContract = userCtx.loadContract(
        web3Provider,
        UserInfo,
        userDeployedNetwork
      );
      const auctionDeployedNetwork = NFTAuction.networks[networkId];
      const auctionContract = auctionCtx.loadContract(
        web3Provider,
        NFTAuction,
        auctionDeployedNetwork
      );

      if (nftContract) {
        // Load total Supply
        const totalSupply = await collectionCtx.loadTotalSupply(nftContract);

        // Load Collection
        collectionCtx.loadCollection(nftContract, totalSupply);

        if (window.ethereum && networkId === settings.networkId) {
          // Event subscription
          nftContract.events
            .Transfer()
            .on("data", (event) => {
              collectionCtx.updateCollection(
                nftContract,
                event.returnValues.tokenId,
                event.returnValues.to
              );
              collectionCtx.setNftIsLoading(false);
            })
            .on("error", (error) => {
              console.log(error);
            });
        }
      } else {
        setNoContract(false);
        return;
      }

      if (auctionContract) {
        auctionCtx.loadAuctions(auctionContract);
        account && auctionCtx.loadUserFunds(auctionContract, account);
        const totalSupply = await collectionCtx.loadTotalSupply(nftContract);

        if (window.ethereum && networkId === settings.networkId) {
          auctionContract.events
            .CreateAuction()
            .on("data", (event) => {
              auctionCtx.loadAuctions(auctionContract);
              auctionCtx.setAuctionTransactionLoading(false);
              history.push("/auctions");
            })
            .on("error", (error) => {
              console.log(error);
            });
          auctionContract.events
            .CancelAuction()
            .on("data", (event) => {
              auctionCtx.loadAuctions(auctionContract);
              auctionCtx.setAuctionTransactionLoading(false);
              collectionCtx.loadCollection(nftContract, totalSupply);
              history.push("/explore");
            })
            .on("error", (error) => {
              console.log(error);
            });

          auctionContract.events
            .Bid()
            .on("data", (event) => {
              auctionCtx.loadAuctions(auctionContract);
              auctionCtx.setAuctionTransactionLoading(false);
            })
            .on("error", (error) => {
              console.log(error);
            });

          auctionContract.events
            .Withdraw()
            .on("data", (event) => {
              auctionCtx.loadAuctions(auctionContract);
              auctionCtx.setAuctionTransactionLoading(false);
            })
            .on("error", (error) => {
              console.log(error);
            });

          auctionContract.events
            .EndAuction()
            .on("data", (event) => {
              auctionCtx.loadAuctions(auctionContract);
              auctionCtx.setAuctionTransactionLoading(false);
              collectionCtx.loadCollection(nftContract, totalSupply);
              history.push("/explore");
            })
            .on("error", (error) => {
              console.log(error);
            });
        }
      } else {
        setNoContract(false);
        return;
      }

      if (mktContract) {
        const offerCount = await marketplaceCtx.loadOfferCount(mktContract);
        marketplaceCtx.loadOffers(mktContract, offerCount);
        marketplaceCtx.loadSellers(mktContract);
        marketplaceCtx.getContractAddress(mktContract);
        account && marketplaceCtx.loadUserFunds(mktContract, account);
        userCtx.getAppOwner(mktContract);

        if (window.ethereum && networkId === settings.networkId) {
          // Event OfferFilled subscription
          mktContract.events
            .BoughtNFT()
            .on("data", (event) => {
              marketplaceCtx.updateOffer(event.returnValues._offerId);
              collectionCtx.updateOwner(
                event.returnValues._tokenId,
                event.returnValues.winner
              );
              marketplaceCtx.setMktIsLoading(false);
            })
            .on("error", (error) => {
              console.log(error);
            });

          // Event Offer subscription
          mktContract.events
            .Offer()
            .on("data", (event) => {
              marketplaceCtx.addOffer(event.returnValues);
              marketplaceCtx.setMktIsLoading(false);
            })
            .on("error", (error) => {
              console.log(error);
            });

          // Event offerCancelled subscription
          mktContract.events
            .SaleCancelled()
            .on("data", (event) => {
              marketplaceCtx.updateOffer(event.returnValues.offerId);
              collectionCtx.updateOwner(
                event.returnValues.id,
                event.returnValues.owner
              );
              marketplaceCtx.setMktIsLoading(false);
            })
            .on("error", (error) => {
              console.log(error);
            });
        }
      } else {
        setNoContract(false);
        return;
      }

      // If User contract Loaded
      if (userContract) {
        userCtx.setUserIsLoading(false);
        account && userCtx.getUserInformation(userContract, account);
        userCtx.getUsersList(userContract);
        userCtx.loadWhiteList(userContract);
        userCtx.loadActivity(userContract);
        userCtx.loadTransactions(userContract);
      } else {
        userCtx.setUserIsLoading(true);
      }

      collectionCtx.setNftIsLoading(false);
      collectionCtx.setNftTransactionLoading(false);
      marketplaceCtx.setMktIsLoading(false);
      userCtx.setUserIsLoading(false);

      if (window.ethereum && networkId === settings.networkId) {
        // Metamask Event Subscription - Account changed
        window.ethereum.on("accountsChanged", (accounts) => {
          web3Ctx.loadAccount(web3Provider);
          userCtx.getUsersList(userContract);
          accounts[0] && userCtx.getUserInformation(userContract, accounts[0]);
          accounts[0] && marketplaceCtx.loadUserFunds(mktContract, accounts[0]);
          accounts[0] && auctionCtx.loadUserFunds(auctionContract, accounts[0]);
          addToast("Account Changed!", {
            appearance: "success",
          });
          setRegisteredAlert(true);
          if (userCtx.contract && userCtx.usersList) {
            if (userCtx.usersList.length > 0) {
              const registeredAccounts = userCtx.usersList.map(
                (user) => user.account
              );
              userCtx.checkRegisteration(
                registeredAccounts.includes(web3Ctx.account)
              );
            }
          }
        });

        // Metamask Event Subscription - Network changed
        window.ethereum.on("chainChanged", (chainId) => {
          window.location.reload();
        });

        await web3Provider.eth.net
          .getNetworkType()
          .then((res) => setNetworkType(res))
          .catch((err) => console.log(err));
      }
    };

    calclateInitialSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (window.ethereum && networkId !== settings.networkId) {
    // Metamask Event Subscription - Network changed
    window.ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });
  }

  /*** =============================================== */
  //      FETCHING AUCTIONS DATA
  /*** =============================================== */
  useEffect(() => {
    if (collectionCtx.contract && auctionCtx.contract && auctionCtx.auctions) {
      auctionCtx.loadAuctionsData(
        collectionCtx.contract,
        auctionCtx.auctions.filter((auc) => auc.isActive === true)
      );
      auctionCtx.setFetchingLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionCtx.contract, auctionCtx.contract, auctionCtx.auctions]);

  /*** =============================================== */
  //      CHECK IF USER IS REGISTERED
  /*** =============================================== */
  useEffect(() => {
    if (userCtx.contract && userCtx.usersList) {
      if (userCtx.usersList.length > 0) {
        const registeredAccounts = userCtx.usersList.map(
          (user) => user.account
        );
        userCtx.checkRegisteration(
          registeredAccounts.includes(web3Ctx.account)
        );
      } else if (userCtx.usersList.length === 0) {
        userCtx.checkRegisteration(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx.contract, userCtx.usersList, web3Ctx.account]);

  /*** =============================================== */
  //      CLOSE REGISTERATION ALERT
  /*** =============================================== */
  function closeAlert() {
    setRegisteredAlert(false);
  }

  /*** =============================================== */
  //      RENDER ALERT IF METAMASK IS NOT EXISTED
  /*** =============================================== */
  if (noMetaMask) {
    return <NoMetaMaskAlert />;
  }

  /*** =============================================== */
  //      RENDER ALERT IF USER IS ON WRONG NETWORK
  /*** =============================================== */
  if (noContract) {
    return <NoContractAlert network={networkType} />;
  }

  return (
    <>
      <Marquee
        className="fixed-top text-white text-center"
        style={{
          "background-color": "black",
          height: "15px",
          fontSize: "10px",
          // position: "fixed",
          // width: "100%",
          // zIndex: 10000,
        }}
      >
        Welcome to FCA NFT Marketplace. This is a demo site. Please do not use
        real money. This site is for demonstration purposes only.
      </Marquee>
      <div className="page-holder d-flex flex-column pt-5 pt-lg-0">
        {!noContract && <Header netId={networkId} />}
        {!userCtx.userIsRegistered && web3Ctx.account && registeredAlert && (
          <RegisterAlert closeAlert={closeAlert} />
        )}
        <ScrollToTop>
          <Switch>
            <Route path="/" exact>
              <Home topSellers={topSellers} />
              <ScrollTopButton />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/mint">
              <CreateItem netId={networkId} />
            </Route>
            <Route path="/explore">
              <Explore />
            </Route>
            <Route path="/auctions">
              <Auctions />
            </Route>
            <Route path="/assets/:id">
              <ItemSingle />
            </Route>
            <Route path="/lazy/:id">
              <ItemSingleFB />
            </Route>
            <Route path="/nftauction/:id">
              <AuctionSingle />
            </Route>
            <Route path="/categories/:category">
              <Category />
            </Route>
            <Route path="/search">
              <Search />
            </Route>
            <Route path="/activity">
              <Activity />
            </Route>
            <Route path="/faq">
              <FAQ />
            </Route>
            {/* <Route path="/register">
            <Register />
          </Route> */}
            {userCtx.appOwner === web3Ctx.account &&
              userCtx.userIsRegistered && (
                <Route path="/admin">
                  <Admin />
                </Route>
              )}
            <Route path="/users/:address">
              <UserGallery topSellers={topSellers} />
            </Route>
            <Route path="/sellers">
              <Authors sellers={topSellers} />
            </Route>
            {userCtx.userIsRegistered && (
              <Route path="/my-account">
                <MyInfo />
              </Route>
            )}

            <Route path="/register">
              <Register />
            </Route>

            <Route path="/privacypolicy">
              <PrivacyPolicy />
            </Route>

            <Route>
              <NotFound />
            </Route>
          </Switch>
        </ScrollToTop>
        <Footer />

        {(window.ethereum && networkId === settings.networkId) || (
          <ViewOnlyAlert />
        )}
        {window.ethereum && networkId !== settings.networkId && (
          <NoContractAlert />
        )}
      </div>
    </>
  );
}

export default App;
