import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { settings } from "../../helpers/settings";
import { formatPrice } from "../../helpers/utils";
import MarketplaceContext from "../../providers/marketplace-context";
import CollectionContext from "../../providers/collection-context";
import UserContext from "../../providers/user-context";
import AuctionContext from "../../providers/auction-context";
import AOS from "aos";

// COMPONENTS
import NftItem from "../general/NftItem";
import AuctionItem from "../general/AuctionItem";
import MetaMaskLoader from "../general/MetaMaskLoader";
import FullScreenLoader from "../general/FullScreenLoader";

function UserGallery({ topSellers }) {
  const marketplaceCtx = useContext(MarketplaceContext);
  const collectionCtx = useContext(CollectionContext);
  const userCtx = useContext(UserContext);
  const auctionCtx = useContext(AuctionContext);
  const [isTopSeller, setIsTopSeller] = useState(null);
  const [isNavSelected, setIsNavSelected] = useState("created");
  const [allCollection, setAllCollection] = useState(null);
  const [isCurrentUser, setIsCurrentUser] = useState(null);
  const { address } = useParams();
  const [userDetails, setUserDetails] = useState({
    avatar: "",
    header: "",
    fullName: "",
    role: "",
    about: "",
    facebook: "",
    dribbble: "",
    twitter: "",
    instagram: "",
  });

  /*** =============================================== */
  //      CHANGE PAGE TITLE
  /*** =============================================== */
  useEffect(() => {
    document.title = `${userDetails.fullName} | ${settings.UISettings.marketplaceBrandName}`;
  }, [userDetails]);

  /*** =============================================== */
  //      CHECK IF USER EXISTS
  /*** =============================================== */
  useEffect(() => {
    if (
      userCtx.contract &&
      userCtx.usersList.map((user) => user.account).includes(address)
    ) {
      setIsCurrentUser(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, userCtx.usersList]);

  /*** =============================================== */
  //      INITIATE AOS ANIMATION
  /*** =============================================== */
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  /*** =============================================== */
  //      MERGE NFT COLLECTION WITH NFT OFFERS
  /*** =============================================== */
  useEffect(() => {
    if (
      marketplaceCtx.contract &&
      collectionCtx.contract &&
      collectionCtx.collection.length > 0
    ) {
      let offersMap = marketplaceCtx.offers.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      let combined = collectionCtx.collection.map((d) =>
        Object.assign(d, offersMap[d.id])
      );
      let final = combined.map((el) => {
        console.log("el", el);
        if (el.price) {
          return { ...el, owner: el.owner };
        } else if (!el.price) {
          return { ...el, price: 0 };
        }
        return { ...el };
      });
      setAllCollection(final);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    marketplaceCtx.offers,
    collectionCtx.collection,
    collectionCtx.contract,
    marketplaceCtx.contract,
    collectionCtx.collection,
  ]);

  /*** =============================================== */
  //      GET USER DETAILS
  /*** =============================================== */
  useEffect(() => {
    if (
      userCtx.contract &&
      userCtx.usersList &&
      userCtx.usersList.length > 0 &&
      isCurrentUser === true
    ) {
      setUserDetails(
        userCtx.usersList.filter((user) => user.account === address)[0]
      );
    }
  }, [userCtx.contract, userCtx.usersList, address, isCurrentUser]);

  /*** =============================================== */
  //      GET TOP SELLERS
  /*** =============================================== */
  useEffect(() => {
    const topSellersList = topSellers.map((seller) => seller.address);
    setIsTopSeller(topSellersList.includes(address));
  }, [topSellers, address]);

  /*** =============================================== */
  //      GET OWNED NFTS
  /*** =============================================== */
  useEffect(() => {
    if (
      collectionCtx.contract &&
      collectionCtx.collection &&
      collectionCtx.collection.length > 0
    ) {
      userCtx.loadUserAssets(collectionCtx.contract, address);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionCtx.contract, collectionCtx.collection, address]);

  /*** =============================================== */
  //      GET CREATED NFTs
  /*** =============================================== */
  const createdAssets = useMemo(() => {
    if (userCtx.userAssets) {
      return userCtx.userAssets.created;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx.userAssets, userCtx.contract]);

  if (!isCurrentUser) {
    return (
      <div className="container py-5">
        <div className="row py-5 text-center">
          <div className="col-lg-6 mx-auto">
            <p className="mb-0 fw-bold" style={{ fontSize: "10rem" }}>
              404
            </p>
            <h1 className="h2 text-uppercase">Not Found</h1>
            <p className="text-muted">
              This page is not found, return to Home page
            </p>
            <Link to="/" className="btn btn-gradient-primary">
              Homepage
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {auctionCtx.fetchingLoading ? (
        <FullScreenLoader heading="Updating Auctions" />
      ) : null}
      {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}
      {collectionCtx.nftIsLoading ? (
        <FullScreenLoader heading="loading" />
      ) : null}
      {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
      <section className="py-5">
        <div className="container py-5">
          <div className="user-gallery-header">
            <div
              className="user-gallery-header-inner"
              style={{ background: `url(${userDetails.header})` }}
            ></div>
            {userDetails.avatar === "" ? (
              <div className="user-gallery-avatar">
                <span
                  className="user-gallery-avatar-inner"
                  style={{ background: `url(/images/Login_icon.svg)` }}
                ></span>
              </div>
            ) : (
              <div className="user-gallery-avatar">
                <span
                  className="user-gallery-avatar-inner"
                  style={{ background: `url(${userDetails.avatar})` }}
                ></span>
              </div>
            )}
          </div>
          <div className="pt-5 text-center">
            <h1 className="h4 mb-0 d-flex align-items-center justify-content-center">
              {userDetails && userDetails.fullName}
              {isTopSeller && (
                <span className="bg-primary badge mb-0 ms-2 text-xxs">
                  <i className="las la-trophy me-1 text-xxs"></i>
                  Top Seller
                </span>
              )}
            </h1>
            <p className="text-muted mb-1">{userDetails.role}</p>
            <ul className="list-inline mb-0">
              {userDetails && userDetails.facebook !== "" && (
                <li className="list-inline-item">
                  <a
                    href={userDetails.facebook}
                    rel="noopener noreferrer"
                    className="user-social-link"
                    target="_blank"
                  >
                    <i className="lab la-facebook-f"></i>
                  </a>
                </li>
              )}
              {userDetails && userDetails.twitter !== "" && (
                <li className="list-inline-item">
                  <a
                    href={userDetails.twitter}
                    rel="noopener noreferrer"
                    className="user-social-link"
                    target="_blank"
                  >
                    <i className="lab la-twitter"></i>
                  </a>
                </li>
              )}
              {userDetails && userDetails.instagram !== "" && (
                <li className="list-inline-item">
                  <a
                    href={userDetails.instagram}
                    rel="noopener noreferrer"
                    className="user-social-link"
                    target="_blank"
                  >
                    <i className="lab la-instagram"></i>
                  </a>
                </li>
              )}
              {userDetails && userDetails.dribbble !== "" && (
                <li className="list-inline-item">
                  <a
                    href={userDetails.dribbble}
                    rel="noopener noreferrer"
                    className="user-social-link"
                    target="_blank"
                  >
                    <i className="lab la-linkedin"></i>
                  </a>
                </li>
              )}
            </ul>
          </div>

          <div className="py-5">
            <div className="row">
              <div className="col-lg-5 mx-auto">
                <div className="toggle-nav">
                  <button
                    className={`toggle-nav-btn flex-fill text-white ${
                      isNavSelected === "created" ? "active" : null
                    }`}
                    onClick={() => setIsNavSelected("created")}
                  >
                    Created
                  </button>
                  <button
                    className={`toggle-nav-btn flex-fill text-white ${
                      isNavSelected === "collected" ? "active" : null
                    }`}
                    onClick={() => setIsNavSelected("collected")}
                  >
                    Owned
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            {collectionCtx.collection.length !== 0 &&
              collectionCtx.totalSupply !== 0 &&
              isNavSelected === "created" &&
              allCollection &&
              createdAssets && (
                <>
                  <div className="row mb-4">
                    <div className="col-lg-7 text-white">
                      <h2>NFTs created by {userDetails.fullName}</h2>
                      <p className="lead text-muted">
                        {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. */}
                      </p>
                    </div>
                  </div>
                  <div className="row gy-4 mb-5 align-items-stretch">
                    {allCollection
                      .filter(
                        (nft) =>
                          !auctionCtx.auctions
                            .filter((auc) => auc.isActive === true)
                            .some((auc) => nft.id === auc.tokenId)
                      )
                      .filter((asset) => asset.creator === address)
                      .map((NFT, key) => {
                        console.log("////////", NFT);
                        const index = marketplaceCtx.offers
                          ? marketplaceCtx.offers.findIndex(
                              (offer) => offer.id === NFT.id
                            )
                          : -1;
                        const owner =
                          index === -1
                            ? NFT.owner
                            : marketplaceCtx.offers[index].user;
                        console.log("owner", owner);
                        const price =
                          index !== -1
                            ? formatPrice(
                                marketplaceCtx.offers[index].price
                              ).toFixed(2)
                            : null;
                        return (
                          <div
                            className={`col-xl-4 col-md-6 ${NFT.category}`}
                            key={key}
                          >
                            <NftItem
                              {...NFT}
                              index={index}
                              owner={owner}
                              price={price ? price : NFT.price}
                              nftKey={key}
                            />
                          </div>
                        );
                      })}

                    {auctionCtx.auctionsData
                      .filter((auc) => auc.active === true)
                      .filter((asset) => asset.creator === address)
                      .map((AUC, key) => {
                        return (
                          <div className="col-xl-4 col-md-6" key={key}>
                            <AuctionItem {...AUC} nftKey={key} />
                          </div>
                        );
                      })}

                    {collectionCtx.collection.filter(
                      (asset) => asset.creator === address
                    ).length === 0 &&
                      auctionCtx.auctionsData
                        .filter((auc) => auc.active === true)
                        .filter((asset) => asset.creator === address) && (
                        <h4>{userDetails.fullName} Hasn't created any NFTs</h4>
                      )}
                  </div>
                </>
              )}

            {collectionCtx.collection.length !== 0 &&
              collectionCtx.totalSupply !== "0" &&
              isNavSelected === "collected" &&
              allCollection &&
              createdAssets && (
                <>
                  <div className="row mb-4">
                    <div className="col-lg-7 text-white ">
                      <h2>NFTs owned by {userDetails.fullName}</h2>
                      <p className="lead text-muted">
                        {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. */}
                      </p>
                    </div>
                  </div>
                  <div className="row gy-4 mb-5 align-items-stretch">
                    {allCollection
                      .filter((asset) => asset.owner === address)
                      .map((NFT, key) => {
                        const index = marketplaceCtx.offers
                          ? marketplaceCtx.offers.findIndex(
                              (offer) => offer.id === NFT.id
                            )
                          : -1;
                        const owner =
                          index === -1
                            ? NFT.owner
                            : marketplaceCtx.offers[index].user;
                        const price =
                          index !== -1
                            ? formatPrice(
                                marketplaceCtx.offers[index].price
                              ).toFixed(2)
                            : null;

                        return (
                          <div
                            className={`col-xl-4 col-md-6 ${NFT.category}`}
                            key={key}
                          >
                            <NftItem
                              {...NFT}
                              index={index}
                              owner={owner}
                              price={price ? price : NFT.price}
                              nftKey={key}
                            />
                          </div>
                        );
                      })}

                    {auctionCtx.auctionsData
                      .filter((auc) => auc.active === true)
                      .filter((asset) => asset.user === address)
                      .map((AUC, key) => {
                        return (
                          <div className="col-xl-4 col-md-6" key={key}>
                            <AuctionItem {...AUC} nftKey={key} />
                          </div>
                        );
                      })}

                    {collectionCtx.collection.filter(
                      (asset) => asset.user === address
                    ).length +
                      collectionCtx.collection.filter(
                        (asset) => asset.owner === address
                      ).length ===
                      0 && <h4>{userDetails.fullName} doesn't own any NFTs</h4>}
                  </div>
                </>
              )}
          </div>
        </div>
      </section>
    </>
  );
}

export default UserGallery;
