import React, { useEffect, useContext } from 'react';
import AOS from 'aos';
import MarketplaceContext from '../../providers/marketplace-context';
import { settings } from '../../helpers/settings';

// COMPONENTS
import PageBanner from '../general/PageBanner';
import FullScreenLoader from '../general/FullScreenLoader';
import RegisterForm from '../user/RegisterForm';

function Register() {
    const marketplaceCtx = useContext(MarketplaceContext);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `Register | ${settings.UISettings.marketplaceBrandName}`;
    }, []);

    /*** =============================================== */
    //      INITIATE AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 1000, once: true });
    }, []);

    return (
        <>
            {marketplaceCtx.mktIsLoading ? <FullScreenLoader heading='loading' /> : null}
            <PageBanner heading='Register' />
            <section className='py-5'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-8 mx-auto'>
                            <RegisterForm />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Register;
