import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PageBanner from "../general/PageBanner";
import CollectionContext from "../../providers/collection-context";
import AuctionContext from "../../providers/auction-context";
import MetaMaskLoader from "../general/MetaMaskLoader";
import FullScreenLoader from "../general/FullScreenLoader";
import AuctionItem from "../general/AuctionItem";
import Loader from "../general/Loader";

function Auctions() {
  const collectionCtx = useContext(CollectionContext);
  const auctionCtx = useContext(AuctionContext);

  return (
    <>
      {auctionCtx.fetchingLoading ? (
        <FullScreenLoader heading="Updating Auctions" />
      ) : null}
      {collectionCtx.nftIsLoading ? (
        <FullScreenLoader heading="Fetching Auctions" />
      ) : null}
      {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}
      <PageBanner heading="Live Auctions" />
      <section className="py-5">
        <div className="container py-5">
          <header className="mb-4">
            <div className="row">
              <div className="col-lg-7">
                <h2 data-aos="fade-up" data-aos-delay="100">
                  All Auctions
                </h2>
                {auctionCtx.auctionsData.filter((auc) => auc.active === true)
                  .length === 0 && (
                  <p
                    className="lead text-muted"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    Currently there are no active auctions. Please leave your
                    email to get informed on the latest active items on our
                    auction list.
                  </p>
                )}
              </div>
            </div>
          </header>

          <div className="row gy-4 mb-5 align-items-stretch">
            {auctionCtx.auctionsData
              .filter((auc) => auc.active === true)
              .map((AUC, key) => {
                return (
                  <div
                    className={`col-xl-4 col-md-6 ${AUC.category}`}
                    key={AUC.tokenId}
                  >
                    <AuctionItem {...AUC} nftKey={key} />
                  </div>
                );
              })}
          </div>

          {auctionCtx.auctionsData.filter(
            (auc) => auc.cancellded !== true && auc.active !== false
          ).length === 0 &&
            !auctionCtx.fetchingLoading && (
              <>
                <h4>
                  Sign up for our Newsletter to get the latest updates in the
                  marketplace
                </h4>
                <p className="text-muted mb-3">
                  Once there'll be Auctions that match is category we'll render
                  them here
                </p>
                <Link className="btn btn-gradient-primary" to="/">
                  Return Home
                </Link>
              </>
            )}

          {auctionCtx.auctions.length === 0 &&
            auctionCtx.auctionsData.length !== 0 && <Loader />}
        </div>
      </section>
    </>
  );
}

export default Auctions;
