import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useForm } from "@formspree/react";
import { settings } from "../../helpers/settings";
import MarketplaceContext from "../../providers/marketplace-context";

function Footer() {
  const [state, handleSubmit] = useForm("xlezgplp");
  const marketplaceCtx = useContext(MarketplaceContext);

  return (
    <footer className="footer bg-map bg-light mt-auto">
      <div className="container py-5 z-index-20">
        <div className="row gy-4 pt-4">
          <div className="col-lg-3 col-md-6 mb-lg-0">
            <img
              src={
                marketplaceCtx.themeMode === "dark"
                  ? settings.UISettings.logo
                  : settings.UISettings.logoLight
              }
              alt="Forever Collection Art"
              className="mb-3"
              width="200"
            />

            {/* <a
                            href='https://web3monkeys.com'
                            className='btn btn-gradient-dark btn-sm pt-2'
                            target='_black'
                            rel='noopener noreferrer'
                        >
                            <span className='lh-reset px-3 pt-2'>Purchase App</span>
                        </a> */}
          </div>
          <div className="col-lg-3 col-md-6 mb-lg-0 pt-2">
            <h5 className="mb-4">Info</h5>
            <ul className="list-unstyled text-muted mb-0">
              <li className="mb-2">
                <Link className="text-reset" to="/faq">
                  FAQ
                </Link>
              </li>
              {/* <li className="mb-2">
                <Link className="text-reset" to="./">
                  Terms and conditions
                </Link>
              </li> */}
              <li className="mb-2">
                <Link className="text-reset" to="/privacypolicy">
                  Privacy / Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link className="text-reset" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 mb-lg-0 pt-2">
            <h5 className="mb-4">Community</h5>
            <ul className="list-unstyled text-muted mb-0">
              <li className="mb-2">
                <a
                  className="text-reset"
                  href="https://twitter.com/forevercollart"
                  target="_blank"
                >
                  Twitter
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="text-reset"
                  href="https://www.facebook.com/Forevercollectionart/"
                  target="_blank"
                >
                  Facebook
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="text-reset"
                  href="https://www.instagram.com/forevercollectionart/"
                  target="_blank"
                >
                  Instagram
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="text-reset"
                  href="https://www.linkedin.com/company/forevercollectionart"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="text-reset"
                  href="https://t.me/forevercollectionart"
                  target="_blank"
                >
                  Telegram
                </a>
              </li>
              {/* <li className="mb-2">
                <Link className="text-reset" to="/">
                  Discord
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 pt-2">
            <h5 className="mb-4">Newsletter</h5>
            <p className="text-sm text-muted">
              Be the first one to be informed about FCA listed art!
            </p>
            <form onSubmit={handleSubmit}>
              <div className="input-group border border-2 border-gray-400 rounded-pill">
                <input
                  className="form-control border-0 bg-none shadow-0 ps-4"
                  type="email"
                  name="email"
                  autoComplete="off"
                  placeholder="Enter your email address..."
                />
                <button
                  className="btn btn-primary rounded-circle btn-sm shadow-0 m-2 icon-md"
                  type="submit"
                >
                  <i className="las la-paper-plane"></i>
                </button>
              </div>
            </form>
            {state.succeeded ? (
              <p className="bg-primary text-white mt-1 px-3 py-1 rounded-sm">
                Thanks!
              </p>
            ) : null}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="border border-gray-200 rounded-pill"></div>
      </div>

      <div className="container py-4 z-index-20">
        <div className="row text-center">
          <p className="text-muted text-sm mb-0">
            © {new Date().getFullYear()} forevercollection.art. Netherlands. All
            rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
