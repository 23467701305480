import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MouseParallaxContainer, MouseParallaxChild } from 'react-parallax-mouse';
import MarketplaceContext from '../../providers/marketplace-context';
import AOS from 'aos';

const heroBg = 'linear-gradient(to bottom, #ffffff, #ffffff, #ffffff)';

function HomeBanner() {
    const marketplaceCtx = useContext(MarketplaceContext);

    /*** =============================================== */
    //      INITIATE AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <section className='hero py-5' style={{ backgroundImage: marketplaceCtx.themeMode === 'light' ? heroBg : '' }}>
            {/* {marketplaceCtx.themeMode === 'dark' && <div className='glow bottom-right'></div>} */}
            {/* <div className='shape-1'></div> */}
            {/* <div className='shape-2'></div> */}
            <div className='container py-5 z-index-20 position-relative mt-5'>
                <div className='row gy-5 align-items-center'>
                    <div className='col-lg-6 text-center text-lg-start order-2 order-lg-1'>
                        <h1 data-aos='fade-up' data-aos-delay='100' className='position-relative lh-1 bannerTitle'>
                            CREATE, SELL AND COLLECT DIGITAL NFTs
                            {/* <div className='shape-3'></div> */}
                        </h1>
                        <p className='text-muted lead pt-4' data-aos='fade-up' data-aos-delay='200'>
                        The future of art creation and preservation is here. Safely record your artwork on a secure blockchain network so it can last forever.
                        </p>
                        {/* <p className='text-muted lead pb-4' data-aos='fade-up' data-aos-delay='200'>
                            This is the easiest way to get in on the next wave of innovation.
                        </p> */}
                        <ul className='list-inline'>
                            <li className='list-inline-item' data-aos='fade-up' data-aos-delay='300'>
                                <Link className='btn py-2 btn-gradient-primary' to='/mint'>

                                    Create NFT
                                </Link>
                            </li>
                            <li className='list-inline-item' data-aos='fade-up' data-aos-delay='400'>
                                <Link className='btn py-2 btn-dark' to='/explore'>

                                    Discover
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className='col-lg-6 order-1 order-lg-2' data-aos='fade-left' data-aos-delay='100'>
                        <MouseParallaxContainer
                            className='image-perspective-box'
                            containerStyles={{ overflow: 'visible' }}
                        >
                            <MouseParallaxChild factorX={0.05} factorY={0.05} factorZ={0.1}>
                                <img
                                    src='/hero.png'
                                    alt=''
                                    className='img-fluid'
                                    style={{ borderRadius: '2rem', boxShadow: '0 20px 40px rgba(0, 0, 0, 0)' }}
                                />
                            </MouseParallaxChild>
                        </MouseParallaxContainer>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeBanner;
