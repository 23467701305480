import React, { useEffect, useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Select from "react-dropdown-select";
import CollectionContext from "../../providers/collection-context";
import MarketplaceContext from "../../providers/marketplace-context";
import UserContext from "../../providers/user-context";
import AuctionContext from "../../providers/auction-context";
import { formatPrice, truncateStart } from "../../helpers/utils";
import { settings } from "../../helpers/settings";
import AOS from "aos";

// COMPONENTS
import FullScreenLoader from "../general/FullScreenLoader";
import NftItem from "../general/NftItem";
import AuctionItem from "../general/AuctionItem";

// SEARCH OPTIONS
const searchOptions = [
  { label: "NFTs", value: "collection" },
  { label: "Users", value: "users" },
  { label: "Auctions", value: "auctions" },
];

function Search() {
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const userCtx = useContext(UserContext);
  const auctionCtx = useContext(AuctionContext);
  const [isSearched, setIsSearched] = useState("collection");
  const [query, setQuery] = useState("");
  const [searchResultsLength, setSearchResultsLength] = useState(0);

  /*** =============================================== */
  //      CHANGE PAGE TITLE
  /*** =============================================== */
  useEffect(() => {
    document.title = `Search Assets | ${settings.UISettings.marketplaceBrandName}`;
  }, []);

  /*** =============================================== */
  //      INITIATE AOS ANIMATION
  /*** =============================================== */
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  /*** =============================================== */
  //      CHOOSE WHAT TYPE OF DATA TO SEARCH
  /*** =============================================== */
  const dataToBeSearched = useMemo(() => {
    if (isSearched === "collection") {
      return collectionCtx.collection.filter(
        (nft) =>
          !auctionCtx.auctions
            .filter((auc) => auc.isActive === true)
            .some((auc) => nft.id === auc.tokenId)
      );
    } else if (isSearched === "users") {
      return userCtx.usersList;
    } else if (isSearched === "auctions") {
      return auctionCtx.auctionsData.filter((auc) => auc.active === true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSearched,
    collectionCtx.collection,
    userCtx.usersList,
    auctionCtx.auctionsData,
  ]);

  /*** =============================================== */
  //      SEARCH ACCORDING TO DATA CHOSEN
  /*** =============================================== */
  useEffect(() => {
    if (isSearched === "collection") {
      setSearchResultsLength(
        dataToBeSearched.filter((nft) => {
          if (nft.title.toLowerCase().includes(query.toLowerCase().trim())) {
            return nft;
          }
          return false;
        }).length
      );
    } else if (isSearched === "users" && userCtx.usersList) {
      setSearchResultsLength(
        dataToBeSearched.filter((user) => {
          if (
            user.fullName.toLowerCase().includes(query.toLowerCase().trim())
          ) {
            return user;
          }
          return false;
        }).length
      );
    } else if (isSearched === "auctions" && userCtx.usersList) {
      setSearchResultsLength(
        dataToBeSearched.filter((auc) => {
          if (auc.title.toLowerCase().includes(query.toLowerCase().trim())) {
            return auc;
          }
          return false;
        }).length
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, dataToBeSearched, isSearched]);

  return (
    <>
      {collectionCtx.collection.length === 0 ? (
        <FullScreenLoader heading="Loading" />
      ) : null}
      <section className="pt-5 position-relative page-banner">
        <div className="container py-4 mt-5 z-index-20">
          <div className="row align-items-center">
            <div className="col-xl-7">
              <h1 data-aos="fade-right" data-aos-delay="100">
                Hmmmm... What're you looking for?
              </h1>
              <p
                className="text-muted mb-4"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                Explore other art pieces in the same category
              </p>
              <form
                onSubmit={(e) => e.preventDefault()}
                className="mb-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div
                  className="bg-white rounded-lg py-1 ps-1 pe-4 position-relative"
                  style={{
                    border:
                      marketplaceCtx.themeMode === "light"
                        ? "2px solid #e9ecef"
                        : "2px solid #282830",
                  }}
                >
                  <div className="input-icon pe-5">
                    <div className="input-icon-text" style={{ top: "0.8rem" }}>
                      <i className="text-primary las la-search"></i>
                    </div>
                    <input
                      className="form-control bg-none form-control-lg shadow-0 py-2 border-0"
                      type="search"
                      autoComplete="off"
                      name="search"
                      placeholder={
                        isSearched === "collection"
                          ? "Search our NFTs..."
                          : isSearched === "users"
                          ? "Search our users..."
                          : "Search our Auctions..."
                      }
                      value={query}
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </div>
                  <Select
                    searchable={false}
                    options={searchOptions}
                    placeholder="NFTs"
                    className="form-select search-form-select ps-4 border-gray-300 shadow-0 bg-white fw-bold"
                    value={isSearched}
                    onChange={(values) => {
                      setIsSearched(values.map((el) => el.value).toString());
                      setQuery("");
                    }}
                  />
                </div>
              </form>
              {query !== "" && searchResultsLength > 0 ? (
                <p className="lead text-muted mb-0">
                  Found{" "}
                  <strong className="text-dark fw-bold mx-2">
                    {searchResultsLength}
                  </strong>{" "}
                  items match your search
                </p>
              ) : null}

              {query.trim() !== "" && searchResultsLength === 0 ? (
                <div className="d-flex">
                  <i
                    className="las la-exclamation mb-2"
                    style={{ fontSize: "3rem" }}
                  ></i>
                  <div className="ms-2">
                    <h3 className="h3">
                      Cannot find any assets that match your search
                    </h3>
                    <p className="text-muted mb-0">
                      You can search for another term...
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
            <div
              className="col-lg-5 d-none d-lg-block"
              data-aos="fade-left"
              data-aos-delay="150"
            >
              <img src="/images/Canvas.png" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section
        className={`pb-5 ${marketplaceCtx.themeMode === "dark" && "bg-light"}`}
      >
        <div className="container pb-5">
          <div className="row gy-4">
            {isSearched === "collection" &&
              dataToBeSearched
                .filter((nft) => {
                  if (query === "") {
                    return false;
                  } else if (
                    nft.title.toLowerCase().includes(query.toLowerCase().trim())
                  ) {
                    return nft;
                  }
                  return false;
                })
                .map((NFT, key) => {
                  const index = marketplaceCtx.offers
                    ? marketplaceCtx.offers.findIndex(
                        (offer) => offer.id === NFT.id
                      )
                    : -1;
                  const owner =
                    index === -1
                      ? NFT.owner
                      : marketplaceCtx.offers[index].user;
                  const price =
                    index !== -1
                      ? formatPrice(marketplaceCtx.offers[index].price).toFixed(
                          2
                        )
                      : null;

                  return (
                    <div
                      className={`col-xl-4 col-md-6 ${NFT.category}`}
                      key={key}
                    >
                      <NftItem
                        {...NFT}
                        index={index}
                        owner={owner}
                        price={price ? price : NFT.price}
                        nftKey={key}
                      />
                    </div>
                  );
                })}

            {isSearched === "users" &&
              userCtx.usersList &&
              userCtx.usersList
                .filter((user) => {
                  if (query === "") {
                    return false;
                  } else if (
                    user.fullName
                      .toLowerCase()
                      .includes(query.toLowerCase().trim())
                  ) {
                    return user;
                  }
                  return false;
                })
                .map((user, index) => {
                  return (
                    <Link
                      to={`/users/${user.account}`}
                      className="col-xl-3 col-lg-4 col-md-6"
                      key={index}
                    >
                      <div className="card bd-3 shadow card-hover-minimal border-0 position-relative rounded-pill text-dark">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="author-avatar author-avatar-md">
                              <span
                                className="author-avatar-inner"
                                style={{
                                  background: `url(${
                                    user.avatar === ""
                                      ? "/images/Login_icon.svg"
                                      : user.avatar
                                  })`,
                                }}
                              ></span>
                            </div>

                            <div className="ms-3">
                              <h3 className="h6 mb-1 text-capitalize text-reset">
                                {truncateStart(user.fullName, 10)}
                              </h3>
                              <p className="text-sm text-muted mb-0">
                                {user.role === "" ? "Member" : user.role}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}

            {isSearched === "auctions" &&
              auctionCtx.auctionsData
                .filter((auc) => {
                  if (query === "") {
                    return false;
                  } else if (
                    auc.title.toLowerCase().includes(query.toLowerCase().trim())
                  ) {
                    return auc;
                  }
                  return false;
                })
                .filter((auc) => auc.active === true)
                .map((AUC, key) => {
                  return (
                    <div
                      className={`col-xl-4 col-md-6 ${AUC.category}`}
                      key={AUC.tokenId}
                    >
                      <AuctionItem {...AUC} nftKey={key} />
                    </div>
                  );
                })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Search;
