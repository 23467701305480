import React, { useContext } from 'react';
import UserContext from '../../providers/user-context';

// COMPONENTS
import FullScreenLoader from '../general/FullScreenLoader';
import PageBanner from '../general/PageBanner';
import ActivityTable from '../tables/ActivityTable';
import TransactionsTable from '../tables/TransactionsTable';

function Activity() {
    const userCtx = useContext(UserContext);
    return (
        <>
            {userCtx.userIsLoading && <FullScreenLoader heading='Loading' />}
            <PageBanner heading='Activity & Transactions' />
            <section className='py-5'>
                <div className='container py-5' data-aos='fade-up' data-aos-delay='100'>
                    {/* ACTIVITES */}
                    <div className='mb-5'>
                        <header className='mb-4'>
                            <div className='row'>
                                <div className='col-lg-7'>
                                    <h2 className='mb-2'>Activities</h2>
                                    <p className='text-muted lead'>
                                        Find out what happened in the Elysium world today.
                                    </p>
                                </div>
                            </div>
                        </header>
                        <div className='card shadow-lg'>
                            <div className='card-body'>
                                <ActivityTable />
                            </div>
                        </div>
                    </div>

                    {/* TRANSACTIONS */}
                    <header className='mb-4'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <h2 className='mb-2'>Transactions</h2>
                                <p className='text-muted lead'>The latest transactions</p>
                            </div>
                        </div>
                    </header>

                    <div className='card shadow-lg'>
                        <div className='card-body'>
                            <TransactionsTable />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Activity;
