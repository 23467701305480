import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import CollectionContext from "../../providers/collection-context";
import MarketplaceContext from "../../providers/marketplace-context";
import AuctionContext from "../../providers/auction-context";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Navigation } from "swiper";
import { formatPrice } from "../../helpers/utils";
import { categoryOptions } from "../../helpers/constants";
import { settings } from "../../helpers/settings";

// COMPONENTS
import HomeBanner from "../home/HomeBanner";
import NftItem from "../general/NftItem";
import AuctionItem from "../general/AuctionItem";
import NoDataAlert from "../general/NoDataAlert";
import TopSellers from "../home/TopSellers";
import Loader from "../general/Loader";
import MetaMaskLoader from "../general/MetaMaskLoader";
import { getFeaturedData } from "../../config/firebase";

SwiperCore.use([Navigation]);

function Home({ topSellers }) {
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const auctionCtx = useContext(AuctionContext);

  /*** =============================================== */
  //      CHANGE PAGE TITLE
  /*** =============================================== */
  useEffect(() => {
    document.title = settings.UISettings.marketplaceBrandName;
  }, []);

  /*** =============================================== */
  //      INITIATE AOS ANIMATION
  /*** =============================================== */
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <>
      <HomeBanner />
      {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
      {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}

      {/* MARKETPLACE FEATURED ITEMS */}
      <section className="pb-5">
        <div className="container pb-5">
          <header className="mb-4">
            <div className="row">
              <div className="col-lg-6 mx-auto text-center">
                <h2 data-aos="fade-up" data-aos-delay="100">
                  Featured this Month
                </h2>
                <p
                  className="text-muted lead mb-0"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  We're excited to share our ten favorite non-fungible tokens
                  this month!
                </p>
              </div>
            </div>
          </header>

          {collectionCtx.totalSupply === "0" && !collectionCtx.nftIsLoading ? (
            <div className="col-lg-9 mx-auto">
              <NoDataAlert
                heading="There're no NFTs at the moment."
                subheading="Try to mint some assets to see how are we rendering them."
                customClass="justify-content-center"
                aos="fade-up"
                aosDelay="300"
              />
            </div>
          ) : null}

          {collectionCtx.collection.length === 0 &&
          collectionCtx.totalSupply !== "0" ? (
            <Loader />
          ) : null}

          <Swiper
            spaceBetween={0}
            breakpoints={{
              768: { slidesPerView: 1 },
              900: { slidesPerView: 2 },
              1200: { slidesPerView: 3 },
              1400: { slidesPerView: 3 },
            }}
            navigation={Boolean(collectionCtx.collection.length !== 0)}
          >
            {collectionCtx.collection
              .filter(
                (nft) =>
                  !auctionCtx.auctions
                    .filter((auc) => auc.isActive === true)
                    .some((auc) => nft.id === auc.tokenId)
              )
              .filter(
                (nft) => nft.featured === true
                // settings.UISettings.featuredItems.some(
                //   (featured) => item.id === featured
                // )
              )
              .map((NFT, key) => {
                const index = marketplaceCtx.offers
                  ? marketplaceCtx.offers.findIndex(
                      (offer) => offer.id === NFT.id
                    )
                  : -1;
                const owner =
                  index === -1 ? NFT.owner : marketplaceCtx.offers[index].user;
                const price =
                  index !== -1
                    ? formatPrice(marketplaceCtx.offers[index].price).toFixed(2)
                    : null;

                return (
                  <SwiperSlide key={key} className="pt-4 pb-5 px-3">
                    <NftItem
                      {...NFT}
                      index={index}
                      owner={owner}
                      price={price}
                      nftKey={key}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </section>

      {/* BROWSE BY CATEGORY */}
      <section className="py-5 bg-light">
        <div className="container py-4">
          <header className="mb-5">
            <div className="row">
              <div className="col-lg-6">
                <h2 data-aos="fade-up" data-aos-delay="100">
                  Categories
                </h2>
                <p
                  className="text-muted lead mb-0"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  Browse what's trending or find the rarest NFTs by category.
                </p>
              </div>
            </div>
          </header>

          <div className="row gy-4">
            {categoryOptions.map((el, i) => {
              return (
                <div
                  className="col-xl-2 col-lg-4 col-6"
                  key={i}
                  data-aos="fade-left"
                  data-aos-delay={(i + 1) * 100}
                >
                  <div className="card card-hover-minimal border-0 rounded-xl htw-card">
                    <div className="card-body text-center py-4">
                      <div className="mb-2">
                        <img src={el.icon} alt="" width={30} />
                      </div>
                      <Link
                        className="text-reset stretched-link"
                        to={`/categories/${el.value}`}
                      >
                        <h6 className="fw-normal">{el.label}</h6>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* NFT ITEMS */}
      {/* <section className='py-5 position-relative'>
                <div className='container pt-5'>
                    <header className='mb-4'>
                        <div className='row'>
                            <div className='col-lg-6 mx-auto text-center'>
                                <h2 data-aos='fade-up' data-aos-delay='100'>
                                    Latest NFTs
                                </h2>
                                <p className='text-muted lead mb-0' data-aos='fade-up' data-aos-delay='200'>
                                    You're in for a treat. There are a bunch of new NFTs that you might like. Check them
                                    out!
                                </p>
                            </div>
                        </div>
                    </header>

                    {collectionCtx.totalSupply === '0' && !collectionCtx.nftIsLoading ? (
                        <div className='col-lg-9 mx-auto'>
                            <NoDataAlert
                                heading="There're no NFTs at the moment."
                                subheading='Try to mint some assets to see how are we rendering them.'
                                customClass='justify-content-center'
                                aos='fade-up'
                                aosDelay='300'
                            />
                        </div>
                    ) : null}

                    {collectionCtx.collection.length === 0 && collectionCtx.totalSupply !== '0' ? <Loader /> : null}

                    <Swiper
                        spaceBetween={0}
                        breakpoints={{
                            768: { slidesPerView: 1 },
                            900: { slidesPerView: 2 },
                            1200: { slidesPerView: 3 },
                            1400: { slidesPerView: 3 },
                        }}
                        navigation={Boolean(collectionCtx.collection.length !== 0)}
                    >
                        {collectionCtx.collection
                            .filter(
                                (nft) =>
                                    !auctionCtx.auctions
                                        .filter((auc) => auc.isActive === true)
                                        .some((auc) => nft.id === auc.tokenId)
                            )
                            .slice(0, 8)
                            .map((NFT, key) => {
                                const index = marketplaceCtx.offers
                                    ? marketplaceCtx.offers.findIndex((offer) => offer.id === NFT.id)
                                    : -1;
                                const owner = index === -1 ? NFT.owner : marketplaceCtx.offers[index].user;
                                const price =
                                    index !== -1 ? formatPrice(marketplaceCtx.offers[index].price).toFixed(2) : null;

                                return (
                                    <SwiperSlide key={key} className='pt-4 pb-5 px-3'>
                                        <NftItem {...NFT} index={index} owner={owner} price={price} nftKey={key} />
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                </div>
                {marketplaceCtx.themeMode === 'dark' && <div className='glow'></div>}
            </section> */}

      {/* <TopSellers
                topSellers={topSellers}
                title='Top Sellers'
                description='Bestselling NFT authors on our marketplace.'
            /> */}

      <section className="py-5 bg-light">
        <div className="container py-5">
          <div className="row gy-5 align-items-center">
            <div className="col-lg-6">
              <h2 data-aos="fade-right" data-aos-delay="100">
                How it works
              </h2>
              <p
                className="text-muted lead mb-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                Feel free to sign up and dive in if you want to get into the
                nuance of minting, buying, or selling NFTs.
              </p>

              <div
                className="d-flex mb-4"
                data-aos="fade-right"
                data-aos-delay="150"
              >
                <div className="icon-animated rounded-xl bg-primary mx-auto flex-shrink-0">
                  <i className="las la-wallet text-white"></i>
                </div>
                <div className="ms-3">
                  <h5>Connect your wallet</h5>
                  <p className="text-muted text-md mb-0">
                    MetaMask is an easy-to-use browser extension that allows you
                    to interface with Ethereum applications like Elysium.
                  </p>
                </div>
              </div>
              <div
                className="mb-4 d-flex"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <div className="icon-animated mx-auto rounded-xl bg-primary flex-shrink-0">
                  <i className="las la-rocket text-white"></i>
                </div>
                <div className="ms-3">
                  <h5>Mint & Buy and Sell NFTs</h5>
                  <p className="text-muted text-md mb-2">
                    NFTs can represent anything from art to music to
                    collectibles and more. NFTs allow you to own digital items
                    in the same way that you would own physical items, like
                    trading cards or stamps.
                  </p>
                </div>
              </div>
              <div
                className="mb-0 d-flex"
                data-aos="fade-right"
                data-aos-delay="250"
              >
                <div className="icon-animated mx-auto rounded-xl bg-primary flex-shrink-0">
                  <i className="lab la-ethereum text-white"></i>
                </div>
                <div className="ms-3">
                  <h5>Purchase & Transfer</h5>
                  <p className="text-muted text-md mb-0">
                    When buyers purchase the NFT token, it gets automatically
                    transferred to them by the marketplace.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5 ms-auto"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <img
                src="/images/ils.png"
                alt="Illustration"
                className="img-fluid w-100"
              />
            </div>
          </div>
        </div>
      </section>

      {/* NFT ITEMS */}
      {/* <section className='py-5'>
                <div className='container py-5'>
                    <header className='mb-4'>
                        <div className='row'>
                            <div className='col-lg-6 mx-auto text-center'>
                                <h2 data-aos='fade-up' data-aos-delay='100'>
                                    Recent Auctions
                                </h2>
                                <p className='text-muted lead mb-0' data-aos='fade-up' data-aos-delay='200'>
                                    Get this rare limited edition collectible before someone else does!
                                </p>
                            </div>
                        </div>
                    </header>

                    {auctionCtx.auctionsData.filter((auc) => auc.active === true).length === 0 &&
                        !collectionCtx.nftIsLoading ? (
                        <div className='col-lg-9 mx-auto'>
                            <NoDataAlert
                                heading="There're no Auctions at the moment."
                                subheading='Once someone has created an auction, you should find it here.'
                                customClass='justify-content-center'
                                aos='fade-up'
                                aosDelay='300'
                            />
                        </div>
                    ) : null}

                    {collectionCtx.nftIsLoading ? <Loader /> : null}

                    <Swiper
                        spaceBetween={0}
                        breakpoints={{
                            768: { slidesPerView: 1 },
                            900: { slidesPerView: 2 },
                            1200: { slidesPerView: 3 },
                            1400: { slidesPerView: 3 },
                        }}
                        navigation={Boolean(auctionCtx.auctionsData.filter((auc) => auc.active === true).length !== 0)}
                    >
                        {auctionCtx.auctionsData
                            .filter((auc) => auc.active === true)
                            .map((AUC, key) => {
                                return (
                                    <SwiperSlide key={key} className='pt-4 pb-5 px-3'>
                                        <AuctionItem {...AUC} nftKey={key} />
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                </div>
            </section> */}
    </>
  );
}

export default Home;
